import React, { Component } from 'react'
import search from "../../../assets/icons/search.svg";
import {Button, Form} from "react-bootstrap";

export default class ProductSearch extends Component {
    render() {
        return (
            <div className="div_fillter productSearch">
                <Form>
                    <Form.Group className="from_search form_product_search">
                        <Form.Control type="text" onChange="" placeholder="Search Product"/>
                        <Button type="submit" className="btn_search">
                            <img src={search} alt="search_icon"/>
                        </Button>
                    </Form.Group>
                    <a href="#" className="btn_back">
                        Back to product
                    </a>
                </Form>
            </div>
        )
    }
}
