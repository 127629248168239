export function validate(input) {
    let errors = {};
    let isValid = true;

    if (typeof input["email"] !== "undefined") {

        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (input["email"].length > 0 && !pattern.test(input["email"])
        ) {
            isValid = false;
            errors["email"] = "Please enter valid email address.";
        }
    }

    if (typeof input["password"] !== "undefined") {
        var pattern = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[`~^.#$@!%&*=?])[A-Za-z\d`~^.#$@!%&*=?]{8,30}$/);
        if (input["password"].length > 0 && !pattern.test(input["password"])) {
            isValid = false;
            errors["password"] = "Password must be atleast 8 digits and contain atleast one uppercase letter, one lowercase letter, one number, one symbol.";
        }
    }
    if (typeof input["newpassword"] !== "undefined") {
        var pattern = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[`~^.#$@!%&*=?])[A-Za-z\d`~^.#$@!%&*=?]{8,30}$/);
        if (input["newpassword"].length > 0 && !pattern.test(input["newpassword"])) {
            isValid = false;
            errors["newpassword"] = "Password must be atleast 8 digits and contain atleast one uppercase letter, one lowercase letter, one number, one symbol.";
        }
    }
    if (typeof input["cpassword"] !== "undefined") {
        if (input["cpassword"].length > 0 && input["cpassword"] != input["password"]) {
            isValid = false;
            errors["cpassword"] = "Passwords do not match.";
        }

    }
    if (typeof input["rpassword"] !== "undefined") {
        if (input["rpassword"].length > 0 && input["rpassword"] != input["newpassword"]) {
            isValid = false;
            errors["rpassword"] = "Passwords do not match.";
        }

    }

    if (typeof input["otp"] !== "undefined") {
        if (input["otp"].length < 4) {
            isValid = false;
            errors["otp"] = "Please enter a valid OTP.";
        }
    }

    if (typeof input["phone"] !== "undefined") {

        var pattern = new RegExp(/^(?!0+$)[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{5,9}$/im);
        if (input["phone"].length > 0 && !pattern.test(input["phone"])
        ) {
            isValid = false;
            errors["phone"] = "Please enter valid phone Number.";
        }
    }
    return {
        is_valid: isValid,
        errors: errors
    };
}