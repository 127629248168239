import React, {Component} from 'react';
import CanvasJSReact from '../../assets/chart/canvasjs.react';

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

class LineChart extends Component {
    render() {
        const options = {
            animationEnabled: true,
            exportEnabled: false,
            theme: "light1", //"light1", "dark1", "dark2"
            title: {
                text: ""
            },
            toolTip: {
                shared: true
            },
            axisX: {stacked: true,},
            axisY: {
                includeZero: false,
                gridThickness: 0, stacked: true,

            },
            data: [{
                type: "column",
                color: "#0178E5",
                showInLegend: false,
                indexLabelFontColor: "#fff",
                indexLabelPlacement: "inside",
                dataPoints: [
                    {label: "12pm", y: 100},
                    {label: "1pm", y: 300},
                    {label: "2pm", y: 200},
                    {label: "3pm", y: 500},
                    {label: "5pm", y: 400},
                    {label: "5pm", y: 550},
                    {label: "7pm", y: 600},
                    {label: "8pm", y: 950},
                    {label: "9pm", y: 800},
                    {label: "10pm", y: 100},
                    {label: "11pm", y: 910},
                ]
            }

            ]
        }

        return (
            <div>
                <CanvasJSChart options={options}/>

            </div>
        );
    }
}

export default LineChart;