import React, {Component} from 'react';
import { format } from 'date-fns'
import SidebarMenu from "../layouts/SidebarMenu";
import {Button, Col, Container, Row, Form} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import notification from "../../assets/icons/Notification.svg";
import profile from "../../assets/icons/photo.png";
import DataTable from 'react-data-table-component';


const customStyles = {
    headCells: {
        style: {
            backgroundColor : "#646C7C",
            color: "#fff",
            fontSize: "12px",

        },
    },
};
const columns = [
    {
        name: '#',
        selector: row => row.id,
    },
    {
        name: 'Description',
        selector: row => row.description,
    },
    {
        name: 'Sales Qty',
        selector: row => row.salesQty,
    },
    {
        name: 'Unit Price',
        selector: row => row.unitPrice,
    },
    {
        name: 'Sales Amount',
        selector: row => row.salesAmount,
    },
    {
        name: '% of Bill',
        selector: row => row.percentBill,
    },
];

const data = [
    {
        id: "28",
        description: 'Fries',
        salesQty: '1',
        unitPrice: '220',
        salesAmount: '220',
        percentBill: '0.20%',
    },
    {
        id: "29",
        description: 'Prawn Crackers',
        salesQty: '1',
        unitPrice: '220',
        salesAmount: '220',
        percentBill: '0.20%',
    },
    {
        id: "30",
        description: 'Sticky Fish',
        salesQty: '1',
        unitPrice: '220',
        salesAmount: '220',
        percentBill: '0.20%',
    },
    {
        id: "795",
        description: 'crispy wasabi prawns',
        salesQty: '1',
        unitPrice: '220',
        salesAmount: '220',
        percentBill: '0.20%',
    },
    {
        id: "685",
        description: 'dynamite prawns',
        salesQty: '1',
        unitPrice: '220',
        salesAmount: '220',
        percentBill: '0.20%',
    },
    {
        id: "31",
        description: 'mandarin appetizer platter',
        salesQty: '1',
        unitPrice: '220',
        salesAmount: '220',
        percentBill: '0.20%',
    },
    {
        id: "19",
        description: 'prawn on toast',
        salesQty: '1',
        unitPrice: '220',
        salesAmount: '220',
        percentBill: '0.20%',
    },
    {
        id: "18",
        description: 'spring rolls',
        salesQty: '1',
        unitPrice: '220',
        salesAmount: '220',
        percentBill: '0.20%',
    },
    {
        id: "17",
        description: 'Nanban',
        salesQty: '1',
        unitPrice: '220',
        salesAmount: '220',
        percentBill: '0.20%',
    },
    {
        id: "14",
        description: 'japanese battered tiger prawns',
        salesQty: '1',
        unitPrice: '220',
        salesAmount: '220',
        percentBill: '0.20%',
    },
];



class GroupSalesSummaryReport extends Component {
    constructor() {
        super();
        this.state = {checked: false};
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(checked) {
        this.setState({checked});
    }
    



    render() {
        return (
            <div className="paymentsPage">
                <SidebarMenu/>
                <div className="main_page_sectoin">
                    <div className="top_header">
                        <Container>
                            <Row>
                                <Col md={7}>
                                    <div className="left_side">
                                        <div className="drop_down">
                                            <h2>Reports - Group Sales Summary Report</h2>
                                        </div>
                                        <div className="data_title">{format(new Date(), 'MM-dd-yyyy')} | {format(new Date(), "eeee")}</div>
                                    </div>
                                </Col>
                                <Col md={5}>
                                    <div className="right_side">
                                        <div className="notification-icon">
                                            <Link to="">
                                                <img src={notification} alt="Notification_icon"/>
                                            </Link>
                                        </div>
                                        <div className="profile-icon">
                                            <div className="profile_img">
                                                <img src={profile} alt="profile_icon"/>
                                            </div>
                                            <div className="name_profile">
                                                John Doe
                                                <span>Sales Manager</span>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <div className="main_panel">
                        <Container>
                            <Row>
                                <Col md={12}>
                                    <div className="title_content border-bottom_1">
                                        <div className="div_fillter">
                                            <Form>
                                                <Form.Group className="sales_report_icon select_staus">
                                                    <select name="" id="">
                                                        <option>Daily Sales Report</option>
                                                        <option value="">Report 01</option>
                                                        <option value="">Report 02</option>
                                                        <option value="">Report 03</option>
                                                    </select>
                                                </Form.Group>
                                                <Form.Group className="">
                                                    <Form.Control type="date"/>
                                                </Form.Group>
                                                <Form.Group className="date_range_icon select_staus">
                                                    <select name="" id="">
                                                        <option value="">Status</option>
                                                        <option value="">Status</option>
                                                        <option value="">Status</option>
                                                        <option value="">Status</option>
                                                    </select>
                                                </Form.Group>
                                                
                                            </Form>
                                        </div>
                                    </div>
                                    <div className="menu_listing">
                                        <div className="reportsView">
                                            <div className="mb-5">
                                                <Tabs className="tabsReportSales">
                                                    <TabList>
                                                        <Tab>Total Appitizers</Tab>
                                                        <Tab>Total Soup</Tab>
                                                        <Tab>Total Salad</Tab>
                                                        <Tab>Total Dim Sum</Tab>
                                                        <Tab>Total Sushi</Tab>
                                                        <Tab>Total Vegetables</Tab>
                                                        {/* <Tab>Total Main Course Beef</Tab>
                                                        <Tab>Total Main Course Chicken</Tab>
                                                        <Tab>Total Main Course Seafood</Tab> */}
                                                    </TabList>
                                                    <TabPanel className="tabsReportSalesTabsPanel bg-white p-3">
                                                        <div className="paymentDataTable">
                                                            <DataTable columns={columns} data={data} customStyles={customStyles} pagination responsive highlightOnHover/>
                                                        </div>
                                                    </TabPanel>
                                                    <TabPanel className="tabsReportSalesTabsPanel bg-white p-3">
                                                        <div className="paymentDataTable">
                                                            <DataTable columns={columns} data={data} customStyles={customStyles} pagination responsive highlightOnHover/>
                                                        </div>
                                                    </TabPanel>
                                                    <TabPanel className="tabsReportSalesTabsPanel bg-white p-3">
                                                        <div className="paymentDataTable">
                                                            <DataTable columns={columns} data={data} customStyles={customStyles} pagination responsive highlightOnHover/>
                                                        </div>
                                                    </TabPanel>
                                                    <TabPanel className="tabsReportSalesTabsPanel bg-white p-3">
                                                        <div className="paymentDataTable">
                                                            <DataTable columns={columns} data={data} customStyles={customStyles} pagination responsive highlightOnHover/>
                                                        </div>
                                                    </TabPanel>
                                                    <TabPanel className="tabsReportSalesTabsPanel bg-white p-3">
                                                        <div className="paymentDataTable">
                                                            <DataTable columns={columns} data={data} customStyles={customStyles} pagination responsive highlightOnHover/>
                                                        </div>
                                                    </TabPanel>
                                                    <TabPanel className="tabsReportSalesTabsPanel bg-white p-3">
                                                        <div className="paymentDataTable">
                                                            <DataTable columns={columns} data={data} customStyles={customStyles} pagination responsive highlightOnHover/>
                                                        </div>
                                                    </TabPanel>
                                                </Tabs>
                                            </div>

                                            <div className="bg-white mb-5">
                                                <Row className="g-0">
                                                    <Col className="reportingOverviewBox p-3">
                                                        <div className="">
                                                            <label>Sales QTY</label>
                                                            <p>1605</p>
                                                        </div>
                                                    </Col>
                                                    <Col className="reportingOverviewBox p-3">
                                                        <div className="">
                                                            <label>Net Sales</label>
                                                            <p>1,102,480.00</p>
                                                        </div>
                                                    </Col>
                                                    <Col className="reportingOverviewBox p-3">
                                                        <div className="">
                                                            <label>Scheme Discount</label>
                                                            <p>106,488.25</p>
                                                        </div>
                                                    </Col>
                                                    <Col className="reportingOverviewBox p-3">
                                                        <div className="">
                                                            <label>Total Discount</label>
                                                            <p>106,488.25</p>
                                                        </div>
                                                    </Col>
                                                    <Col className="reportingOverviewBox p-3">
                                                        <div className="">
                                                            <label>Add Sales Tax @13%:</label>
                                                            <p>143,322.40</p>
                                                        </div>
                                                    </Col>
                                                    <Col className="reportingOverviewBox p-3">
                                                        <div className="">
                                                            <label>Service Charges</label>
                                                            <p>6,214.00</p>
                                                        </div>
                                                    </Col>
                                                    <Col className="reportingOverviewBox p-3">
                                                        <div className="">
                                                            <label>Sales Variance:</label>
                                                            <p>-15,032.15</p>
                                                        </div>
                                                    </Col>
                                                    <Col className="reportingOverviewBox p-3">
                                                        <div className="">
                                                            <label>Gross Sales</label>
                                                            <p>1,130,496.00</p>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>

            </div>
        );
    }
}

export default GroupSalesSummaryReport;