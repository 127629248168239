import React, {Component} from 'react';
import {Link} from "react-router-dom";

class Copyright extends Component {
    render() {
        return (
            <div className="copyright">
                <p>Powered by ©ServeEasy Inc. 2021. All Rights Reserved.</p>
                <ul>
                    <li>
                        <Link to="">Privacy Policy</Link>
                    </li>
                    <li>
                        <Link to="">Terms of Service</Link>
                    </li>
                    <li>
                        <Link to="">ServeEasy</Link>
                    </li>
                </ul>

            </div>
        );
    }
}

export default Copyright;