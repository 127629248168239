import React, {Component} from 'react';
import CanvasJSReact from '../../assets/chart/canvasjs.react';

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

class DoughnutChart extends Component {
    render() {
        const options = {
            animationEnabled: true,
            subtitles: [{
                text: "750k",
                verticalAlign: "center",
                fontSize: 25,
                dockInsidePlotArea: false,
                fontColor: "#0178E5",
                fontFamily: 'Source Sans Pro',
                fontWeight: 600,
                maxWidth: 100
            }],
            data: [{
                type: "doughnut",
                indexLabelPlacement: 'inside',
                radius: "100%",
                innerRadius: "55%",
                indexLabelFontFamily: 'Source Sans Pro',
                indexLabelFontWeight: 600,
                indexLabelOrientation: "vertical",
                indexLabelTextAlign: 'center',
                showInLegend: false,
                indexLabelFontSize: 16,
                indexLabelFontColor: '#ffffff',
                indexLabel: "{y}",
                yValueFormatString: "###'%'",
                dataPoints: [
                    {name: "Breakfast", y: 20, color: "#0178E5"},
                    {name: "Lunch", y: 35, color: "#FCBB36"},
                    {name: "Happy Hour", y: 10, color: "#F5697A"},
                    {name: "Dinner", y: 13, color: "#2DC4C1"},
                ]
            }]
        }

        return (
            <div className="resposive_issue">
                <CanvasJSChart options={options}/>
            </div>
        );
    }
}

export default DoughnutChart;