import React, { Component } from 'react'
import { format } from 'date-fns'
import SidebarMenu from "../layouts/SidebarMenu";
import {Button, Col, Container, Form, Row, Tooltip, OverlayTrigger} from "react-bootstrap";
import {Link} from "react-router-dom";
import notification from "../../assets/icons/Notification.svg";
import profile from "../../assets/icons/photo.png";
import 'react-tabs/style/react-tabs.css';
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import Switch from "react-switch";
import ProductSearch from './components/ProductSearch';


export default class Settings extends Component {
    constructor() {
        super();
         this.state = {checked: false};
         this.handleChange = this.handleChange.bind(this);
    }

    handleChange(checked) {
        this.setState({checked});
        console.log(this.state.checked);
    }
    render() {
        return (
            <div>
                <SidebarMenu/>
                <div className="main_page_sectoin">
                    <div className="top_header">
                        <Container>
                            <Row>
                                <Col md={6}>
                                    <div className="left_side">
                                        <div className="drop_down">
                                            <h2>Settings - Operations</h2>
                                        </div>
                                        <div className="data_title">{format(new Date(), 'MM-dd-yyyy')} | {format(new Date(), "eeee")}</div>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="right_side">
                                        <div className="notification-icon">
                                            <Link to="">
                                                <img src={notification} alt="Notification_icon"/>
                                            </Link>
                                        </div>
                                        <div className="profile-icon">
                                            <div className="profile_img">
                                                <img src={profile} alt="profile_icon"/>
                                            </div>
                                            <div className="name_profile">
                                                John Doe
                                                <span>Sales Manager</span>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    {/* topheader ends */}
                    <div className="main_panel">
                        <Container>
                            <Row>
                                <Col md={12}>
                                    <div className="title_content border-bottom_1">
                                        <div className="breadcrumbs">
                                            <Row>
                                                <Col sm={12} md={12} lg={5}>
                                                    <ul className="list-inline">
                                                        <li className="list-inline-item"><a href="#">Performance-Center</a></li>
                                                        <li className="list-inline-item"><a href="#">Settings</a></li>
                                                        <li className="list-inline-item">Operations</li>
                                                    </ul>
                                                </Col>
                                                <Col sm={12} md={12} lg={7}>
                                                    <ProductSearch />
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                    <div className="settingsTabs">
                                        <Tabs>
                                            <TabList>
                                                <Tab>Operations</Tab>
                                                <Tab>Takeaway &amp; Delivery</Tab>
                                                <Tab>Guest Engagement</Tab>
                                                <Tab>FOH</Tab>
                                                <Tab>BOH</Tab>
                                            </TabList>

                                            <TabPanel>
                                                <div class="subSettingsTabs">
                                                    <Tabs>
                                                        <TabList>
                                                            <Tab>Timing Status</Tab>
                                                            <Tab>Login Settings</Tab>
                                                            <Tab>Profile Management</Tab>
                                                            <Tab>Services Management</Tab>
                                                        </TabList>
                                                        <hr/>
                                                        <TabPanel>
                                                            <Row>
                                                                <Col xs={12} sm={12} md={5}>
                                                                    <div className="boxTimings">
                                                                        <div className="boxTimingsHeader">
                                                                            <span className="timingTitle">Shift Status</span>
                                                                        </div>
                                                                        <div className="boxTimingsBody">
                                                                            <Row>
                                                                                <Col xs={5}>
                                                                                    <span className="dayStatus">Day Open</span>
                                                                                    <span className="dayTime">11:30 pm</span>
                                                                                    <span className="dayDate"><small>Tuesday | 07-09-2021</small></span>
                                                                                </Col>
                                                                                <Col xs={5}>
                                                                                    <span className="dayStatus">Day Close</span>
                                                                                    <span className="dayTime">--:-- </span>
                                                                                    <span className="dayDate"><small>-- | --</small></span>
                                                                                </Col>
                                                                                <Col xs={2}>
                                                                                    <Switch id="shiftStatus" checked={this.state.checked} onChange={this.handleChange} onColor="#F7F7F7" offColor="#F7F7F7" onHandleColor="#6EC9A8" offHandleColor="#F5697A" handleDiameter={22} uncheckedIcon={true} checkedIcon={true} height={24} width={44} />
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                    </div>

                                                                    <div className="boxTimings">
                                                                        <div className="boxTimingsHeader">
                                                                            <span className="timingTitle">Business Timings</span>
                                                                        </div>
                                                                        <div className="boxTimingsBody">
                                                                            <Row>
                                                                                <Col xs={5}>
                                                                                    <span className="dayStatus">Day Open</span>
                                                                                    <span className="dayTime">12:00 pm</span>
                                                                                    <span className="dayDate"><small>Tuesday | 07-09-2021</small></span>
                                                                                </Col>
                                                                                <Col xs={5}>
                                                                                    <span className="dayStatus">Day Close</span>
                                                                                    <span className="dayTime">12:00 am</span>
                                                                                    <span className="dayDate"><small>Wednesday | 07-09-2021</small></span>
                                                                                </Col>
                                                                                <Col xs={2}>
                                                                                    <Switch id="businessTimings" checked={this.state.checked} onChange={this.handleChange} onColor="#F7F7F7" offColor="#F7F7F7" onHandleColor="#6EC9A8" offHandleColor="#F5697A" handleDiameter={22} uncheckedIcon={true} checkedIcon={true} height={24} width={44} />
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <Col xs={12} sm={12} md={5}>
                                                                    <div className="boxTimings">
                                                                        <div className="boxTimingsHeader">
                                                                            <span className="timingTitle">Business Timings</span>
                                                                        </div>
                                                                        <div className="boxTimingsBody">
                                                                            <Row>
                                                                                <Col xs={5}>
                                                                                    <span className="dayStatus">Day Open</span>
                                                                                    <span className="dayTime">12:00 pm</span>
                                                                                    <span className="dayDate"><small>Tuesday | 07-09-2021</small></span>
                                                                                </Col>
                                                                                <Col xs={5}>
                                                                                    <span className="dayStatus">Day Close</span>
                                                                                    <span className="dayTime">12:00 am</span>
                                                                                    <span className="dayDate"><small>Wednesday | 07-09-2021</small></span>
                                                                                </Col>
                                                                                <Col xs={2}>
                                                                                    <Switch id="businessTimings" checked={this.state.checked} onChange={this.handleChange} onColor="#F7F7F7" offColor="#F7F7F7" onHandleColor="#6EC9A8" offHandleColor="#F5697A" handleDiameter={22} uncheckedIcon={true} checkedIcon={true} height={24} width={44} />
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                    </div>

                                                                    <div className="boxTimings">
                                                                        <div className="boxTimingsHeader">
                                                                            <span className="timingTitle">Shift Status</span>
                                                                        </div>
                                                                        <div className="boxTimingsBody">
                                                                            <Row>
                                                                                <Col xs={5}>
                                                                                    <span className="dayStatus">Day Open</span>
                                                                                    <span className="dayTime">11:30 pm</span>
                                                                                    <span className="dayDate"><small>Tuesday | 07-09-2021</small></span>
                                                                                </Col>
                                                                                <Col xs={5}>
                                                                                    <span className="dayStatus">Day Close</span>
                                                                                    <span className="dayTime">--:-- </span>
                                                                                    <span className="dayDate"><small>-- | --</small></span>
                                                                                </Col>
                                                                                <Col xs={2}>
                                                                                    <Switch id="shiftStatus" checked={this.state.checked} onChange={this.handleChange} onColor="#F7F7F7" offColor="#F7F7F7" onHandleColor="#6EC9A8" offHandleColor="#F5697A" handleDiameter={22} uncheckedIcon={true} checkedIcon={true} height={24} width={44} />
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </TabPanel>
                                                        <TabPanel>Pills 02 Tab</TabPanel>
                                                        <TabPanel>Pills 03 Tab</TabPanel>
                                                        <TabPanel>Pills 04 Tab</TabPanel>
                                                    </Tabs>

                                                </div>
                                            </TabPanel>
                                            <TabPanel>
                                                <div class="subSettingsTabs clearfix">
                                                    <Tabs>
                                                        <TabList>
                                                            <Tab>Quote Time Strategy</Tab>
                                                            <Tab>Availability</Tab>
                                                            <Tab>ServeEasy Order Success</Tab>
                                                            <Tab>Related Reports</Tab>
                                                        </TabList>
                                                        <hr/>
                                                        <TabPanel>
                                                            <p className="sm-text">Quote times show guests when takeout and delivery orders will be ready. Quote times also control when orders are fired to the kitchen.</p>
                                                            <Row>
                                                                <Col xs={4}>
                                                                    <div className="quoteTimeBox">
                                                                        <div className="quoteTimeBoxHeader">
                                                                            <span className="timingTitle">Manual</span>
                                                                            <p className="timingDescription">Set fixed quote times for takeout<br className="d-none s-sm-none d-md-none d-lg-block"/> and delivery</p>
                                                                            <hr className="blueLine"/>
                                                                        </div>
                                                                        <div className="quoteTimeBoxBody">
                                                                            <span className="quoteTimes">Quote times <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="button-tooltip-2">This is a Tooltip to display short info on hover</Tooltip>}><span className="toolTip">?</span></OverlayTrigger></span>

                                                                            <Form className="formQuoteTimes">
                                                                                <Row>
                                                                                    <Col sm={4}>
                                                                                        
                                                                                    </Col>
                                                                                    <Col sm={4}>
                                                                                        <span className="labelHeading">
                                                                                            Hours
                                                                                        </span>
                                                                                    </Col>
                                                                                    <Col sm={4}>
                                                                                        <span className="labelHeading">
                                                                                            Minutes
                                                                                        </span>
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row className="mb-3 align-items-center g-1">
                                                                                    <Col sm={4}>
                                                                                        <Form.Check type="checkbox" id={`checboxTakeaway`} label={`Takeaway`} />
                                                                                    </Col>
                                                                                    <Col sm={4}>
                                                                                        <Form.Group >
                                                                                            <Form.Select id="MtakeawayHrs" aria-label="Takeaway Hours">
                                                                                                <option value="0Hrs">0Hrs</option>
                                                                                                <option value="2Hrs">2Hrs</option>
                                                                                                <option value="4Hrs">4Hrs</option>
                                                                                                <option value="6Hrs">6Hrs</option>
                                                                                            </Form.Select>
                                                                                        </Form.Group>
                                                                                    </Col>
                                                                                    <Col sm={4}>
                                                                                        <Form.Group >
                                                                                            <Form.Select id="MtakeawayMins" aria-label="Takeaway Minutes">
                                                                                                <option value="10min">10min</option>
                                                                                                <option value="20min">20min</option>
                                                                                                <option value="30min">30min</option>
                                                                                                <option value="40min">40min</option>
                                                                                            </Form.Select>
                                                                                        </Form.Group>
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row className="mb-3 align-items-center g-1">
                                                                                    <Col sm={4}>
                                                                                        <Form.Check type="checkbox" id={`MchecboxDelivery`} label={`Delivery`} />
                                                                                    </Col>
                                                                                    <Col sm={4}>
                                                                                        <Form.Group >
                                                                                            <Form.Select id="MdeliveryHrs" aria-label="Delivery Hours">
                                                                                                <option value="0Hrs">0Hrs</option>
                                                                                                <option value="2Hrs">2Hrs</option>
                                                                                                <option value="4Hrs">4Hrs</option>
                                                                                                <option value="6Hrs">6Hrs</option>
                                                                                            </Form.Select>
                                                                                        </Form.Group>
                                                                                    </Col>
                                                                                    <Col sm={4}>
                                                                                        <Form.Group >
                                                                                            <Form.Select id="MdeliveryMins" aria-label="Delivery Minutes">
                                                                                                <option value="10min">10min</option>
                                                                                                <option value="20min">20min</option>
                                                                                                <option value="30min">30min</option>
                                                                                                <option value="40min">40min</option>
                                                                                            </Form.Select>
                                                                                        </Form.Group>
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row className="mb-3 align-items-center g-1">
                                                                                    <Col sm={4}>
                                                                                        <Form.Check type="checkbox" id={`MchecboxDineIn`} label={`Dine-In`} />
                                                                                    </Col>
                                                                                    <Col sm={4}>
                                                                                        <Form.Group >
                                                                                            <Form.Select id="MdineInHrs" aria-label="Dine-In Hours">
                                                                                                <option value="0Hrs">0Hrs</option>
                                                                                                <option value="2Hrs">2Hrs</option>
                                                                                                <option value="4Hrs">4Hrs</option>
                                                                                                <option value="6Hrs">6Hrs</option>
                                                                                            </Form.Select>
                                                                                        </Form.Group>
                                                                                    </Col>
                                                                                    <Col sm={4}>
                                                                                        <Form.Group >
                                                                                            <Form.Select id="MdineInMins" aria-label="Dine-In Minutes">
                                                                                                <option value="10min">10min</option>
                                                                                                <option value="20min">20min</option>
                                                                                                <option value="30min">30min</option>
                                                                                                <option value="40min">40min</option>
                                                                                            </Form.Select>
                                                                                        </Form.Group>
                                                                                    </Col>
                                                                                </Row>
                                                                            </Form>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <Col xs={4}>
                                                                    <div className="quoteTimeBox">
                                                                        <div className="quoteTimeBoxHeader">
                                                                            <span className="timingTitle">
                                                                                Order Capacity
                                                                            </span>
                                                                            <p className="timingDescription">Automate quote times by how quickly orders are made</p>
                                                                            <hr className="blueLine"/>
                                                                        </div>
                                                                        <div className="quoteTimeBoxBody">
                                                                            <span className="quoteTimes">Quote times <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="button-tooltip-2">This is a Tooltip to display short info on hover</Tooltip>}><span className="toolTip">?</span></OverlayTrigger></span>

                                                                            <Form className="formQuoteTimes">
                                                                                <Row className="g-1">
                                                                                    <Col sm={3}>
                                                                                        
                                                                                    </Col>
                                                                                    <Col sm={2}>
                                                                                        <span className="labelHeading">
                                                                                            Hours
                                                                                        </span>
                                                                                    </Col>
                                                                                    <Col sm={2}>
                                                                                        <span className="labelHeading">
                                                                                            Minutes
                                                                                        </span>
                                                                                    </Col>
                                                                                    <Col sm={2}>
                                                                                        <span className="labelHeading">
                                                                                            Orders
                                                                                        </span>
                                                                                    </Col>
                                                                                    <Col sm={3}>
                                                                                        <span className="labelHeading">
                                                                                            Add Minutes
                                                                                        </span>
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row className="mb-3 align-items-center g-1">
                                                                                    <Col sm={3}>
                                                                                        <Form.Check type="checkbox" id={`OchecboxTakeaway`} label={`Takeaway`} />
                                                                                    </Col>
                                                                                    <Col sm={2}>
                                                                                        <Form.Group >
                                                                                            <Form.Select id="OtakeawayHrs" aria-label="Takeaway Hours">
                                                                                                <option value="0Hrs">0Hrs</option>
                                                                                                <option value="2Hrs">2Hrs</option>
                                                                                                <option value="4Hrs">4Hrs</option>
                                                                                                <option value="6Hrs">6Hrs</option>
                                                                                            </Form.Select>
                                                                                        </Form.Group>
                                                                                    </Col>
                                                                                    <Col sm={2}>
                                                                                        <Form.Group >
                                                                                            <Form.Select id="OtakeawayMins" aria-label="Takeaway Minutes">
                                                                                                <option value="10min">10min</option>
                                                                                                <option value="20min">20min</option>
                                                                                                <option value="30min">30min</option>
                                                                                                <option value="40min">40min</option>
                                                                                            </Form.Select>
                                                                                        </Form.Group>
                                                                                    </Col>
                                                                                    <Col sm={2}>
                                                                                        <Form.Group >
                                                                                            <Form.Select id="OtakeawayHrs" aria-label="Takeaway Hours">
                                                                                                <option value="0Hrs">0Hrs</option>
                                                                                                <option value="2Hrs">2Hrs</option>
                                                                                                <option value="4Hrs">4Hrs</option>
                                                                                                <option value="6Hrs">6Hrs</option>
                                                                                            </Form.Select>
                                                                                        </Form.Group>
                                                                                    </Col>
                                                                                    <Col sm={1} className="text-center">
                                                                                        +
                                                                                    </Col>
                                                                                    <Col sm={2}>
                                                                                        <Form.Group >
                                                                                            <Form.Select id="OtakeawayAddedMins" aria-label="Takeaway Minutes">
                                                                                                <option value="10min">10min</option>
                                                                                                <option value="20min">20min</option>
                                                                                                <option value="30min">30min</option>
                                                                                                <option value="40min">40min</option>
                                                                                            </Form.Select>
                                                                                        </Form.Group>
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row className="mb-3 align-items-center g-1">
                                                                                    <Col sm={3}>
                                                                                        <Form.Check type="checkbox" id={`OchecboxDelivery`} label={`Delivery`} />
                                                                                    </Col>
                                                                                    <Col sm={2}>
                                                                                        <Form.Group >
                                                                                            <Form.Select id="OdeliveryHrs" aria-label="Takeaway Hours">
                                                                                                <option value="0Hrs">0Hrs</option>
                                                                                                <option value="2Hrs">2Hrs</option>
                                                                                                <option value="4Hrs">4Hrs</option>
                                                                                                <option value="6Hrs">6Hrs</option>
                                                                                            </Form.Select>
                                                                                        </Form.Group>
                                                                                    </Col>
                                                                                    <Col sm={2}>
                                                                                        <Form.Group >
                                                                                            <Form.Select id="OdeliveryMins" aria-label="Takeaway Minutes">
                                                                                                <option value="10min">10min</option>
                                                                                                <option value="20min">20min</option>
                                                                                                <option value="30min">30min</option>
                                                                                                <option value="40min">40min</option>
                                                                                            </Form.Select>
                                                                                        </Form.Group>
                                                                                    </Col>
                                                                                    <Col sm={2}>
                                                                                        <Form.Group >
                                                                                            <Form.Select id="OdeliveryHrs" aria-label="Takeaway Hours">
                                                                                                <option value="0Hrs">0Hrs</option>
                                                                                                <option value="2Hrs">2Hrs</option>
                                                                                                <option value="4Hrs">4Hrs</option>
                                                                                                <option value="6Hrs">6Hrs</option>
                                                                                            </Form.Select>
                                                                                        </Form.Group>
                                                                                    </Col>
                                                                                    <Col sm={1} className="text-center">
                                                                                        +
                                                                                    </Col>
                                                                                    <Col sm={2}>
                                                                                        <Form.Group >
                                                                                            <Form.Select id="OdeliveryAddedMins" aria-label="Takeaway Minutes">
                                                                                                <option value="10min">10min</option>
                                                                                                <option value="20min">20min</option>
                                                                                                <option value="30min">30min</option>
                                                                                                <option value="40min">40min</option>
                                                                                            </Form.Select>
                                                                                        </Form.Group>
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row className="mb-3 align-items-center g-1">
                                                                                    <Col sm={3}>
                                                                                        <Form.Check type="checkbox" id={`OchecboxDineIn`} label={`Dine-In`} />
                                                                                    </Col>
                                                                                    <Col sm={2}>
                                                                                        <Form.Group >
                                                                                            <Form.Select id="OdineInHrs" aria-label="Takeaway Hours">
                                                                                                <option value="0Hrs">0Hrs</option>
                                                                                                <option value="2Hrs">2Hrs</option>
                                                                                                <option value="4Hrs">4Hrs</option>
                                                                                                <option value="6Hrs">6Hrs</option>
                                                                                            </Form.Select>
                                                                                        </Form.Group>
                                                                                    </Col>
                                                                                    <Col sm={2}>
                                                                                        <Form.Group >
                                                                                            <Form.Select id="OdineInMins" aria-label="Takeaway Minutes">
                                                                                                <option value="10min">10min</option>
                                                                                                <option value="20min">20min</option>
                                                                                                <option value="30min">30min</option>
                                                                                                <option value="40min">40min</option>
                                                                                            </Form.Select>
                                                                                        </Form.Group>
                                                                                    </Col>
                                                                                    <Col sm={2}>
                                                                                        <Form.Group >
                                                                                            <Form.Select id="OdineInHrs" aria-label="Takeaway Hours">
                                                                                                <option value="0Hrs">0Hrs</option>
                                                                                                <option value="2Hrs">2Hrs</option>
                                                                                                <option value="4Hrs">4Hrs</option>
                                                                                                <option value="6Hrs">6Hrs</option>
                                                                                            </Form.Select>
                                                                                        </Form.Group>
                                                                                    </Col>
                                                                                    <Col sm={1} className="text-center">
                                                                                        +
                                                                                    </Col>
                                                                                    <Col sm={2}>
                                                                                        <Form.Group >
                                                                                            <Form.Select id="OdineInAddedMins" aria-label="Takeaway Minutes">
                                                                                                <option value="10min">10min</option>
                                                                                                <option value="20min">20min</option>
                                                                                                <option value="30min">30min</option>
                                                                                                <option value="40min">40min</option>
                                                                                            </Form.Select>
                                                                                        </Form.Group>
                                                                                    </Col>
                                                                                </Row>
                                                                            </Form>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <Col xs={4}>
                                                                    <div className="quoteTimeBox">
                                                                        <div className="quoteTimeBoxHeader">
                                                                            <span className="timingTitle">
                                                                                Order Capacity
                                                                            </span>
                                                                            <p className="timingDescription">Automate quote times by how quickly orders are made</p>
                                                                            <hr className="blueLine"/>
                                                                        </div>
                                                                        <div className="quoteTimeBoxBody">
                                                                            <span className="quoteTimes">Quote times <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="button-tooltip-2">This is a Tooltip to display short info on hover</Tooltip>}><span className="toolTip">?</span></OverlayTrigger></span>

                                                                            <Form className="formQuoteTimes">
                                                                                <Row className="g-1">
                                                                                    <Col sm={3}>
                                                                                        
                                                                                    </Col>
                                                                                    <Col sm={2}>
                                                                                        <span className="labelHeading">
                                                                                            Hours
                                                                                        </span>
                                                                                    </Col>
                                                                                    <Col sm={2}>
                                                                                        <span className="labelHeading">
                                                                                            Minutes
                                                                                        </span>
                                                                                    </Col>
                                                                                    <Col sm={2}>
                                                                                        <span className="labelHeading">
                                                                                            Orders
                                                                                        </span>
                                                                                    </Col>
                                                                                    <Col sm={3}>
                                                                                        <span className="labelHeading">
                                                                                            Add Minutes
                                                                                        </span>
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row className="mb-3 align-items-center g-1">
                                                                                    <Col sm={3}>
                                                                                        <Form.Check type="checkbox" id={`OchecboxTakeaway`} label={`Takeaway`} />
                                                                                    </Col>
                                                                                    <Col sm={2}>
                                                                                        <Form.Group >
                                                                                            <Form.Select id="OtakeawayHrs" aria-label="Takeaway Hours">
                                                                                                <option value="0Hrs">0Hrs</option>
                                                                                                <option value="2Hrs">2Hrs</option>
                                                                                                <option value="4Hrs">4Hrs</option>
                                                                                                <option value="6Hrs">6Hrs</option>
                                                                                            </Form.Select>
                                                                                        </Form.Group>
                                                                                    </Col>
                                                                                    <Col sm={2}>
                                                                                        <Form.Group >
                                                                                            <Form.Select id="OtakeawayMins" aria-label="Takeaway Minutes">
                                                                                                <option value="10min">10min</option>
                                                                                                <option value="20min">20min</option>
                                                                                                <option value="30min">30min</option>
                                                                                                <option value="40min">40min</option>
                                                                                            </Form.Select>
                                                                                        </Form.Group>
                                                                                    </Col>
                                                                                    <Col sm={2}>
                                                                                        <Form.Group >
                                                                                            <Form.Select id="OtakeawayHrs" aria-label="Takeaway Hours">
                                                                                                <option value="0Hrs">0Hrs</option>
                                                                                                <option value="2Hrs">2Hrs</option>
                                                                                                <option value="4Hrs">4Hrs</option>
                                                                                                <option value="6Hrs">6Hrs</option>
                                                                                            </Form.Select>
                                                                                        </Form.Group>
                                                                                    </Col>
                                                                                    <Col sm={1} className="text-center">
                                                                                        +
                                                                                    </Col>
                                                                                    <Col sm={2}>
                                                                                        <Form.Group >
                                                                                            <Form.Select id="OtakeawayAddedMins" aria-label="Takeaway Minutes">
                                                                                                <option value="10min">10min</option>
                                                                                                <option value="20min">20min</option>
                                                                                                <option value="30min">30min</option>
                                                                                                <option value="40min">40min</option>
                                                                                            </Form.Select>
                                                                                        </Form.Group>
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row className="mb-3 align-items-center g-1">
                                                                                    <Col sm={3}>
                                                                                        <Form.Check type="checkbox" id={`OchecboxDelivery`} label={`Delivery`} />
                                                                                    </Col>
                                                                                    <Col sm={2}>
                                                                                        <Form.Group >
                                                                                            <Form.Select id="OdeliveryHrs" aria-label="Takeaway Hours">
                                                                                                <option value="0Hrs">0Hrs</option>
                                                                                                <option value="2Hrs">2Hrs</option>
                                                                                                <option value="4Hrs">4Hrs</option>
                                                                                                <option value="6Hrs">6Hrs</option>
                                                                                            </Form.Select>
                                                                                        </Form.Group>
                                                                                    </Col>
                                                                                    <Col sm={2}>
                                                                                        <Form.Group >
                                                                                            <Form.Select id="OdeliveryMins" aria-label="Takeaway Minutes">
                                                                                                <option value="10min">10min</option>
                                                                                                <option value="20min">20min</option>
                                                                                                <option value="30min">30min</option>
                                                                                                <option value="40min">40min</option>
                                                                                            </Form.Select>
                                                                                        </Form.Group>
                                                                                    </Col>
                                                                                    <Col sm={2}>
                                                                                        <Form.Group >
                                                                                            <Form.Select id="OdeliveryHrs" aria-label="Takeaway Hours">
                                                                                                <option value="0Hrs">0Hrs</option>
                                                                                                <option value="2Hrs">2Hrs</option>
                                                                                                <option value="4Hrs">4Hrs</option>
                                                                                                <option value="6Hrs">6Hrs</option>
                                                                                            </Form.Select>
                                                                                        </Form.Group>
                                                                                    </Col>
                                                                                    <Col sm={1} className="text-center">
                                                                                        +
                                                                                    </Col>
                                                                                    <Col sm={2}>
                                                                                        <Form.Group >
                                                                                            <Form.Select id="OdeliveryAddedMins" aria-label="Takeaway Minutes">
                                                                                                <option value="10min">10min</option>
                                                                                                <option value="20min">20min</option>
                                                                                                <option value="30min">30min</option>
                                                                                                <option value="40min">40min</option>
                                                                                            </Form.Select>
                                                                                        </Form.Group>
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row className="mb-3 align-items-center g-1">
                                                                                    <Col sm={3}>
                                                                                        <Form.Check type="checkbox" id={`OchecboxDineIn`} label={`Dine-In`} />
                                                                                    </Col>
                                                                                    <Col sm={2}>
                                                                                        <Form.Group >
                                                                                            <Form.Select id="OdineInHrs" aria-label="Takeaway Hours">
                                                                                                <option value="0Hrs">0Hrs</option>
                                                                                                <option value="2Hrs">2Hrs</option>
                                                                                                <option value="4Hrs">4Hrs</option>
                                                                                                <option value="6Hrs">6Hrs</option>
                                                                                            </Form.Select>
                                                                                        </Form.Group>
                                                                                    </Col>
                                                                                    <Col sm={2}>
                                                                                        <Form.Group >
                                                                                            <Form.Select id="OdineInMins" aria-label="Takeaway Minutes">
                                                                                                <option value="10min">10min</option>
                                                                                                <option value="20min">20min</option>
                                                                                                <option value="30min">30min</option>
                                                                                                <option value="40min">40min</option>
                                                                                            </Form.Select>
                                                                                        </Form.Group>
                                                                                    </Col>
                                                                                    <Col sm={2}>
                                                                                        <Form.Group >
                                                                                            <Form.Select id="OdineInHrs" aria-label="Takeaway Hours">
                                                                                                <option value="0Hrs">0Hrs</option>
                                                                                                <option value="2Hrs">2Hrs</option>
                                                                                                <option value="4Hrs">4Hrs</option>
                                                                                                <option value="6Hrs">6Hrs</option>
                                                                                            </Form.Select>
                                                                                        </Form.Group>
                                                                                    </Col>
                                                                                    <Col sm={1} className="text-center">
                                                                                        +
                                                                                    </Col>
                                                                                    <Col sm={2}>
                                                                                        <Form.Group >
                                                                                            <Form.Select id="OdineInAddedMins" aria-label="Takeaway Minutes">
                                                                                                <option value="10min">10min</option>
                                                                                                <option value="20min">20min</option>
                                                                                                <option value="30min">30min</option>
                                                                                                <option value="40min">40min</option>
                                                                                            </Form.Select>
                                                                                        </Form.Group>
                                                                                    </Col>
                                                                                </Row>
                                                                            </Form>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                
                                                            </Row>

                                                        </TabPanel>
                                                        <TabPanel>
                                                            <Tabs className="availabilityTabs">
                                                                <TabList className="availabilityTabList">
                                                                    <Tab>
                                                                        <span className="headingTab">online Ordering</span>
                                                                        <p>Enable online ordering and customize approval rules, payment methods and more</p>
                                                                    </Tab>
                                                                    <Tab>
                                                                        <span className="headingTab">Takeout/delivery</span>
                                                                        <p>Enable online ordering and customize approval rules, payment methods and more</p>
                                                                    </Tab>
                                                                    <Tab>
                                                                        <span className="headingTab">Online Ordering Schedule</span>
                                                                        <p>Enable online ordering and customize approval rules, payment methods and more</p>
                                                                    </Tab>
                                                                    <Tab>
                                                                        <span className="headingTab">Prep/delivery times</span>
                                                                        <p>Enable online ordering and customize approval rules, payment methods and more</p>
                                                                    </Tab>
                                                                </TabList>
                                                                <TabPanel className="availabilityTabPanels">
                                                                    <Tabs className="availabilitySubTabs">
                                                                        <TabList className="availabilitySubTabsList">
                                                                            <Tab>Toggle Online Ordering</Tab>
                                                                            <Tab>Spotlight Feature</Tab>
                                                                            <Tab>Highlight Popular Menu Items</Tab>
                                                                            <Tab>Menu Item Upsells</Tab>
                                                                            <Tab>Approval</Tab>
                                                                            <Tab>Online Ordering Scheduling Settings</Tab>
                                                                            <Tab>Minimum Lead Time Settings</Tab>
                                                                            <Tab>Dining Options</Tab>
                                                                            <Tab>Server Selection</Tab>
                                                                            <Tab>Revenue Center</Tab>
                                                                            <Tab>Special Requests</Tab>
                                                                            <Tab>Takeout Payment Options</Tab>
                                                                            <Tab>Allow Credit Card Tips</Tab>
                                                                        </TabList>
                                                                        <TabPanel className="availabilitySubPanels">
                                                                            <Form className="formToggleOnlineOrdering">
                                                                                <Form.Group>
                                                                                    <Form.Check label="Accept Online Orders" name="onlineOrdering-radio" type="radio" id="acceptOnlineOrdering" aria-label="Accept Online Orders" />
                                                                                    <Form.Check label="Snooze online orders temporarily" name="onlineOrdering-radio" type="radio" id="snoozeOnlineOrdering" aria-label="Snooze online orders temporarily" />
                                                                                    <Form.Check label="Don&#39;t accept online orders" name="onlineOrdering-radio" type="radio" id="notAcceptOnlineOrdering" aria-label="Don&#39;t accept online orders" />
                                                                                </Form.Group>
                                                                            </Form>
                                                                        </TabPanel>
                                                                        <TabPanel className="availabilitySubPanels">availabilitySubTabsListPanels 2</TabPanel>
                                                                        <TabPanel className="availabilitySubPanels">availabilitySubTabsListPanels 3</TabPanel>
                                                                        <TabPanel className="availabilitySubPanels">availabilitySubTabsListPanels 4</TabPanel>
                                                                        <TabPanel className="availabilitySubPanels">availabilitySubTabsListPanels 5</TabPanel>
                                                                        <TabPanel className="availabilitySubPanels">availabilitySubTabsListPanels 6</TabPanel>
                                                                        <TabPanel className="availabilitySubPanels">availabilitySubTabsListPanels 7</TabPanel>
                                                                        <TabPanel className="availabilitySubPanels">availabilitySubTabsListPanels 8</TabPanel>
                                                                        <TabPanel className="availabilitySubPanels">availabilitySubTabsListPanels 9</TabPanel>
                                                                        <TabPanel className="availabilitySubPanels">availabilitySubTabsListPanels 10</TabPanel>
                                                                        <TabPanel className="availabilitySubPanels">availabilitySubTabsListPanels 11</TabPanel>
                                                                        <TabPanel className="availabilitySubPanels">availabilitySubTabsListPanels 12</TabPanel>
                                                                        <TabPanel className="availabilitySubPanels">availabilitySubTabsListPanels 13</TabPanel>
                                                                    </Tabs>
                                                                </TabPanel>
                                                                <TabPanel className="availabilityTabPanels bg-success">Takeout/delivery Tab</TabPanel>
                                                                <TabPanel className="availabilityTabPanels bg-success">Online Ordering Schedule Tab</TabPanel>
                                                                <TabPanel className="availabilityTabPanels bg-success">Prep/delivery times Tab</TabPanel>
                                                            </Tabs>
                                                        </TabPanel>
                                                        <TabPanel>Pills 03 Tab</TabPanel>
                                                        <TabPanel>Pills 04 Tab</TabPanel>
                                                    </Tabs>

                                                </div>
                                            </TabPanel>
                                            <TabPanel>
                                                tab guest
                                            </TabPanel>
                                            <TabPanel>
                                                tab FOH
                                            </TabPanel>
                                            <TabPanel>
                                                tab BOH
                                            </TabPanel>
                                        </Tabs>

                                    </div>

                                </Col>

                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
        )
    }
}
