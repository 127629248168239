import React, {Component} from 'react';
import { format } from 'date-fns'
import SidebarMenu from "../layouts/SidebarMenu";
import { Button, Col, Container, Form, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import notification from "../../assets/icons/Notification.svg";
import profile from "../../assets/icons/photo.png";
import ProductSearch from './components/ProductSearch';
import file from "../../assets/icons/file.svg";
import file1 from "../../assets/icons/file.png";
import Switch from "react-switch";
import search from "../../assets/icons/search.svg";

class MenuDropdownDiscountFixedOffEdit extends Component {
    constructor() {
        super();
        this.state = {checked: false};
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(checked) {
        this.setState({checked});
    }
    
    


    render() {
        return (
            <div className="paymentsPage customMenus">
                <SidebarMenu/>
                <div className="main_page_sectoin">
                    <div className="top_header">
                        <Container>
                            <Row>
                                <Col md={6}>
                                    <div className="left_side">
                                        <div className="drop_down">
                                            <h2>Menu - Custom Menus - Edit</h2>
                                        </div>
                                        <div className="data_title">{format(new Date(), 'MM-dd-yyyy')} | {format(new Date(), "eeee")}</div>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="right_side">
                                        <div className="notification-icon">
                                            <Link to="">
                                                <img src={notification} alt="Notification_icon"/>
                                            </Link>
                                        </div>
                                        <div className="profile-icon">
                                            <div className="profile_img">
                                                <img src={profile} alt="profile_icon"/>
                                            </div>
                                            <div className="name_profile">
                                                John Doe
                                                <span>Sales Manager</span>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <div className="main_panel">
                        <Container>
                            <Row>
                                <Col md={12}>
                                <div className="title_content border-bottom_1">
                                        <div className="breadcrumbs">
                                            <Row>
                                                <Col sm={12} md={12} lg={5}>
                                                    <ul className="list-inline">
                                                        <li className="list-inline-item"><a href="#">Performance-Center</a></li>
                                                        <li className="list-inline-item"><a href="#">whatever</a></li>
                                                        <li className="list-inline-item">Payments</li>
                                                    </ul>
                                                </Col>
                                                <Col sm={12} md={12} lg={7}>
                                                    <ProductSearch />
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                    <div className="menu_listing bg-white">
                                        <h3>Fixed % Off</h3>
                                        <Form className="tab_froms">
                                        <Row>
                                            <Col md={4}>
                                                <Form.Group className="div_from">
                                                    <Form.Label>Name
                                                        <svg width="15" height="15"
                                                            viewBox="0 0 15 15" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <rect width="15" height="15" rx="7.5"
                                                                fill="#DEDEDE" />
                                                            <path
                                                                d="M7.232 8.152C7.20533 7.97067 7.21067 7.80533 7.248 7.656C7.29067 7.50667 7.34933 7.368 7.424 7.24C7.49867 7.112 7.58133 6.99467 7.672 6.888C7.768 6.78133 7.85867 6.68 7.944 6.584C8.02933 6.48267 8.09867 6.38667 8.152 6.296C8.21067 6.2 8.24 6.10133 8.24 6C8.24 5.83467 8.18933 5.70933 8.088 5.624C7.992 5.53867 7.864 5.496 7.704 5.496C7.55467 5.496 7.42133 5.53067 7.304 5.6C7.192 5.66933 7.08267 5.75733 6.976 5.864L6.328 5.272C6.51467 5.05333 6.736 4.87733 6.992 4.744C7.25333 4.61067 7.54133 4.544 7.856 4.544C8.06933 4.544 8.26667 4.57067 8.448 4.624C8.63467 4.67733 8.79467 4.76 8.928 4.872C9.06667 4.984 9.17333 5.128 9.248 5.304C9.328 5.47467 9.368 5.67733 9.368 5.912C9.368 6.07733 9.33867 6.22667 9.28 6.36C9.22133 6.488 9.14667 6.608 9.056 6.72C8.97067 6.832 8.87733 6.94133 8.776 7.048C8.68 7.14933 8.58933 7.256 8.504 7.368C8.424 7.48 8.36 7.6 8.312 7.728C8.264 7.85067 8.248 7.992 8.264 8.152H7.232ZM7.744 10.096C7.54133 10.096 7.37333 10.0267 7.24 9.888C7.10667 9.744 7.04 9.568 7.04 9.36C7.04 9.14667 7.10667 8.97067 7.24 8.832C7.37333 8.688 7.54133 8.616 7.744 8.616C7.94667 8.616 8.11467 8.688 8.248 8.832C8.38667 8.97067 8.456 9.14667 8.456 9.36C8.456 9.568 8.38667 9.744 8.248 9.888C8.11467 10.0267 7.94667 10.096 7.744 10.096Z"
                                                                fill="#4D5464" />
                                                        </svg>
                                                    </Form.Label>
                                                    <Form.Control type="text" id="" name="" placeholder="Azaadi Offer" />
                                                </Form.Group>
                                                <Form.Group className="div_from">
                                                    <Form.Label>Value
                                                        <svg width="15" height="15"
                                                            viewBox="0 0 15 15" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <rect width="15" height="15" rx="7.5"
                                                                fill="#DEDEDE" />
                                                            <path
                                                                d="M7.232 8.152C7.20533 7.97067 7.21067 7.80533 7.248 7.656C7.29067 7.50667 7.34933 7.368 7.424 7.24C7.49867 7.112 7.58133 6.99467 7.672 6.888C7.768 6.78133 7.85867 6.68 7.944 6.584C8.02933 6.48267 8.09867 6.38667 8.152 6.296C8.21067 6.2 8.24 6.10133 8.24 6C8.24 5.83467 8.18933 5.70933 8.088 5.624C7.992 5.53867 7.864 5.496 7.704 5.496C7.55467 5.496 7.42133 5.53067 7.304 5.6C7.192 5.66933 7.08267 5.75733 6.976 5.864L6.328 5.272C6.51467 5.05333 6.736 4.87733 6.992 4.744C7.25333 4.61067 7.54133 4.544 7.856 4.544C8.06933 4.544 8.26667 4.57067 8.448 4.624C8.63467 4.67733 8.79467 4.76 8.928 4.872C9.06667 4.984 9.17333 5.128 9.248 5.304C9.328 5.47467 9.368 5.67733 9.368 5.912C9.368 6.07733 9.33867 6.22667 9.28 6.36C9.22133 6.488 9.14667 6.608 9.056 6.72C8.97067 6.832 8.87733 6.94133 8.776 7.048C8.68 7.14933 8.58933 7.256 8.504 7.368C8.424 7.48 8.36 7.6 8.312 7.728C8.264 7.85067 8.248 7.992 8.264 8.152H7.232ZM7.744 10.096C7.54133 10.096 7.37333 10.0267 7.24 9.888C7.10667 9.744 7.04 9.568 7.04 9.36C7.04 9.14667 7.10667 8.97067 7.24 8.832C7.37333 8.688 7.54133 8.616 7.744 8.616C7.94667 8.616 8.11467 8.688 8.248 8.832C8.38667 8.97067 8.456 9.14667 8.456 9.36C8.456 9.568 8.38667 9.744 8.248 9.888C8.11467 10.0267 7.94667 10.096 7.744 10.096Z"
                                                                fill="#4D5464" />
                                                        </svg>
                                                    </Form.Label>
                                                    <Form.Control type="text" id="" name="" placeholder="40% " />
                                                </Form.Group>
                                                <Form.Group className="div_from">
                                                    <Form.Label>Bill Print Name
                                                        <svg width="15" height="15"
                                                            viewBox="0 0 15 15" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <rect width="15" height="15" rx="7.5"
                                                                fill="#DEDEDE" />
                                                            <path
                                                                d="M7.232 8.152C7.20533 7.97067 7.21067 7.80533 7.248 7.656C7.29067 7.50667 7.34933 7.368 7.424 7.24C7.49867 7.112 7.58133 6.99467 7.672 6.888C7.768 6.78133 7.85867 6.68 7.944 6.584C8.02933 6.48267 8.09867 6.38667 8.152 6.296C8.21067 6.2 8.24 6.10133 8.24 6C8.24 5.83467 8.18933 5.70933 8.088 5.624C7.992 5.53867 7.864 5.496 7.704 5.496C7.55467 5.496 7.42133 5.53067 7.304 5.6C7.192 5.66933 7.08267 5.75733 6.976 5.864L6.328 5.272C6.51467 5.05333 6.736 4.87733 6.992 4.744C7.25333 4.61067 7.54133 4.544 7.856 4.544C8.06933 4.544 8.26667 4.57067 8.448 4.624C8.63467 4.67733 8.79467 4.76 8.928 4.872C9.06667 4.984 9.17333 5.128 9.248 5.304C9.328 5.47467 9.368 5.67733 9.368 5.912C9.368 6.07733 9.33867 6.22667 9.28 6.36C9.22133 6.488 9.14667 6.608 9.056 6.72C8.97067 6.832 8.87733 6.94133 8.776 7.048C8.68 7.14933 8.58933 7.256 8.504 7.368C8.424 7.48 8.36 7.6 8.312 7.728C8.264 7.85067 8.248 7.992 8.264 8.152H7.232ZM7.744 10.096C7.54133 10.096 7.37333 10.0267 7.24 9.888C7.10667 9.744 7.04 9.568 7.04 9.36C7.04 9.14667 7.10667 8.97067 7.24 8.832C7.37333 8.688 7.54133 8.616 7.744 8.616C7.94667 8.616 8.11467 8.688 8.248 8.832C8.38667 8.97067 8.456 9.14667 8.456 9.36C8.456 9.568 8.38667 9.744 8.248 9.888C8.11467 10.0267 7.94667 10.096 7.744 10.096Z"
                                                                fill="#4D5464" />
                                                        </svg>
                                                    </Form.Label>
                                                    <Form.Control type="text" id="" name="" placeholder="Azaadi 40%" />
                                                </Form.Group>
                                                <Row>
                                                    <Col sm={12} md={6}>
                                                        <Form.Group className="div_from">
                                                            <Form.Label className="mb-3">Applies To
                                                                <svg width="15" height="15"
                                                                    viewBox="0 0 15 15" fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg">
                                                                    <rect width="15" height="15" rx="7.5"
                                                                        fill="#DEDEDE" />
                                                                    <path
                                                                        d="M7.232 8.152C7.20533 7.97067 7.21067 7.80533 7.248 7.656C7.29067 7.50667 7.34933 7.368 7.424 7.24C7.49867 7.112 7.58133 6.99467 7.672 6.888C7.768 6.78133 7.85867 6.68 7.944 6.584C8.02933 6.48267 8.09867 6.38667 8.152 6.296C8.21067 6.2 8.24 6.10133 8.24 6C8.24 5.83467 8.18933 5.70933 8.088 5.624C7.992 5.53867 7.864 5.496 7.704 5.496C7.55467 5.496 7.42133 5.53067 7.304 5.6C7.192 5.66933 7.08267 5.75733 6.976 5.864L6.328 5.272C6.51467 5.05333 6.736 4.87733 6.992 4.744C7.25333 4.61067 7.54133 4.544 7.856 4.544C8.06933 4.544 8.26667 4.57067 8.448 4.624C8.63467 4.67733 8.79467 4.76 8.928 4.872C9.06667 4.984 9.17333 5.128 9.248 5.304C9.328 5.47467 9.368 5.67733 9.368 5.912C9.368 6.07733 9.33867 6.22667 9.28 6.36C9.22133 6.488 9.14667 6.608 9.056 6.72C8.97067 6.832 8.87733 6.94133 8.776 7.048C8.68 7.14933 8.58933 7.256 8.504 7.368C8.424 7.48 8.36 7.6 8.312 7.728C8.264 7.85067 8.248 7.992 8.264 8.152H7.232ZM7.744 10.096C7.54133 10.096 7.37333 10.0267 7.24 9.888C7.10667 9.744 7.04 9.568 7.04 9.36C7.04 9.14667 7.10667 8.97067 7.24 8.832C7.37333 8.688 7.54133 8.616 7.744 8.616C7.94667 8.616 8.11467 8.688 8.248 8.832C8.38667 8.97067 8.456 9.14667 8.456 9.36C8.456 9.568 8.38667 9.744 8.248 9.888C8.11467 10.0267 7.94667 10.096 7.744 10.096Z"
                                                                        fill="#4D5464" />
                                                                </svg>
                                                            </Form.Label>
                                                            <Form.Check type="checkbox" id="itemName01" label="Any Item" />
                                                            <Form.Check type="checkbox" id="itemName01" label="Entire Check" />
                                                            <Form.Check type="checkbox" id="itemName01" label="Specific Item/Group" />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col sm={12} md={6}>
                                                        <Form.Group className="div_from">
                                                            <Form.Label className="mb-3">Applicable On
                                                                <svg width="15" height="15"
                                                                    viewBox="0 0 15 15" fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg">
                                                                    <rect width="15" height="15" rx="7.5"
                                                                        fill="#DEDEDE" />
                                                                    <path
                                                                        d="M7.232 8.152C7.20533 7.97067 7.21067 7.80533 7.248 7.656C7.29067 7.50667 7.34933 7.368 7.424 7.24C7.49867 7.112 7.58133 6.99467 7.672 6.888C7.768 6.78133 7.85867 6.68 7.944 6.584C8.02933 6.48267 8.09867 6.38667 8.152 6.296C8.21067 6.2 8.24 6.10133 8.24 6C8.24 5.83467 8.18933 5.70933 8.088 5.624C7.992 5.53867 7.864 5.496 7.704 5.496C7.55467 5.496 7.42133 5.53067 7.304 5.6C7.192 5.66933 7.08267 5.75733 6.976 5.864L6.328 5.272C6.51467 5.05333 6.736 4.87733 6.992 4.744C7.25333 4.61067 7.54133 4.544 7.856 4.544C8.06933 4.544 8.26667 4.57067 8.448 4.624C8.63467 4.67733 8.79467 4.76 8.928 4.872C9.06667 4.984 9.17333 5.128 9.248 5.304C9.328 5.47467 9.368 5.67733 9.368 5.912C9.368 6.07733 9.33867 6.22667 9.28 6.36C9.22133 6.488 9.14667 6.608 9.056 6.72C8.97067 6.832 8.87733 6.94133 8.776 7.048C8.68 7.14933 8.58933 7.256 8.504 7.368C8.424 7.48 8.36 7.6 8.312 7.728C8.264 7.85067 8.248 7.992 8.264 8.152H7.232ZM7.744 10.096C7.54133 10.096 7.37333 10.0267 7.24 9.888C7.10667 9.744 7.04 9.568 7.04 9.36C7.04 9.14667 7.10667 8.97067 7.24 8.832C7.37333 8.688 7.54133 8.616 7.744 8.616C7.94667 8.616 8.11467 8.688 8.248 8.832C8.38667 8.97067 8.456 9.14667 8.456 9.36C8.456 9.568 8.38667 9.744 8.248 9.888C8.11467 10.0267 7.94667 10.096 7.744 10.096Z"
                                                                        fill="#4D5464" />
                                                                </svg>
                                                            </Form.Label>
                                                            <Form.Check type="checkbox" id="itemName01" label="Cash On Delivery" />
                                                            <Form.Check type="checkbox" id="itemName01" label="Cards" />
                                                            <Form.Check type="checkbox" id="itemName01" label="Third Party Transection" />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col sm={12} md={6}>
                                                        <Form.Group className="div_from">
                                                            <Form.Label className="mb-3">Applicable On
                                                                <svg width="15" height="15"
                                                                    viewBox="0 0 15 15" fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg">
                                                                    <rect width="15" height="15" rx="7.5"
                                                                        fill="#DEDEDE" />
                                                                    <path
                                                                        d="M7.232 8.152C7.20533 7.97067 7.21067 7.80533 7.248 7.656C7.29067 7.50667 7.34933 7.368 7.424 7.24C7.49867 7.112 7.58133 6.99467 7.672 6.888C7.768 6.78133 7.85867 6.68 7.944 6.584C8.02933 6.48267 8.09867 6.38667 8.152 6.296C8.21067 6.2 8.24 6.10133 8.24 6C8.24 5.83467 8.18933 5.70933 8.088 5.624C7.992 5.53867 7.864 5.496 7.704 5.496C7.55467 5.496 7.42133 5.53067 7.304 5.6C7.192 5.66933 7.08267 5.75733 6.976 5.864L6.328 5.272C6.51467 5.05333 6.736 4.87733 6.992 4.744C7.25333 4.61067 7.54133 4.544 7.856 4.544C8.06933 4.544 8.26667 4.57067 8.448 4.624C8.63467 4.67733 8.79467 4.76 8.928 4.872C9.06667 4.984 9.17333 5.128 9.248 5.304C9.328 5.47467 9.368 5.67733 9.368 5.912C9.368 6.07733 9.33867 6.22667 9.28 6.36C9.22133 6.488 9.14667 6.608 9.056 6.72C8.97067 6.832 8.87733 6.94133 8.776 7.048C8.68 7.14933 8.58933 7.256 8.504 7.368C8.424 7.48 8.36 7.6 8.312 7.728C8.264 7.85067 8.248 7.992 8.264 8.152H7.232ZM7.744 10.096C7.54133 10.096 7.37333 10.0267 7.24 9.888C7.10667 9.744 7.04 9.568 7.04 9.36C7.04 9.14667 7.10667 8.97067 7.24 8.832C7.37333 8.688 7.54133 8.616 7.744 8.616C7.94667 8.616 8.11467 8.688 8.248 8.832C8.38667 8.97067 8.456 9.14667 8.456 9.36C8.456 9.568 8.38667 9.744 8.248 9.888C8.11467 10.0267 7.94667 10.096 7.744 10.096Z"
                                                                        fill="#4D5464" />
                                                                </svg>
                                                            </Form.Label>
                                                            <Form.Check type="checkbox" id="itemName01" label="All" />
                                                            <Form.Check type="checkbox" id="itemName01" label="DineIn" />
                                                            <Form.Check type="checkbox" id="itemName01" label="Takeaway" />
                                                            <Form.Check type="checkbox" id="itemName01" label="Delivery" />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md={4} className="border-left pl-4">
                                                <Form.Group className="div_from">
                                                    <Form.Label className="mb-3">Eligible for Discounts
                                                        <svg width="15" height="15"
                                                            viewBox="0 0 15 15" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <rect width="15" height="15" rx="7.5"
                                                                fill="#DEDEDE" />
                                                            <path
                                                                d="M7.232 8.152C7.20533 7.97067 7.21067 7.80533 7.248 7.656C7.29067 7.50667 7.34933 7.368 7.424 7.24C7.49867 7.112 7.58133 6.99467 7.672 6.888C7.768 6.78133 7.85867 6.68 7.944 6.584C8.02933 6.48267 8.09867 6.38667 8.152 6.296C8.21067 6.2 8.24 6.10133 8.24 6C8.24 5.83467 8.18933 5.70933 8.088 5.624C7.992 5.53867 7.864 5.496 7.704 5.496C7.55467 5.496 7.42133 5.53067 7.304 5.6C7.192 5.66933 7.08267 5.75733 6.976 5.864L6.328 5.272C6.51467 5.05333 6.736 4.87733 6.992 4.744C7.25333 4.61067 7.54133 4.544 7.856 4.544C8.06933 4.544 8.26667 4.57067 8.448 4.624C8.63467 4.67733 8.79467 4.76 8.928 4.872C9.06667 4.984 9.17333 5.128 9.248 5.304C9.328 5.47467 9.368 5.67733 9.368 5.912C9.368 6.07733 9.33867 6.22667 9.28 6.36C9.22133 6.488 9.14667 6.608 9.056 6.72C8.97067 6.832 8.87733 6.94133 8.776 7.048C8.68 7.14933 8.58933 7.256 8.504 7.368C8.424 7.48 8.36 7.6 8.312 7.728C8.264 7.85067 8.248 7.992 8.264 8.152H7.232ZM7.744 10.096C7.54133 10.096 7.37333 10.0267 7.24 9.888C7.10667 9.744 7.04 9.568 7.04 9.36C7.04 9.14667 7.10667 8.97067 7.24 8.832C7.37333 8.688 7.54133 8.616 7.744 8.616C7.94667 8.616 8.11467 8.688 8.248 8.832C8.38667 8.97067 8.456 9.14667 8.456 9.36C8.456 9.568 8.38667 9.744 8.248 9.888C8.11467 10.0267 7.94667 10.096 7.744 10.096Z"
                                                                fill="#4D5464" />
                                                        </svg>
                                                    </Form.Label>
                                                    <Form.Check type="checkbox" id="itemName01" className="form-check-label-inlineFloat" label="Discount will apply to all items excepte the following items/groups" />
                                                </Form.Group>

                                                <Form.Group className="form-bg-search">
                                                    <Form.Control type="text" onChange="" placeholder="Search Product"/>
                                                </Form.Group>
                                                {/* product tags */}
                                                <div className="productTagsContainer mt-3">
                                                    <div className="productTag">
                                                        <span>
                                                            Cheesecake
                                                        </span>
                                                        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <circle cx="8.5" cy="8.5" r="8.5" fill="#0178E5"/>
                                                            <path d="M5.196 12L7.38 8.416L5.336 5.126H7.072L7.842 6.428C7.94467 6.61467 8.04733 6.81067 8.15 7.016C8.262 7.212 8.36933 7.40333 8.472 7.59H8.528C8.612 7.40333 8.70067 7.212 8.794 7.016C8.88733 6.81067 8.976 6.61467 9.06 6.428L9.718 5.126H11.384L9.354 8.598L11.538 12H9.802L8.962 10.642C8.85 10.4367 8.73333 10.2313 8.612 10.026C8.5 9.82067 8.38333 9.62 8.262 9.424H8.206C8.10333 9.62 8.00067 9.82067 7.898 10.026C7.80467 10.222 7.70667 10.4273 7.604 10.642L6.876 12H5.196Z" fill="white"/>
                                                        </svg>
                                                    </div>
                                                    <div className="productTag">
                                                        <span>
                                                            Tea
                                                        </span>
                                                        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <circle cx="8.5" cy="8.5" r="8.5" fill="#0178E5"/>
                                                            <path d="M5.196 12L7.38 8.416L5.336 5.126H7.072L7.842 6.428C7.94467 6.61467 8.04733 6.81067 8.15 7.016C8.262 7.212 8.36933 7.40333 8.472 7.59H8.528C8.612 7.40333 8.70067 7.212 8.794 7.016C8.88733 6.81067 8.976 6.61467 9.06 6.428L9.718 5.126H11.384L9.354 8.598L11.538 12H9.802L8.962 10.642C8.85 10.4367 8.73333 10.2313 8.612 10.026C8.5 9.82067 8.38333 9.62 8.262 9.424H8.206C8.10333 9.62 8.00067 9.82067 7.898 10.026C7.80467 10.222 7.70667 10.4273 7.604 10.642L6.876 12H5.196Z" fill="white"/>
                                                        </svg>
                                                    </div>
                                                    <div className="productTag">
                                                        <span>
                                                            Coffee
                                                        </span>
                                                        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <circle cx="8.5" cy="8.5" r="8.5" fill="#0178E5"/>
                                                            <path d="M5.196 12L7.38 8.416L5.336 5.126H7.072L7.842 6.428C7.94467 6.61467 8.04733 6.81067 8.15 7.016C8.262 7.212 8.36933 7.40333 8.472 7.59H8.528C8.612 7.40333 8.70067 7.212 8.794 7.016C8.88733 6.81067 8.976 6.61467 9.06 6.428L9.718 5.126H11.384L9.354 8.598L11.538 12H9.802L8.962 10.642C8.85 10.4367 8.73333 10.2313 8.612 10.026C8.5 9.82067 8.38333 9.62 8.262 9.424H8.206C8.10333 9.62 8.00067 9.82067 7.898 10.026C7.80467 10.222 7.70667 10.4273 7.604 10.642L6.876 12H5.196Z" fill="white"/>
                                                        </svg>
                                                    </div>
                                                </div>
                                                {/* product tags */}
                                                <Form.Label className="subFormLabel mt-3">Min/Max Bill Amount</Form.Label>
                                                <Form.Group className="div_from formInline3">
                                                    <Form.Label className="">Minimum</Form.Label>
                                                    <Form.Control type="number" id="" name="" placeholder="10,000" min="1" />
                                                    <Form.Label className="">Maximum</Form.Label>
                                                    <Form.Control type="number" id="" name="" placeholder="25,000" min="1"/>
                                                </Form.Group>
                                            </Col>
                                            <Col md={4} className="border-left pl-4">
                                                <Form.Group>
                                                    <Form.Label>Discount Implementation
                                                        <svg width="15" height="15"
                                                            viewBox="0 0 15 15" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <rect width="15" height="15" rx="7.5"
                                                                fill="#DEDEDE" />
                                                            <path
                                                                d="M7.232 8.152C7.20533 7.97067 7.21067 7.80533 7.248 7.656C7.29067 7.50667 7.34933 7.368 7.424 7.24C7.49867 7.112 7.58133 6.99467 7.672 6.888C7.768 6.78133 7.85867 6.68 7.944 6.584C8.02933 6.48267 8.09867 6.38667 8.152 6.296C8.21067 6.2 8.24 6.10133 8.24 6C8.24 5.83467 8.18933 5.70933 8.088 5.624C7.992 5.53867 7.864 5.496 7.704 5.496C7.55467 5.496 7.42133 5.53067 7.304 5.6C7.192 5.66933 7.08267 5.75733 6.976 5.864L6.328 5.272C6.51467 5.05333 6.736 4.87733 6.992 4.744C7.25333 4.61067 7.54133 4.544 7.856 4.544C8.06933 4.544 8.26667 4.57067 8.448 4.624C8.63467 4.67733 8.79467 4.76 8.928 4.872C9.06667 4.984 9.17333 5.128 9.248 5.304C9.328 5.47467 9.368 5.67733 9.368 5.912C9.368 6.07733 9.33867 6.22667 9.28 6.36C9.22133 6.488 9.14667 6.608 9.056 6.72C8.97067 6.832 8.87733 6.94133 8.776 7.048C8.68 7.14933 8.58933 7.256 8.504 7.368C8.424 7.48 8.36 7.6 8.312 7.728C8.264 7.85067 8.248 7.992 8.264 8.152H7.232ZM7.744 10.096C7.54133 10.096 7.37333 10.0267 7.24 9.888C7.10667 9.744 7.04 9.568 7.04 9.36C7.04 9.14667 7.10667 8.97067 7.24 8.832C7.37333 8.688 7.54133 8.616 7.744 8.616C7.94667 8.616 8.11467 8.688 8.248 8.832C8.38667 8.97067 8.456 9.14667 8.456 9.36C8.456 9.568 8.38667 9.744 8.248 9.888C8.11467 10.0267 7.94667 10.096 7.744 10.096Z"
                                                                fill="#4D5464" />
                                                        </svg>
                                                    </Form.Label>
                                                    <Form.Label className="subFormLabel">Available at all items of the day?</Form.Label>
                                                    <Switch id="shiftStatus" checked={this.state.checked} onChange={this.handleChange} onColor="#F7F7F7" offColor="#F7F7F7" onHandleColor="#6EC9A8" offHandleColor="#F5697A" handleDiameter={22} uncheckedIcon={true} checkedIcon={true} height={24} width={44} />
                                                </Form.Group>

                                                <div className="formInline">
                                                    <Form.Label>Times Schedule</Form.Label>
                                                    
                                                    <Form.Group>
                                                        <Form.Control placeholder="3:00" />
                                                    </Form.Group>
                                                    <Form.Group className="div_from div_select">
                                                        <Form.Select className="" name="" id="">
                                                            <option value="PM">PM</option>
                                                            <option value="AM">AM</option>
                                                        </Form.Select>
                                                    </Form.Group>

                                                    <Form.Label>To</Form.Label>
                                                    
                                                    <Form.Group>
                                                        <Form.Control placeholder="3:00" />
                                                    </Form.Group>
                                                    <Form.Group className="div_from div_select">
                                                        <Form.Select className="" name="" id="">
                                                            <option value="PM">PM</option>
                                                            <option value="AM">AM</option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                    
                                                </div>
                                                <hr/>

                                                <Form.Group>
                                                    <Form.Label className="subFormLabel">Available Every Day?</Form.Label>
                                                    <Switch id="shiftStatus" checked={this.state.checked} onChange={this.handleChange} onColor="#F7F7F7" offColor="#F7F7F7" onHandleColor="#6EC9A8" offHandleColor="#F5697A" handleDiameter={22} uncheckedIcon={true} checkedIcon={true} height={24} width={44} />
                                                </Form.Group>
                                                <Form.Group className="mt-3">
                                                    <Row>
                                                        <Col>
                                                            <Form.Check type="checkbox" id="availableDaySun" label="Sunday" />
                                                            <Form.Check type="checkbox" id="availableDayMon" label="Monday" />
                                                            <Form.Check type="checkbox" id="availableDayTue" label="Tuesday" />
                                                            <Form.Check type="checkbox" id="availableDayWed" label="Wednesday" />
                                                        </Col>
                                                        <Col>
                                                            <Form.Check type="checkbox" id="availableDayThu" label="Thursday" />
                                                            <Form.Check type="checkbox" id="availableDayFri" label="Friday" />
                                                            <Form.Check type="checkbox" id="availableDaySat" label="Saturday" />
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Label className="subFormLabel">Date Range</Form.Label>
                                                    <Switch id="shiftStatus" checked={this.state.checked} onChange={this.handleChange} onColor="#F7F7F7" offColor="#F7F7F7" onHandleColor="#6EC9A8" offHandleColor="#F5697A" handleDiameter={22} uncheckedIcon={true} checkedIcon={true} height={24} width={44} />
                                                </Form.Group>
                                                <Form.Group className="formInline2">
                                                    <Form.Label>Start Date</Form.Label>
                                                    <Form.Control type="date" className="custom-width" id="startDateRange" name="" />
                                                    <Form.Label>End Date</Form.Label>
                                                    <Form.Control type="date" className="custom-width" id="endDateRange" name="" />
                                                </Form.Group>

                                            </Col>
                                            <Col md={12}>
                                                <Button type="submit" className="btn_submit">
                                                    Update
                                                </Button>
                                                <Button type="button" className="btn_submit">
                                                    Cancel
                                                </Button>
                                            </Col>
                                        </Row>
                                        </Form>
                                    </div>

                                </Col>

                            </Row>
                        </Container>
                    </div>
                </div>

            </div>
        );
    }
}

export default MenuDropdownDiscountFixedOffEdit;