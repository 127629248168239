import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import ProfileImg from '../../assets/images/profile.jpeg'


class CompleteProfile extends Component {
    render() {
        return (
            <div>

                <div className="DecideRole">
                    <div className="container">
                        <div className="row mt-4">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-3">
                                <svg width="59" height="33" viewBox="0 0 59 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M33.6149 30.2582C33.8905 30.4419 34.1727 30.619 34.4615 30.7895C36.8241 32.1735 39.528 33 42.4879 33C51.6037 33 58.9934 25.6142 58.9934 16.5033C59 7.38581 51.6102 0 42.4879 0C36.7454 0 32.2039 2.28921 27.9577 4.84079C24.9913 1.90877 20.9158 0.0918306 16.4071 0.0918306C7.35039 0.09839 0 7.43828 0 16.4967C0 25.5552 7.35039 32.9016 16.4137 32.9016C23.1209 32.9016 28.4303 27.477 33.6149 30.2582ZM18.4219 10.2457C16.2365 11.1574 13.9461 11.7478 11.439 11.7478C4.11491 11.7478 10.8615 0.675611 18.4219 10.2457ZM32.5583 11.3608C38.7602 7.58259 47.2263 4.01431 52.9556 7.30054C60.8179 11.8068 59.3741 23.2069 47.876 23.266C40.3287 23.2987 35.61 16.2737 32.5583 11.3608ZM22.6943 27.3393C19.7148 25.8962 20.4826 21.8623 23.6984 18.1956C25.1554 20.4126 26.3564 22.1837 28.3778 25.0698C28.719 25.5617 29.0865 26.0405 29.4737 26.5063C26.737 27.7066 24.276 28.1002 22.6943 27.3393Z" fill="url(#paint0_linear_724_5968)"/>
                                    <path d="M33.6149 30.2582C33.8905 30.4419 34.1727 30.619 34.4615 30.7895C36.8241 32.1735 39.528 33 42.4879 33C51.6037 33 58.9934 25.6142 58.9934 16.5033C59 7.38581 51.6102 0 42.4879 0C36.7454 0 32.2039 2.28921 27.9577 4.84079C24.9913 1.90877 20.9158 0.0918306 16.4071 0.0918306C7.35039 0.09839 0 7.43828 0 16.4967C0 25.5552 7.35039 32.9016 16.4137 32.9016C23.1209 32.9016 28.4303 27.477 33.6149 30.2582ZM18.4219 10.2457C16.2365 11.1574 13.9461 11.7478 11.439 11.7478C4.11491 11.7478 10.8615 0.675611 18.4219 10.2457ZM32.5583 11.3608C38.7602 7.58259 47.2263 4.01431 52.9556 7.30054C60.8179 11.8068 59.3741 23.2069 47.876 23.266C40.3287 23.2987 35.61 16.2737 32.5583 11.3608ZM22.6943 27.3393C19.7148 25.8962 20.4826 21.8623 23.6984 18.1956C25.1554 20.4126 26.3564 22.1837 28.3778 25.0698C28.719 25.5617 29.0865 26.0405 29.4737 26.5063C26.737 27.7066 24.276 28.1002 22.6943 27.3393Z" fill="url(#paint1_linear_724_5968)"/>
                                    <path opacity="0.5" d="M28.5485 4.84079C32.7028 2.33512 37.1524 0.0918306 42.7309 0C42.6521 0 42.5734 0 42.488 0C36.9096 0 32.46 2.15802 28.3188 4.63089C28.3976 4.69648 28.4698 4.76863 28.5485 4.84079Z" fill="url(#paint2_linear_724_5968)"/>
                                    <path d="M48.1321 23.2592C48.2371 23.2592 48.3487 23.2658 48.4603 23.2592C59.9584 23.2067 61.4022 11.8066 53.5399 7.30036C51.8729 6.34269 49.9763 5.96881 47.9746 6.01473C49.7663 6.05408 51.4529 6.44108 52.9558 7.30036C60.759 11.7673 59.394 23.0362 48.1321 23.2592Z" fill="url(#paint3_linear_724_5968)"/>
                                    <path d="M12.0232 11.7478C14.5302 11.7478 16.814 11.1575 19.006 10.2458C16.5975 7.19567 14.2742 6.24457 12.4563 6.36264C14.1758 6.46759 16.2628 7.51708 18.4219 10.2458C16.3349 11.1182 14.1626 11.6954 11.7935 11.7478C11.8656 11.7478 11.9444 11.7478 12.0232 11.7478Z" fill="url(#paint4_linear_724_5968)"/>
                                    <path d="M30.0511 26.4997C29.6639 26.0405 29.3029 25.5551 28.9551 25.0632C26.9403 22.1771 25.503 20.0191 24.0461 17.802C23.9345 17.9332 23.8032 18.0578 23.6982 18.189C25.0436 20.229 26.4743 22.3476 28.3776 25.0632C28.7188 25.5551 29.0863 26.034 29.4736 26.4997C27.8132 27.2343 26.2578 27.6607 24.9386 27.7197C26.3693 27.7853 28.1479 27.3458 30.0511 26.4997Z" fill="url(#paint5_linear_724_5968)"/>
                                    <path opacity="0.5" d="M42.7569 32.9934C39.9218 32.9344 37.3229 32.121 35.0456 30.7894C34.7568 30.6189 34.4746 30.4418 34.199 30.2581C33.0439 29.6416 31.8888 29.4251 30.7075 29.471C31.6854 29.5104 32.6501 29.74 33.6149 30.2581C33.8905 30.4418 34.1727 30.6189 34.4615 30.7894C36.8241 32.1735 39.528 32.9999 42.4878 32.9999C42.5797 32.9999 42.6716 32.9999 42.7569 32.9934Z" fill="url(#paint6_linear_724_5968)"/>
                                    <path opacity="0.4" d="M0.584093 16.4967C0.584093 7.53664 7.77697 0.249221 16.709 0.0983562C16.6106 0.0983562 16.5121 0.0917969 16.4137 0.0917969C7.35039 0.0983562 0 7.43825 0 16.4967C0 25.5551 7.35039 32.9016 16.4137 32.9016C16.5121 32.9016 16.6106 32.9016 16.709 32.895C7.77697 32.7442 0.584093 25.4633 0.584093 16.4967Z" fill="url(#paint7_linear_724_5968)"/>
                                    <path d="M52.9556 7.30067C48.9719 5.01803 43.6757 6.04784 38.7339 8.15339C47.5281 5.25416 45.4017 14.6406 39.0489 19.6388C41.4575 21.764 44.3582 23.2858 47.8825 23.2661C59.374 23.2071 60.8179 11.8069 52.9556 7.30067Z" fill="url(#paint8_linear_724_5968)"/>
                                    <path opacity="0.5" d="M5.31585 9.14371C6.74655 7.52355 8.95823 6.36255 11.8524 6.36255H11.8459C13.6638 6.23136 16.0002 7.18247 18.4219 10.2457C21.7098 8.86822 24.7156 6.79547 27.9642 4.84735C24.9978 1.91532 20.9223 0.0983887 16.4136 0.0983887C10.8221 0.0983887 5.88682 2.88611 2.92041 7.14967L5.31585 9.14371Z" fill="url(#paint9_linear_724_5968)"/>
                                    <path opacity="0.4" d="M47.876 23.2592C40.3353 23.292 35.6166 16.2735 32.5584 11.3606C29.5066 13.2169 26.9996 15.1257 25.6346 16.2932C24.9192 16.9032 24.2695 17.5395 23.7051 18.1954C25.162 20.4125 26.363 22.1835 28.3844 25.0696C31.5739 29.6349 36.5158 33.0064 42.4945 33.0064L47.876 23.2592Z" fill="url(#paint10_linear_724_5968)"/>
                                    <path d="M20.3384 32.344C25.2736 31.0322 29.487 28.0411 33.615 30.2582C32.5256 29.6219 31.0817 28.3035 29.4738 26.4997C26.7371 27.7066 24.2761 28.1002 22.701 27.3393C19.7215 25.8962 20.4893 21.8622 23.7051 18.1956C17.8051 23.7316 11.1438 34.4955 20.3384 32.344Z" fill="url(#paint11_linear_724_5968)"/>
                                    <path d="M22.8254 19.0419C23.0419 18.7533 23.2782 18.4713 23.5276 18.1892C18.1329 23.2596 12.0951 32.7116 18.1657 32.6263C12.6989 32.5279 17.7391 24.1976 22.8254 19.0419Z" fill="url(#paint12_linear_724_5968)"/>
                                    <path d="M11.8527 6.36255C2.6253 7.60226 0.0854766 19.2254 9.52285 19.2254C18.9602 19.2254 27.0457 0 42.488 0C29.4739 0 22.6289 11.7478 11.4392 11.7478C6.458 11.7478 7.98715 6.62492 11.8527 6.36255Z" fill="url(#paint13_linear_724_5968)"/>
                                    <path d="M9.63422 19.1272C4.75803 19.1272 3.07794 16.0247 3.86548 12.8565C2.84167 16.1558 4.45614 19.5142 9.52922 19.5142C18.9732 19.5142 27.052 0.288818 42.4944 0.288818C39.1802 0.288818 36.2728 1.0497 33.5821 2.18447C23.6196 7.28107 17.0437 19.1272 9.63422 19.1272Z" fill="url(#paint14_linear_724_5968)"/>
                                    <path d="M50.2518 28.1986C50.3962 29.9959 47.5938 31.6882 43.9908 31.9768C40.3878 32.2654 37.3558 31.0388 37.2114 29.2416C37.067 27.4443 39.8693 25.752 43.4723 25.4634C47.0753 25.1748 50.1074 26.3948 50.2518 28.1986Z" fill="url(#paint15_linear_724_5968)"/>
                                    <path d="M44.2531 0.865859C47.9152 1.36437 51.0982 2.49913 53.1261 3.87659C52.3254 3.16163 46.1891 -1.92186 37.6509 0.793707C39.5869 0.537893 41.8577 0.544452 44.2531 0.865859Z" fill="url(#paint16_linear_724_5968)"/>
                                    <path d="M43.7282 3.09619C40.8143 3.64718 38.0644 4.71635 35.4459 5.99542C32.8339 7.29416 30.3728 8.87496 28.0627 10.646C26.9339 11.5708 25.7788 12.4629 24.7419 13.4927C24.2168 13.9978 23.6393 14.457 23.1537 15.0014C22.6549 15.5327 22.1495 16.064 21.6376 16.5887C21.1061 17.1004 20.6532 17.671 20.1741 18.2286L18.7303 19.9012C17.8049 21.0491 16.9386 22.2363 15.9805 23.3842C16.6958 22.0855 17.4309 20.7933 18.225 19.5339L19.5244 17.7104C19.9576 17.1004 20.3907 16.4969 20.8895 15.9394C21.3686 15.3687 21.8542 14.798 22.3464 14.2339C22.8255 13.6567 23.3899 13.1648 23.9149 12.6269C24.9584 11.5446 26.1398 10.6132 27.2817 9.64896C29.6771 7.83859 32.2563 6.25123 35.0324 5.10335C37.8019 3.95546 40.7486 3.12243 43.7282 3.09619Z" fill="url(#paint17_linear_724_5968)"/>
                                    <defs>
                                    <linearGradient id="paint0_linear_724_5968" x1="0.0139693" y1="16.5089" x2="58.9948" y2="16.5089" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#E9E9E9" stop-opacity="0.3"/>
                                    <stop offset="0.4972" stop-color="#DCDCDC"/>
                                    <stop offset="1" stop-color="#E2E2E2" stop-opacity="0.3"/>
                                    </linearGradient>
                                    <linearGradient id="paint1_linear_724_5968" x1="0.0139693" y1="16.5089" x2="58.9948" y2="16.5089" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#E9E9E9" stop-opacity="0.3"/>
                                    <stop offset="0.4972" stop-color="#DCDCDC"/>
                                    <stop offset="1" stop-color="#E2E2E2" stop-opacity="0.3"/>
                                    </linearGradient>
                                    <linearGradient id="paint2_linear_724_5968" x1="28.3223" y1="2.42171" x2="42.7312" y2="2.42171" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#E9E9E9" stop-opacity="0.3"/>
                                    <stop offset="0.4972" stop-color="#DCDCDC"/>
                                    <stop offset="1" stop-color="#E2E2E2" stop-opacity="0.3"/>
                                    </linearGradient>
                                    <linearGradient id="paint3_linear_724_5968" x1="47.9771" y1="14.6412" x2="58.467" y2="14.6412" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#E9E9E9" stop-opacity="0.3"/>
                                    <stop offset="0.4972" stop-color="#DCDCDC"/>
                                    <stop offset="1" stop-color="#E2E2E2" stop-opacity="0.3"/>
                                    </linearGradient>
                                    <linearGradient id="paint4_linear_724_5968" x1="11.7952" y1="9.0519" x2="19.0062" y2="9.0519" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#E9E9E9" stop-opacity="0.3"/>
                                    <stop offset="0.4972" stop-color="#DCDCDC"/>
                                    <stop offset="1" stop-color="#E2E2E2" stop-opacity="0.3"/>
                                    </linearGradient>
                                    <linearGradient id="paint5_linear_724_5968" x1="23.6997" y1="22.7668" x2="30.0512" y2="22.7668" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#E9E9E9" stop-opacity="0.3"/>
                                    <stop offset="0.4972" stop-color="#DCDCDC"/>
                                    <stop offset="1" stop-color="#E2E2E2" stop-opacity="0.3"/>
                                    </linearGradient>
                                    <linearGradient id="paint6_linear_724_5968" x1="30.7104" y1="31.2335" x2="42.7572" y2="31.2335" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#E9E9E9" stop-opacity="0.3"/>
                                    <stop offset="0.4972" stop-color="#DCDCDC"/>
                                    <stop offset="1" stop-color="#E2E2E2" stop-opacity="0.3"/>
                                    </linearGradient>
                                    <linearGradient id="paint7_linear_724_5968" x1="0.0039566" y1="16.5056" x2="16.7094" y2="16.5056" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#E9E9E9" stop-opacity="0.3"/>
                                    <stop offset="0.4972" stop-color="#DCDCDC"/>
                                    <stop offset="1" stop-color="#E2E2E2" stop-opacity="0.3"/>
                                    </linearGradient>
                                    <linearGradient id="paint8_linear_724_5968" x1="38.7384" y1="14.642" x2="57.8833" y2="14.642" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#E9E9E9" stop-opacity="0.3"/>
                                    <stop offset="0.4972" stop-color="#DCDCDC"/>
                                    <stop offset="1" stop-color="#E2E2E2" stop-opacity="0.3"/>
                                    </linearGradient>
                                    <linearGradient id="paint9_linear_724_5968" x1="2.92634" y1="5.17478" x2="27.9648" y2="5.17478" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#E9E9E9" stop-opacity="0.3"/>
                                    <stop offset="0.4972" stop-color="#DCDCDC"/>
                                    <stop offset="1" stop-color="#E2E2E2" stop-opacity="0.3"/>
                                    </linearGradient>
                                    <linearGradient id="paint10_linear_724_5968" x1="23.7108" y1="22.1894" x2="47.8766" y2="22.1894" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#E9E9E9" stop-opacity="0.3"/>
                                    <stop offset="0.4972" stop-color="#DCDCDC"/>
                                    <stop offset="1" stop-color="#E2E2E2" stop-opacity="0.3"/>
                                    </linearGradient>
                                    <linearGradient id="paint11_linear_724_5968" x1="15.8016" y1="25.4139" x2="33.6154" y2="25.4139" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#E9E9E9" stop-opacity="0.3"/>
                                    <stop offset="0.4972" stop-color="#DCDCDC"/>
                                    <stop offset="1" stop-color="#E2E2E2" stop-opacity="0.3"/>
                                    </linearGradient>
                                    <linearGradient id="paint12_linear_724_5968" x1="15.6288" y1="25.412" x2="23.5278" y2="25.412" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#E9E9E9" stop-opacity="0.3"/>
                                    <stop offset="0.4972" stop-color="#DCDCDC"/>
                                    <stop offset="1" stop-color="#E2E2E2" stop-opacity="0.3"/>
                                    </linearGradient>
                                    <linearGradient id="paint13_linear_724_5968" x1="3.58002" y1="9.61791" x2="42.4889" y2="9.61791" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#E9E9E9" stop-opacity="0.3"/>
                                    <stop offset="0.4972" stop-color="#DCDCDC"/>
                                    <stop offset="1" stop-color="#E2E2E2" stop-opacity="0.3"/>
                                    </linearGradient>
                                    <linearGradient id="paint14_linear_724_5968" x1="3.58246" y1="9.90673" x2="42.4953" y2="9.90673" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#E9E9E9" stop-opacity="0.3"/>
                                    <stop offset="0.4972" stop-color="#DCDCDC"/>
                                    <stop offset="1" stop-color="#E2E2E2" stop-opacity="0.3"/>
                                    </linearGradient>
                                    <linearGradient id="paint15_linear_724_5968" x1="37.2091" y1="28.7218" x2="50.2574" y2="28.7218" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#E9E9E9" stop-opacity="0.3"/>
                                    <stop offset="0.4972" stop-color="#DCDCDC"/>
                                    <stop offset="1" stop-color="#E2E2E2" stop-opacity="0.3"/>
                                    </linearGradient>
                                    <linearGradient id="paint16_linear_724_5968" x1="37.6545" y1="1.94032" x2="53.1264" y2="1.94032" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#E9E9E9" stop-opacity="0.3"/>
                                    <stop offset="0.4972" stop-color="#DCDCDC"/>
                                    <stop offset="1" stop-color="#E2E2E2" stop-opacity="0.3"/>
                                    </linearGradient>
                                    <linearGradient id="paint17_linear_724_5968" x1="15.987" y1="13.2457" x2="43.7288" y2="13.2457" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#E9E9E9" stop-opacity="0.3"/>
                                    <stop offset="0.4972" stop-color="#DCDCDC"/>
                                    <stop offset="1" stop-color="#E2E2E2" stop-opacity="0.3"/>
                                    </linearGradient>
                                    </defs>
                                </svg>
                                <div className="greetings mt-3">
                                    <h4>Welcome To <b>Serve</b>Easy</h4>
                                </div>
                                <form className="formRoleSelect mt-4">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="radio-control">
                                                <input type="radio" className="btn-check" name="roleRadio" id="option1" autocomplete="off" value="Super Admin" checked/>
                                                <label for="option1"><svg width="25" height="27" viewBox="0 0 25 27" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M24.0192 21.5514C23.3921 20.069 22.482 18.7225 21.3397 17.5868C20.2009 16.4479 18.8518 15.5399 17.367 14.9128C17.3537 14.9062 17.3404 14.9029 17.3271 14.8962C19.3982 13.4033 20.7446 10.9714 20.7446 8.22776C20.7446 3.68259 17.0545 0 12.5 0C7.94551 0 4.25537 3.68259 4.25537 8.22776C4.25537 10.9714 5.60177 13.4033 7.6729 14.8995C7.65961 14.9062 7.64631 14.9095 7.63301 14.9161C6.14366 15.5432 4.80723 16.4422 3.6603 17.5902C2.51905 18.7267 1.60913 20.073 0.980795 21.5547C0.363519 23.0054 0.030608 24.5607 8.31311e-05 26.1364C-0.00080418 26.1718 0.00541927 26.207 0.018387 26.24C0.0313547 26.273 0.0508041 26.303 0.0755893 26.3284C0.100374 26.3537 0.129994 26.3739 0.162702 26.3876C0.195411 26.4014 0.230546 26.4085 0.266039 26.4084H2.26071C2.40698 26.4084 2.52334 26.2923 2.52666 26.1497C2.59315 23.5885 3.62373 21.1898 5.44553 19.3717C7.33049 17.4906 9.83379 16.4555 12.5 16.4555C15.1662 16.4555 17.6695 17.4906 19.5545 19.3717C21.3763 21.1898 22.4068 23.5885 22.4733 26.1497C22.4767 26.2957 22.593 26.4084 22.7393 26.4084H24.734C24.7695 26.4085 24.8046 26.4014 24.8373 26.3876C24.87 26.3739 24.8996 26.3537 24.9244 26.3284C24.9492 26.303 24.9686 26.273 24.9816 26.24C24.9946 26.207 25.0008 26.1718 24.9999 26.1364C24.9667 24.5506 24.6376 23.0079 24.0192 21.5514ZM12.5 13.9341C10.9741 13.9341 9.53792 13.3402 8.45747 12.262C7.37703 11.1838 6.78195 9.75056 6.78195 8.22776C6.78195 6.70496 7.37703 5.27174 8.45747 4.1935C9.53792 3.11527 10.9741 2.52141 12.5 2.52141C14.0259 2.52141 15.4621 3.11527 16.5425 4.1935C17.623 5.27174 18.218 6.70496 18.218 8.22776C18.218 9.75056 17.623 11.1838 16.5425 12.262C15.4621 13.3402 14.0259 13.9341 12.5 13.9341Z" fill="white"/></svg> Super Admin</label>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="col-12 col-sm-12 col-md-8 col-lg-8">
                                <div className="row align-items-center">
                                    <div className="col-12 col-sm-12 col-md-8 col-lg-8">
                                        <div className="img-profile">
                                            <img className="img-fluid" src={ProfileImg} alt="profile pic" title="profile pic" />
                                            <div className="profileDetails">
                                                <h5>@johndoe</h5>
                                                <p>senior manager</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                                        <Link className="editProfileLink" to="#">Edit Profile</Link>
                                    </div>
                                    
                                </div>
                                <div className="row mt-3">
                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                        <div className="mb-3 custom-formControls">
                                            <label for="exampleFormControlInput1" className="form-label">User Name</label>
                                            <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="John Doe" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                        <div className="mb-3 custom-formControls">
                                            <label for="exampleFormControlInput1" className="form-label">Job Title</label>
                                            <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Senior Manager" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                        <div className="mb-3 custom-formControls">
                                            <label for="exampleFormControlInput1" className="form-label">Email Address</label>
                                            <input type="email" className="form-control" id="exampleFormControlInput1" placeholder="John Doe" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                        <div className="mb-3 custom-formControls">
                                            <label for="exampleFormControlInput1" className="form-label">Mobile Number</label>
                                            <input type="tel" className="form-control" id="exampleFormControlInput1" placeholder="+92 300 9999992" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <div className="mb-3 custom-formControls">
                                            <label for="exampleFormControlTextarea1" class="form-label">Bio</label>
                                            <textarea class="form-control" id="exampleFormControlTextarea1" rows="5"></textarea>
                                        </div>
                                        <input type="submit" className="btn btn-submit" value="Update &amp; Finish"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="nextOptions">
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <p>Already have an account? <Link to="/">Login</Link></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ul dir="rtl" className="text-right list-absolute-left">
                        <li className="markDone">
                            <Link to="/create-account">Create Account</Link>
                        </li>
                        <li className="markDone">
                            <Link to="#">Decide Role</Link>
                        </li>
                        <li className="markDone">
                            <Link to="#">Customize Access</Link>
                        </li>
                        <li className="markCurrent">
                            <Link to="#">Complete</Link>
                        </li>
                    </ul>
                </div>

            </div>
        );
    }
}

export default CompleteProfile;