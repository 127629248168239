import React, {Component} from 'react';
import { format } from 'date-fns'
import SidebarMenu from "../layouts/SidebarMenu";
import {Button, Col, Container, Row, Form} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import notification from "../../assets/icons/Notification.svg";
import profile from "../../assets/icons/photo.png";
import DataTable from 'react-data-table-component';


const customStyles = {
    headCells: {
        style: {
            backgroundColor : "#646C7C",
            color: "#fff",
            fontSize: "12px",

        },
    },
};
const columns = [
    {
        name: 'Slip No.',
        selector: row => row.slipNo,
        sortable: true,
    },
    {
        name: 'Date & Time',
        selector: row => row.dateTime,
    },
    {
        name: 'Card Number',
        selector: row => row.cardNo,
        sortable: true,
    },
    {
        name: 'Order Type',
        selector: row => row.orderType,
    },
    {
        name: 'Amount',
        selector: row => row.amount,
    },
];

const data = [
    {
        slipNo: "1651361",
        dateTime: '12-10-2021   12:00',
        cardNo: "1561655165165146",
        orderType: 'DineIn',
        amount: '1561',
    },
    {
        slipNo: "1651361",
        dateTime: '12-10-2021   12:00',
        cardNo: "1561655165165146",
        orderType: 'Takeaway',
        amount: '2561',
    },
    {
        slipNo: "1651361",
        dateTime: '12-10-2021   12:00',
        cardNo: "1561655165165146",
        orderType: 'Delivery',
        amount: '3000',
    },
    {
        slipNo: "1651361",
        dateTime: '12-10-2021   12:00',
        cardNo: "1561655165165146",
        orderType: 'DineIn',
        amount: '1561',
    },
    {
        slipNo: "1651361",
        dateTime: '12-10-2021   12:00',
        cardNo: "1561655165165146",
        orderType: 'DineIn',
        amount: '1561',
    },
    {
        slipNo: "1651361",
        dateTime: '12-10-2021   12:00',
        cardNo: "1561655165165146",
        orderType: 'DineIn',
        amount: '1561',
    },
    {
        slipNo: "1651361",
        dateTime: '12-10-2021   12:00',
        cardNo: "1561655165165146",
        orderType: 'DineIn',
        amount: '1561',
    },
    {
        slipNo: "1651361",
        dateTime: '12-10-2021   12:00',
        cardNo: "1561655165165146",
        orderType: 'DineIn',
        amount: '1561',
    },
    
];



class CreditCardSummaryReport extends Component {
    constructor() {
        super();
        this.state = {checked: false};
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(checked) {
        this.setState({checked});
    }
    



    render() {
        return (
            <div className="paymentsPage">
                <SidebarMenu/>
                <div className="main_page_sectoin">
                    <div className="top_header">
                        <Container>
                            <Row>
                                <Col md={7}>
                                    <div className="left_side">
                                        <div className="drop_down">
                                            <h2>Reports -  Credit Card Summary Report</h2>
                                        </div>
                                        <div className="data_title">{format(new Date(), 'MM-dd-yyyy')} | {format(new Date(), "eeee")}</div>
                                    </div>
                                </Col>
                                <Col md={5}>
                                    <div className="right_side">
                                        <div className="notification-icon">
                                            <Link to="">
                                                <img src={notification} alt="Notification_icon"/>
                                            </Link>
                                        </div>
                                        <div className="profile-icon">
                                            <div className="profile_img">
                                                <img src={profile} alt="profile_icon"/>
                                            </div>
                                            <div className="name_profile">
                                                John Doe
                                                <span>Sales Manager</span>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <div className="main_panel">
                        <Container>
                            <Row>
                                <Col md={12}>
                                    <div className="title_content border-bottom_1">
                                        <div className="div_fillter">
                                            <Form>
                                                <Form.Group className="sales_report_icon select_staus">
                                                    <select name="" id="">
                                                        <option>Daily Sales Report</option>
                                                        <option value="">Report 01</option>
                                                        <option value="">Report 02</option>
                                                        <option value="">Report 03</option>
                                                    </select>
                                                </Form.Group>
                                                <Form.Group className="">
                                                    <Form.Control type="date"/>
                                                </Form.Group>
                                                <Form.Group className="date_range_icon select_staus">
                                                    <select name="" id="">
                                                        <option value="">Status</option>
                                                        <option value="">Status</option>
                                                        <option value="">Status</option>
                                                        <option value="">Status</option>
                                                    </select>
                                                </Form.Group>
                                                
                                            </Form>
                                        </div>
                                    </div>
                                    <div className="menu_listing">
                                        <div className="reportsView">
                                            <h3 className="mb-0"> Credit Card Summary Report</h3>
                                            <div className="bg-white">
                                                <DataTable columns={columns} data={data} customStyles={customStyles} pagination responsive highlightOnHover/>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>

            </div>
        );
    }
}

export default CreditCardSummaryReport;