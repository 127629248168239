import React from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Login from "./app/pages/Login";
import CreateAccount from "./app/pages/CreateAccount";
import Home from "./app/pages/Home";
import Order from "./app/pages/Order";
import OrderDetails from "./app/pages/OrderDetails";
import Menu from "./app/pages/Menu";
import ProductDetails from "./app/pages/ProductDetails";
import Settings from "./app/pages/Settings";
import Payments from "./app/pages/Payments"
import PaymentDetail from "./app/pages/PaymentDetail";
import Reports from "./app/pages/Reports";
import ReportsView from "./app/pages/ReportsView";
import DailySystemSalesReport from './app/pages/DailySystemSalesReport'
import GroupSalesSummaryReport from './app/pages/GroupSalesSummaryReport'
import HourlySalesSummaryReport from './app/pages/HourlySalesSummaryReport'
import ItemsSoldCountReport from './app/pages/ItemsSoldCountReport'
import MenuItemSalesSummaryReport from './app/pages/MenuItemSalesSummaryReport'
import OpenCommentsReport from './app/pages/OpenCommentsReport'
import VoidItemsSummaryReport from './app/pages/VoidItemsSummaryReport'
import CreditCardSummaryReport from './app/pages/CreditCardSummaryReport'
import CuisineSummaryReport from './app/pages/CuisineSummaryReport'
import Report86 from './app/pages/Report86'
import DecideRole from "./app/pages/DecideRole";
import CustomizeRoleAccess from './app/pages/CustomizeRoleAccess'
import CompleteProfile from './app/pages/CompleteProfile'
import CustomMenus from './app/pages/CustomMenus'
import CustomMenusEdit from './app/pages/CustomMenusEdit'
import CustomMenuDropdownModifiers from './app/pages/CustomMenuDropdownModifiers'
import CustomMenuDropdownModifiersEdit from './app/pages/CustomMenuDropdownModifiersEdit'
import MenuDropdownDiscount from './app/pages/MenuDropdownDiscount'
import MenuDropdownDiscountFixedOffEdit from './app/pages/MenuDropdownDiscountFixedOffEdit'
import MenuDropdownDiscountFixedAmountOff from './app/pages/MenuDropdownDiscountFixedAmountOff'
import MenuDropdownBankDiscountEdit from './app/pages/MenuDropdownBankDiscountEdit'
import MenuMainCategory from './app/pages/MenuMainCategory'
import MenuMainCategorySubcategory from './app/pages/MenuMainCategorySubcategory'


function App(props) {

    return (

        <Router>
            <Switch>
                <Route path="/product-details">
                    <ProductDetails/>
                </Route>
                {/* menus */}
                <Route path="/menu">
                    <Menu/>
                </Route>
                <Route path="/menumaincategory">
                    <MenuMainCategory/>
                </Route>
                <Route path="/menumaincategorysubcategory">
                    <MenuMainCategorySubcategory/>
                </Route>

                <Route path="/custommenu">
                    <CustomMenus />
                </Route>
                <Route path="/custommenusedit">
                    <CustomMenusEdit />
                </Route>
                <Route path="/custommenudropdownmodifiers">
                    <CustomMenuDropdownModifiers />
                </Route>
                <Route path="/custommenudropdownmodifiersedit">
                    <CustomMenuDropdownModifiersEdit />
                </Route>
                <Route path="/menudropdowndiscount">
                    <MenuDropdownDiscount />
                </Route>
                <Route path="/menudropdowndiscountfixedoffedit">
                    <MenuDropdownDiscountFixedOffEdit />
                </Route>
                <Route path="/menudropdowndiscountfixedamountoffedit">
                    <MenuDropdownDiscountFixedAmountOff />
                </Route>
                <Route path="/menudropdownbankdiscountedit">
                    <MenuDropdownBankDiscountEdit />
                </Route>
                
                {/* menus */}
                <Route path="/order-details">
                    <OrderDetails/>
                </Route>
                <Route path="/order">
                    <Order/>
                </Route>
                <Route path="/home">
                    <Home/>
                </Route>

                {/* stepwise account creation */}
                <Route path="/create-account">
                    <CreateAccount/>
                </Route>
                <Route path="/decide-role">
                    <DecideRole />
                </Route>
                <Route path="/customize-role-access">
                    <CustomizeRoleAccess />
                </Route>
                <Route path="/complete-profile">
                    <CompleteProfile />
                </Route>

                {/* stepwise account creation */}

                
                <Route path="/settings">
                    <Settings/>
                </Route>

                <Route path="/payments">
                    <Payments/>
                </Route>
                <Route path="/paymentdetails">
                    <PaymentDetail />
                </Route>
                
                
                <Route path="/reports">
                    <Reports />
                </Route>
                <Route path="/reportsview">
                    <ReportsView />
                </Route>
                <Route path="/dailysystemsalesreport">
                    <DailySystemSalesReport />
                </Route>
                <Route path="/groupsalessummaryreport">
                    <GroupSalesSummaryReport />
                </Route>
                <Route path="/hourlysalessummaryreport">
                    <HourlySalesSummaryReport />
                </Route>
                <Route path="/itemssoldcountreport">
                    <ItemsSoldCountReport />
                </Route>
                <Route path="/menuitemsalessummaryreport">
                    <MenuItemSalesSummaryReport />
                </Route>
                <Route path="/opencommentsreport">
                    <OpenCommentsReport />
                </Route>
                <Route path="/voiditemssummaryreport">
                    <VoidItemsSummaryReport />
                </Route>
                <Route path="/creditcardsummaryreport">
                    <CreditCardSummaryReport />
                </Route>
                <Route path="/report86">
                    <Report86 />
                </Route>
                <Route path="/cuisinesummaryreport">
                    <CuisineSummaryReport />
                </Route>



                <Route path="/">
                    <Login/>
                </Route>
            </Switch>
        </Router>
    );
}

export default App;
