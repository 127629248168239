import React, {Component} from 'react';
import logo from "../../logo.svg";
import logoicon from "../../logo_icon.svg";
import arrow_icon from "../../assets/icons/arrow_icon.svg";
import icon1 from "../../assets/icons/icon1.svg";
import icon1_blue from "../../assets/icons/icon1_blue.svg";
import icon2 from "../../assets/icons/icon2.svg";
import icon2_blue from "../../assets/icons/icon2_blue.svg";
import icon3 from "../../assets/icons/icon3.svg";
import icon3_blue from "../../assets/icons/icon3_blue.svg";
import icon4 from "../../assets/icons/icon4.svg";
import icon4_blue from "../../assets/icons/icon4_blue.svg";
import icon5 from "../../assets/icons/icon5.svg";
import icon5_blue from "../../assets/icons/icon5_blue.svg";
import logout from "../../assets/icons/logout.svg";
import logout_blue from "../../assets/icons/logout_blue.svg";
import setting_icon from "../../assets/icons/setting_icon.svg";
import setting_blue from "../../assets/icons/setting_blue.svg";

import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import {Link} from "react-router-dom";

class SidebarMenu extends Component {
    constructor() {
        super();
        this.SideMenuClick = this.SideMenuClick.bind(this);
    };

    async SideMenuClick() {
        document.body.classList.toggle('body_class');
    }

    render() {

        return (
            <div className="layout-fixed main-sidebar" id="layout-fixed">
                <div className="logo_sidebar">
                    <Link to="/home">
                        <img src={logo} className="logo_main" alt="Shopavize-logo"/>
                        <img src={logoicon} className="logo_icon" alt="Shopavize-logo"/>
                    </Link>
                    <img src={arrow_icon} className="arrow_icon" alt="arrow-logo" onClick={this.SideMenuClick}/>

                </div>
                <div className="menu_sidebar" onMouseEnter={this.SideMenuClick1} onMouseLeave={this.SideMenuClick1}>
                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <img src={icon1} alt="icon1" className="icon1"/>
                                    <img src={icon1_blue} alt="icon1_blue" className="icon1_blue"/>
                                    <span>Performance Center</span>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <Link to="order">
                                        <img src={icon2} alt="icon2" className="icon2"/>
                                        <img src={icon2_blue} alt="icon2_blue" className="icon2_blue"/>
                                        <span>Orders</span>
                                    </Link>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <Link to="menu">
                                        <img src={icon3} alt="icon3" className="icon3"/>
                                        <img src={icon3_blue} alt="icon3_blue" className="icon3_blue"/>
                                        <span>Menu</span>
                                    </Link>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <ul>
                                    <li>
                                        <Link to="">Custom Menus</Link>
                                    </li>
                                    <li>
                                        <Link to="">Linked Combos</Link>
                                    </li>
                                    <li>
                                        <Link to="">Price Life Cycle</Link>
                                    </li>
                                    <li>
                                        <Link to="">Product Groups</Link>
                                    </li>
                                    <li className="active">
                                        <Link to="">Modifiers</Link>
                                    </li>
                                    <li>
                                        <Link to="">Classes</Link>
                                    </li>
                                    <li>
                                        <Link to="">Discounts</Link>
                                    </li>
                                    <li>
                                        <Link to="">Service Fees</Link>
                                    </li>
                                    <li>
                                        <Link to="">Taxes</Link>
                                    </li>
                                    <li>
                                        <Link to="">Price Tiers</Link>
                                    </li>
                                </ul>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <Link to="payments">
                                        <img src={icon4} alt="icon4" className="icon4"/>
                                        <img src={icon4_blue} alt="icon4_blue" className="icon4_blue"/>
                                        <span>Payment</span>
                                    </Link>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <Link to="reports">
                                        <img src={icon5} alt="icon5" className="icon5"/>
                                        <img src={icon5_blue} alt="icon5_blue" className="icon5_blue"/>
                                        <span>Reports</span>
                                    </Link>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                        </AccordionItem>
                    </Accordion>
                </div>
                <div className="logout_div">
                    <ul>
                        <li>
                            <Link to="settings">
                                <img src={setting_icon} className="setting_icon" alt="setting-icon"/>
                                <img src={setting_blue} className="setting_blue_icon" alt="setting-icon"/>
                                <span>Settings</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/">
                                <img src={logout} className="logout_icon" alt="logout-icon"/>
                                <img src={logout_blue} className="logout_blue_icon" alt="logout_blue-icon"/>
                                <span>Logout</span>
                            </Link>
                        </li>
                    </ul>

                </div>
            </div>
        );
    }
}

export default SidebarMenu;