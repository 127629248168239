import React, {Component} from 'react';
import { format } from 'date-fns'
import SidebarMenu from "../layouts/SidebarMenu";
import {Button, Col, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import notification from "../../assets/icons/Notification.svg";
import profile from "../../assets/icons/photo.png";
import ProductSearch from './components/ProductSearch';
import DataTable from 'react-data-table-component';

const customStyles = {
    headCells: {
        style: {
            backgroundColor : "#646C7C",
            color: "#fff",
            fontSize: "12px",

        },
    },
};
const columns = [
    {
        name: 'Transaction ID',
        selector: row => row.transactionId,
        sortable: true,
    },
    {
        name: 'Customer Name',
        selector: row => row.customerName,
        sortable: true,
    },
    {
        name: 'Created At',
        selector: row => row.createdAtTime,
    },
    {
        name: 'Transaction Category',
        selector: row => row.transactionCategory,
    },
    {
        name: 'Amount',
        selector: row => row.amount,
        sortable: true,
    },
    {
        name: 'Payment By',
        selector: row => row.paymentBy,
    },
    {
        name: 'Payment Status',
        selector: row => row.paymentStatus,
        // cell: () => {}
    },
    {
        name: 'Action',
        cell: () => <button className="viewRecord" onClick={() => console.log("view transaction clicked")}><svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.9682 5.68549C15.3409 4.06286 14.2519 2.6596 12.8358 1.64916C11.4196 0.638714 9.73844 0.0653741 8 0C6.26156 0.0653741 4.58038 0.638714 3.16424 1.64916C1.7481 2.6596 0.659078 4.06286 0.031774 5.68549C-0.0105913 5.80268 -0.0105913 5.93099 0.031774 6.04817C0.659078 7.6708 1.7481 9.07406 3.16424 10.0845C4.58038 11.095 6.26156 11.6683 8 11.7337C9.73844 11.6683 11.4196 11.095 12.8358 10.0845C14.2519 9.07406 15.3409 7.6708 15.9682 6.04817C16.0106 5.93099 16.0106 5.80268 15.9682 5.68549V5.68549ZM8 10.667C5.17325 10.667 2.1865 8.57091 1.1038 5.86683C2.1865 3.16276 5.17325 1.0667 8 1.0667C10.8267 1.0667 13.8135 3.16276 14.8962 5.86683C13.8135 8.57091 10.8267 10.667 8 10.667Z" fill="#D2D2D2"/><path d="M8.00014 2.66699C7.36722 2.66699 6.74852 2.85467 6.22227 3.20631C5.69601 3.55794 5.28585 4.05772 5.04364 4.64246C4.80144 5.2272 4.73806 5.87063 4.86154 6.49139C4.98502 7.11215 5.28979 7.68235 5.73733 8.12989C6.18488 8.57743 6.75508 8.88221 7.37583 9.00569C7.99659 9.12916 8.64002 9.06579 9.22476 8.82358C9.8095 8.58138 10.3093 8.17121 10.6609 7.64496C11.0125 7.11871 11.2002 6.5 11.2002 5.86708C11.2002 5.01837 10.8631 4.20441 10.2629 3.60428C9.66281 3.00414 8.84886 2.66699 8.00014 2.66699ZM8.00014 8.00048C7.5782 8.00048 7.16573 7.87536 6.81489 7.64094C6.46406 7.40652 6.19061 7.07332 6.02914 6.6835C5.86767 6.29367 5.82542 5.86472 5.90774 5.45088C5.99006 5.03704 6.19324 4.65691 6.4916 4.35855C6.78996 4.06019 7.1701 3.857 7.58394 3.77468C7.99777 3.69236 8.42673 3.73461 8.81656 3.89608C9.20638 4.05756 9.53957 4.331 9.77399 4.68183C10.0084 5.03267 10.1335 5.44514 10.1335 5.86708C10.1335 6.43289 9.90877 6.97553 9.50868 7.37562C9.10859 7.77571 8.56595 8.00048 8.00014 8.00048Z" fill="#D2D2D2"/></svg></button>,
		ignoreRowClick: true,
        button: true,
    },
];

const data = [
    {
        transactionId: "#454651681",
        customerName: 'Mr. Marshall',
        createdAtTime: '03:00pm',
        createdAtDate: '26-08-2021',
        createdAt: '03:00pm',
        transactionCategory: 'Dine-In',
        amount: '3,000',
        paymentBy: 'Online',
        paymentStatus: <span class="labelStatus statusPaid">Paid</span>,
       
    },
    {
        transactionId: "#454651682",
        customerName: 'Mrs. Marshall',
        createdAtTime: '03:00pm',
        createdAtDate: '26-08-2021',
        createdAt: '03:00pm',
        transactionCategory: 'Takeaway',
        amount: '3,000',
        paymentBy: 'Cash',
        paymentStatus: <span class="labelStatus statusPaid">Paid</span>,
    },
    {
        transactionId: "#454651683",
        customerName: 'Mr. Adam',
        createdAtTime: '03:00pm',
        createdAtDate: '26-08-2021',
        createdAt: '03:00pm',
        transactionCategory: 'Delivery',
        amount: '3,000',
        paymentBy: 'Online',
        paymentStatus: <span class="labelStatus statusPending">Pending</span>,
    },
    {
        transactionId: "#454651684",
        customerName: 'Mrs. Adam',
        createdAtTime: '03:00pm',
        createdAtDate: '26-08-2021',
        createdAt: '03:00pm',
        transactionCategory: 'Dine-In',
        amount: '3,000',
        paymentBy: 'Cash',
        paymentStatus: <span class="labelStatus statusFailed">Failed</span>,
    },
    {
        transactionId: "#454651685",
        customerName: 'Mr. Reeves',
        createdAtTime: '03:00pm',
        createdAtDate: '26-08-2021',
        createdAt: '03:00pm',
        transactionCategory: 'Dine-In',
        amount: '3,000',
        paymentBy: 'Online',
        paymentStatus: <span class="labelStatus statusPending">Pending</span>,
    },
    {
        transactionId: "#454651686",
        customerName: 'Mrs. Reeves',
        createdAtTime: '03:00pm',
        createdAtDate: '26-08-2021',
        createdAt: '03:00pm',
        transactionCategory: 'Delivery',
        amount: '3,000',
        paymentBy: 'Online',
        paymentStatus: <span class="labelStatus statusPaid">Failed</span>,
    },
    {
        transactionId: "#454651687",
        customerName: 'Mr. Wood',
        createdAtTime: '03:00pm',
        createdAtDate: '26-08-2021',
        createdAt: '03:00pm',
        transactionCategory: 'Takeaway',
        amount: '3,000',
        paymentBy: 'Cash',
        paymentStatus: <span class="labelStatus statusPaid">Failed</span>,
    },
    {
        transactionId: "#454651688",
        customerName: 'Mr. Clint',
        createdAtTime: '03:00pm',
        createdAtDate: '26-08-2021',
        createdAt: '03:00pm',
        transactionCategory: 'Dine-In',
        amount: '3,000',
        paymentBy: 'Online',
        paymentStatus: <span class="labelStatus statusPaid">Pending</span>,
    },
    {
        transactionId: "#454651689",
        customerName: 'Mr. Alex',
        createdAtTime: '03:00pm',
        createdAtDate: '26-08-2021',
        createdAt: '03:00pm',
        transactionCategory: 'Dine-In',
        amount: '3,000',
        paymentBy: 'Online',
        paymentStatus: <span class="labelStatus statusPaid">Paid</span>,
    },
    {
        transactionId: "#454651690",
        customerName: 'Mr. Alexis',
        createdAtTime: '03:00pm',
        createdAtDate: '26-08-2021',
        createdAt: '03:00pm',
        transactionCategory: 'Takeaway',
        amount: '3,000',
        paymentBy: 'Cash',
        paymentStatus: <span class="labelStatus statusPaid">Paid</span>,
    },
    {
        transactionId: "#454651691",
        customerName: 'Mr. Alexis',
        createdAtTime: '03:00pm',
        createdAtDate: '26-08-2021',
        createdAt: '03:00pm',
        transactionCategory: 'Takeaway',
        amount: '3,000',
        paymentBy: 'Cash',
        paymentStatus: <span class="labelStatus statusPaid">Paid</span>,
    },
    {
        transactionId: "#454651692",
        customerName: 'Mr. Alexis',
        createdAtTime: '03:00pm',
        createdAtDate: '26-08-2021',
        createdAt: '03:00pm',
        transactionCategory: 'Takeaway',
        amount: '3,000',
        paymentBy: 'Cash',
        paymentStatus: <span class="labelStatus statusPaid">Paid</span>,
    },
    {
        transactionId: "#454651693",
        customerName: 'Mr. Alexis',
        createdAtTime: '03:00pm',
        createdAtDate: '26-08-2021',
        createdAt: '03:00pm',
        transactionCategory: 'Takeaway',
        amount: '3,000',
        paymentBy: 'Cash',
        paymentStatus: <span class="labelStatus statusPaid">Paid</span>,
    },
    {
        transactionId: "#454651694",
        customerName: 'Mr. Alexis',
        createdAtTime: '03:00pm',
        createdAtDate: '26-08-2021',
        createdAt: '03:00pm',
        transactionCategory: 'Takeaway',
        amount: '3,000',
        paymentBy: 'Cash',
        paymentStatus: <span class="labelStatus statusPaid">Paid</span>,
    },
    {
        transactionId: "#454651695",
        customerName: 'Mr. Alexis',
        createdAtTime: '03:00pm',
        createdAtDate: '26-08-2021',
        createdAt: '03:00pm',
        transactionCategory: 'Takeaway',
        amount: '3,000',
        paymentBy: 'Cash',
        paymentStatus: <span class="labelStatus statusPaid">Paid</span>,
    },
    {
        transactionId: "#454651696",
        customerName: 'Mr. Alexis',
        createdAtTime: '03:00pm',
        createdAtDate: '26-08-2021',
        createdAt: '03:00pm',
        transactionCategory: 'Takeaway',
        amount: '3,000',
        paymentBy: 'Cash',
        paymentStatus: <span class="labelStatus statusPaid">Paid</span>,
    },
];


class Payments extends Component {
    constructor() {
        super();
        this.state = {checked: false};
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(checked) {
        this.setState({checked});
    }
    



    render() {
        return (
            <div className="paymentsPage">
                <SidebarMenu/>
                <div className="main_page_sectoin">
                    <div className="top_header">
                        <Container>
                            <Row>
                                <Col md={6}>
                                    <div className="left_side">
                                        <div className="drop_down">
                                            <h2>Payments</h2>
                                        </div>
                                        <div className="data_title">{format(new Date(), 'MM-dd-yyyy')} | {format(new Date(), "eeee")}</div>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="right_side">
                                        <div className="notification-icon">
                                            <Link to="">
                                                <img src={notification} alt="Notification_icon"/>
                                            </Link>
                                        </div>
                                        <div className="profile-icon">
                                            <div className="profile_img">
                                                <img src={profile} alt="profile_icon"/>
                                            </div>
                                            <div className="name_profile">
                                                John Doe
                                                <span>Sales Manager</span>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <div className="main_panel">
                        <Container>
                            <Row>
                                <Col md={12}>
                                <div className="title_content border-bottom_1">
                                        <div className="breadcrumbs">
                                            <Row>
                                                <Col sm={12} md={12} lg={5}>
                                                    <ul className="list-inline">
                                                        <li className="list-inline-item"><a href="#">Performance-Center</a></li>
                                                        <li className="list-inline-item"><a href="#">whatever</a></li>
                                                        <li className="list-inline-item">Payments</li>
                                                    </ul>
                                                </Col>
                                                <Col sm={12} md={12} lg={7}>
                                                    <ProductSearch />
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                    <div className="menu_listing">
                                        <h3>Payment</h3>
                                        <div className="category_breadcrumb">
                                            <Button className="btn btn-primary btnNewEntry">+ Add New Entry</Button>
                                        </div>
                                        {/* <div className="item_counts">
                                            <div className="counting">
                                                Showing1-3 of 3 results
                                            </div>
                                            <div className="result_page">
                                                Results per page:
                                                <select name="" id="">
                                                    <option value="10">10</option>
                                                    <option value="10">50</option>
                                                    <option value="10">100</option>
                                                </select>
                                            </div>
                                        </div> */}

                                        <div className="paymentDataTable">

                                            <DataTable columns={columns} data={data} customStyles={customStyles} pagination responsive highlightOnHover/>
                                        </div>

                                    </div>

                                </Col>

                            </Row>
                        </Container>
                    </div>
                </div>

            </div>
        );
    }
}

export default Payments;