import React, {Component} from 'react';
import CanvasJSReact from '../../assets/chart/canvasjs.react';

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

class BarChart extends Component {
    constructor() {
        super();
        this.toggleDataSeries = this.toggleDataSeries.bind(this);
    }

    toggleDataSeries(e) {
        if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
            e.dataSeries.visible = false;
        } else {
            e.dataSeries.visible = true;
        }
        this.chart.render();
    }

    render() {
        const options = {
            animationEnabled: true,
            theme: "light2",
            gridThickness: 0,
            axisY: {
                titleFontColor: "#B6BCC2",
                lineColor: "#B6BCC2",
                labelFontColor: "#B6BCC2",
                tickColor: "#B6BCC2"
            },
            axisX: {
                titleFontColor: "#B6BCC2",
                lineColor: "#B6BCC2",
                labelFontColor: "#B6BCC2",
                tickColor: "#B6BCC2"
            }
            ,
            toolTip: {
                shared: true
            }
            , legend: {
                cursor: "pointer",
                itemclick: this.toggleDataSeries,
                verticalAlign: "top",
                horizontalAlign: "right",
                fontSize: 15,
            },

            data: [
                {
                    color: "#0178E5",
                    type: "spline",
                    name: "Dine-In",
                    lineThickness: 4,
                    showInLegend: true,
                    markerSize: 18,
                    markerColor: "rgba(2, 119, 229, 0.5)",
                    dataPoints: [

                        {y: 1000, label: "0-Aug-2021"},
                        {y: 500, label: "4-Aug-2021"},
                        {y: 300, label: "12-Aug-2021"},
                        {y: 400, label: "16-Aug-2021"},
                        {y: 500, label: "24-Aug-2021"},
                        {y: 500, label: "30-Aug-2021"},
                    ]
                },
                {
                    type: "spline",
                    name: "Takeaway",
                    color: "#FCBB36", lineThickness: 4,
                    showInLegend: true,
                    legendMarkerType: "square",
                    markerSize: 18,
                    markerColor: "rgba(252, 187, 54, 0.5)",
                    dataPoints:
                        [
                            {y: 300, label: "0-Aug-2021"},
                            {y: 1000, label: "4-Aug-2021"},
                            {y: 800, label: "12-Aug-2021"},
                            {y: 400, label: "16-Aug-2021"},
                            {y: 500, label: "24-Aug-2021"},
                            {y: 200, label: "30-Aug-2021"},

                        ]
                },
                {
                    type: "spline",
                    name: "Reservation",
                    color: "#F5697A", lineThickness: 4,
                    showInLegend: true,
                    legendMarkerType: "square",
                    markerSize: 18,
                    markerColor: "rgba(245, 105, 122, 0.5)",
                    dataPoints:
                        [
                            {y: 900, label: "0-Aug-2021"},
                            {y: 500, label: "4-Aug-2021"},
                            {y: 700, label: "12-Aug-2021"},
                            {y: 900, label: "16-Aug-2021"},
                            {y: 500, label: "24-Aug-2021"},
                            {y: 1000, label: "30-Aug-2021"},

                        ]
                }
                ,
                {
                    type: "spline",
                    name: "Delivery",
                    color: "#6EC9A8",
                    lineThickness: 4,
                    showInLegend: true,
                    legendMarkerType: "square",
                    markerSize: 18,
                    markerColor: "rgba(110, 201, 168, 0.5)",
                    dataPoints:
                        [
                            {y: 150, label: "0-Aug-2021"},
                            {y: 100, label: "4-Aug-2021"},
                            {y: 800, label: "12-Aug-2021"},
                            {y: 400, label: "16-Aug-2021"},
                            {y: 800, label: "24-Aug-2021"},
                            {y: 200, label: "30-Aug-2021"},

                        ]
                }
            ]
        }

        return (
            <div>
                <CanvasJSChart options={options} onRef={ref => this.chart = ref}/>
            </div>
        );
    }
}

export default BarChart;