import React from 'react'
import {Button, Form} from "react-bootstrap";
import search from "../../../assets/icons/search.svg";

function MenuOrderFilters() {
    return (
        <div className="div_fillter">
            <Form>
                <Form.Group className="from_search">
                    <Form.Control type="text"
                                    placeholder="What are you looking for..."/>
                    <Button type="submit" className="btn_search">
                        <img src={search} alt="search_icon"/>
                    </Button>
                </Form.Group>
                <Form.Group className="date_img">
                    <Form.Control type="date"/>
                </Form.Group>
                <Form.Group className="date_staus select_staus">
                    <select name="" id="">
                        <option value="">Status</option>
                        <option value="">Status</option>
                        <option value="">Status</option>
                        <option value="">Status</option>
                    </select>
                </Form.Group>
                <Form.Group className="order_platform select_staus">
                    <select name="" id="">
                        <option value="">Order Platform</option>
                        <option value="">Order Platform</option>
                        <option value="">Order Platform</option>
                        <option value="">Order Platform</option>
                    </select>
                </Form.Group>
                <Form.Group className="save_fillter select_staus">
                    <select name="" id="">
                        <option value="">Save filters</option>
                        <option value="">Save filters</option>
                        <option value="">Save filters</option>
                        <option value="">Save filters</option>
                    </select>
                </Form.Group>
                <Form.Group className="more_fillter select_staus">
                    <select name="" id="">
                        <option value="">More filters</option>
                        <option value="">More filters</option>
                        <option value="">More filters</option>
                        <option value="">More filters</option>
                    </select>
                </Form.Group>
            </Form>
        </div>
    )
}

export default MenuOrderFilters
