import React, {Component} from 'react';

import {Link} from "react-router-dom";
import logo_small from '../../logo_small.svg';
import email from '../../assets/icons/email.svg';
import user from '../../assets/icons/user.svg';
import phone from '../../assets/icons/phone.svg';
import key from '../../assets/icons/key.svg';
import eye from '../../assets/icons/eye.svg';
import eyeclose from '../../assets/icons/eye-closed.svg';
import done_icon from '../../assets/icons/done_icon.svg';
import error_icon from '../../assets/icons/error.svg';
import {Col, Container, Form, Label, Row} from "react-bootstrap";
import {validate} from "../helpers";
import CarouselBanner from "./components/CarouselBanner";
import Copyright from "../layouts/Copyright";

class CreateAccount extends Component {
    constructor(props) {
        super(props)
        this.state = {
            new_password: "",
            confirmation_password: "",
            show_password: false,
            show_password1: false,
            input: {},
            errors: {}

        }
        this.handleShowPassword = this.handleShowPassword.bind(this)
        this.handleShowPassword1 = this.handleShowPassword1.bind(this)
        this.handleChangeText = this.handleChangeText.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleShowPassword(e) {
        this.setState({show_password: this.state.show_password ? false : true})
    }

    handleShowPassword1(e) {
        this.setState({show_password1: this.state.show_password1 ? false : true})
    }

    handleChangeText(event) {

        let input = this.state.input;
        input[event.target.name] = event.target.value;

        this.setState({
            input
        });
        let validated = validate(this.state.input)
        this.setState({errors: validated.errors})
    }

    handleSubmit(event) {
        let validated = validate(this.state.input)
        if (validated.is_valid) {
            return true
        }
        event.preventDefault();
        this.setState({errors: validated.errors})
        return false
    }

    render() {
        return (
            <div>
                <div className="login_section">
                    <Container fluid className="h-100">
                        <Row className="h-100">
                            <Col md={12} lg={10} className="mt-auto mb-auto">
                                <div className="div_login">
                                    <div className="logo_small">
                                        <img src={logo_small} alt="Logo"/>
                                    </div>
                                    <h1>Welcome To <span>Serve</span>Easy</h1>
                                    <p> To keep connected with us please login with your personal
                                        information by email address and password
                                    </p>
                                </div>
                                <div className="login_form">
                                    <Form className="main_form" onSubmit={this.handleSubmit}>
                                        <Form.Group className="form_box first">
                                            <div className="svg_icon">
                                                <img src={user} alt="user_icon"/>
                                            </div>
                                            <Form.Label>User Name</Form.Label>
                                            <Form.Control type="text" placeholder="Jhne.deo" required
                                                          id="user_name"
                                                          onChange={this.handleChangeText} name="user_name"/>
                                            <div className="icon_last">
                                                <img src={done_icon} alt="done_icon"/>

                                            </div>
                                        </Form.Group>
                                        <Form.Group className="form_box ">
                                            <div className="svg_icon">
                                                <img src={phone} alt="phone_icon"/>
                                            </div>
                                            <Form.Label>Phone Number</Form.Label>
                                            <Form.Control type="number" required onChange={this.handleChangeText}
                                                          name="phone" value={this.state.input.phone}
                                                          placeholder="+1 123 456 789"/>
                                            <div className="icon_last">
                                                <img src={done_icon} alt="done_icon"/>
                                            </div>
                                        </Form.Group>
                                        <Form.Group className="form_box ">
                                            <div className="svg_icon">
                                                <img src={email} alt="email_icon"/>
                                            </div>
                                            <Form.Label>Email address</Form.Label>
                                            <Form.Control type="text" placeholder="johndoe@serveeasy.com" required
                                                          id="email"
                                                          onChange={this.handleChangeText} name="email"/>
                                            <div
                                                className={this.state.errors.email ? "error_danger icon_last" : "icon_last"}>
                                                {this.state.errors.email ?
                                                    <img src={error_icon} alt="error_icon"/> :
                                                    <img src={done_icon} alt="done_icon"/>
                                                }
                                            </div>
                                        </Form.Group>
                                        <Form.Group className="form_box ">
                                            <div className="svg_icon">
                                                <img src={key} alt="key_icon"/>
                                            </div>
                                            <Form.Label>Password</Form.Label>
                                            <Form.Control type={this.state.show_password ? "text" : "password"}
                                                          placeholder="****************"
                                                          onChange={this.handleChangeText} required name="password"
                                                          id="password"/>
                                            <div className="icon_last">
                                                {this.state.show_password ?
                                                    <img src={eye} alt="eye_icon" onClick={this.handleShowPassword}/> :
                                                    <img src={eyeclose} alt="eyeclose"
                                                         onClick={this.handleShowPassword}/>
                                                }
                                            </div>
                                        </Form.Group>
                                        <Form.Group
                                            className={this.state.errors.cpassword ? "form_box last danger" : "form_box last"}>
                                            <div className="svg_icon">
                                                <img src={key} alt="key_icon"/>
                                            </div>
                                            <Form.Label>Confirm Password</Form.Label>
                                            <Form.Control type={this.state.show_password1 ? "text" : "password"}
                                                          placeholder="****************"
                                                          onChange={this.handleChangeText} required name="cpassword"
                                                          id="cpassword"/>
                                            <div className="icon_last">
                                                {this.state.show_password1 ?
                                                    <img src={eye} alt="eye_icon" onClick={this.handleShowPassword1}/> :
                                                    <img src={eyeclose} alt="eyeclose"
                                                         onClick={this.handleShowPassword1}/>
                                                }
                                            </div>
                                        </Form.Group>
                                        <div className="check_box">
                                            <Form.Group className="form_check" controlId="formBasicCheckbox">
                                                <Form.Check type="checkbox" label="I am agree  with the terms of use"/>
                                            </Form.Group>
                                        </div>
                                        <Form.Group className="form-submit signup">
                                            <Form.Control type="submit" className="submit_style" value="Sign up"/>
                                        </Form.Group>

                                    </Form>

                                    <div className="login_bottom">
                                        Already have an account? <Link to="/login">Login</Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>


                </div>
                <CarouselBanner/>
                <Copyright/>
            </div>
        );
    }
}

export default CreateAccount;