import React, { Component } from 'react';
import SidebarMenu from "../layouts/SidebarMenu";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import notification from "../../assets/icons/Notification.svg";
import profile from "../../assets/icons/photo.png";
import search from "../../assets/icons/search.svg";
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import file from "../../assets/icons/file.svg";
import file1 from "../../assets/icons/file.png";

class ProductDetails extends Component {
    render() {
        return (
            <div>
                <SidebarMenu />
                <div className="main_page_sectoin">
                    <div className="top_header">
                        <Container>
                            <Row>
                                <Col md={6}>
                                    <div className="left_side">
                                        <div className="drop_down">
                                            <h2>Product Detail</h2>
                                        </div>
                                        <div className="data_title">17-08-2021 | Tuesday</div>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="right_side">
                                        <div className="notification-icon">
                                            <Link to="">
                                                <img src={notification} alt="Notification_icon" />
                                            </Link>
                                        </div>
                                        <div className="profile-icon">
                                            <div className="profile_img">
                                                <img src={profile} alt="profile_icon" />
                                            </div>
                                            <div className="name_profile">
                                                John Doe
                                                <span>Sales Manager</span>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <div className="main_panel">
                        <Container>
                            <Row>
                                <Col md={6}>
                                    <div className="sub__title">

                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <circle r="10" transform="matrix(-1 0 0 1 10 10)" fill="#0178E5" />
                                            <path d="M5 10.2727H14M14 10.2727L10.246 7M14 10.2727L10.246 13.5455"
                                                stroke="white" stroke-width="1.5" stroke-linecap="round"
                                                stroke-linejoin="round" />
                                        </svg>
                                        Products &gt; Chicken Sandwich / VEGGIE &gt; Chicken Sandwich &gt; BOSS Chicken Sando
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="title_content div__search">
                                        <div className="div_search">
                                            <Form>
                                                <Form.Group className="from_search">
                                                    <Form.Control type="text"
                                                        placeholder="Search Product" />
                                                    <Button type="submit" className="btn_search">
                                                        <img src={search} alt="search_icon" />
                                                    </Button>
                                                </Form.Group>
                                            </Form>
                                        </div>
                                        <div className="back_to_home">
                                            <Link to="">Back to Menu</Link>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <div className="bg_white product_details">
                                        <div className="tabs_content order_tabs" id="printid">

                                            <Tabs>
                                                <TabList>
                                                    <Tab>Product Details</Tab>
                                                    <Tab>Display/Print Options</Tab>
                                                    <Tab>Advanced Product Details</Tab>
                                                    <Tab>Modifiers</Tab>
                                                    <Tab>Tax Rates Setup</Tab>
                                                </TabList>
                                                <TabPanel>
                                                    <div className="tab_froms">
                                                        <Form className="">
                                                            <Row>
                                                                <Col md={5}>
                                                                    <Form.Group className="div_from">
                                                                        <Form.Label>Display Name
                                                                            <svg width="15" height="15"
                                                                                viewBox="0 0 15 15" fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg">
                                                                                <rect width="15" height="15" rx="7.5"
                                                                                    fill="#DEDEDE" />
                                                                                <path
                                                                                    d="M7.232 8.152C7.20533 7.97067 7.21067 7.80533 7.248 7.656C7.29067 7.50667 7.34933 7.368 7.424 7.24C7.49867 7.112 7.58133 6.99467 7.672 6.888C7.768 6.78133 7.85867 6.68 7.944 6.584C8.02933 6.48267 8.09867 6.38667 8.152 6.296C8.21067 6.2 8.24 6.10133 8.24 6C8.24 5.83467 8.18933 5.70933 8.088 5.624C7.992 5.53867 7.864 5.496 7.704 5.496C7.55467 5.496 7.42133 5.53067 7.304 5.6C7.192 5.66933 7.08267 5.75733 6.976 5.864L6.328 5.272C6.51467 5.05333 6.736 4.87733 6.992 4.744C7.25333 4.61067 7.54133 4.544 7.856 4.544C8.06933 4.544 8.26667 4.57067 8.448 4.624C8.63467 4.67733 8.79467 4.76 8.928 4.872C9.06667 4.984 9.17333 5.128 9.248 5.304C9.328 5.47467 9.368 5.67733 9.368 5.912C9.368 6.07733 9.33867 6.22667 9.28 6.36C9.22133 6.488 9.14667 6.608 9.056 6.72C8.97067 6.832 8.87733 6.94133 8.776 7.048C8.68 7.14933 8.58933 7.256 8.504 7.368C8.424 7.48 8.36 7.6 8.312 7.728C8.264 7.85067 8.248 7.992 8.264 8.152H7.232ZM7.744 10.096C7.54133 10.096 7.37333 10.0267 7.24 9.888C7.10667 9.744 7.04 9.568 7.04 9.36C7.04 9.14667 7.10667 8.97067 7.24 8.832C7.37333 8.688 7.54133 8.616 7.744 8.616C7.94667 8.616 8.11467 8.688 8.248 8.832C8.38667 8.97067 8.456 9.14667 8.456 9.36C8.456 9.568 8.38667 9.744 8.248 9.888C8.11467 10.0267 7.94667 10.096 7.744 10.096Z"
                                                                                    fill="#4D5464" />
                                                                            </svg>
                                                                        </Form.Label>
                                                                        <Form.Control type="text" />
                                                                    </Form.Group>
                                                                    <Form.Group className="div_from">
                                                                        <Form.Label>Description
                                                                            <svg width="15" height="15"
                                                                                viewBox="0 0 15 15" fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg">
                                                                                <rect width="15" height="15" rx="7.5"
                                                                                    fill="#DEDEDE" />
                                                                                <path
                                                                                    d="M7.232 8.152C7.20533 7.97067 7.21067 7.80533 7.248 7.656C7.29067 7.50667 7.34933 7.368 7.424 7.24C7.49867 7.112 7.58133 6.99467 7.672 6.888C7.768 6.78133 7.85867 6.68 7.944 6.584C8.02933 6.48267 8.09867 6.38667 8.152 6.296C8.21067 6.2 8.24 6.10133 8.24 6C8.24 5.83467 8.18933 5.70933 8.088 5.624C7.992 5.53867 7.864 5.496 7.704 5.496C7.55467 5.496 7.42133 5.53067 7.304 5.6C7.192 5.66933 7.08267 5.75733 6.976 5.864L6.328 5.272C6.51467 5.05333 6.736 4.87733 6.992 4.744C7.25333 4.61067 7.54133 4.544 7.856 4.544C8.06933 4.544 8.26667 4.57067 8.448 4.624C8.63467 4.67733 8.79467 4.76 8.928 4.872C9.06667 4.984 9.17333 5.128 9.248 5.304C9.328 5.47467 9.368 5.67733 9.368 5.912C9.368 6.07733 9.33867 6.22667 9.28 6.36C9.22133 6.488 9.14667 6.608 9.056 6.72C8.97067 6.832 8.87733 6.94133 8.776 7.048C8.68 7.14933 8.58933 7.256 8.504 7.368C8.424 7.48 8.36 7.6 8.312 7.728C8.264 7.85067 8.248 7.992 8.264 8.152H7.232ZM7.744 10.096C7.54133 10.096 7.37333 10.0267 7.24 9.888C7.10667 9.744 7.04 9.568 7.04 9.36C7.04 9.14667 7.10667 8.97067 7.24 8.832C7.37333 8.688 7.54133 8.616 7.744 8.616C7.94667 8.616 8.11467 8.688 8.248 8.832C8.38667 8.97067 8.456 9.14667 8.456 9.36C8.456 9.568 8.38667 9.744 8.248 9.888C8.11467 10.0267 7.94667 10.096 7.744 10.096Z"
                                                                                    fill="#4D5464" />
                                                                            </svg>
                                                                        </Form.Label>
                                                                        <Form.Control as="textarea" />
                                                                    </Form.Group>
                                                                    <Form.Group className="div_from">
                                                                        <Form.Label>Product Class
                                                                            <svg width="15" height="15"
                                                                                viewBox="0 0 15 15" fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg">
                                                                                <rect width="15" height="15" rx="7.5"
                                                                                    fill="#DEDEDE" />
                                                                                <path
                                                                                    d="M7.232 8.152C7.20533 7.97067 7.21067 7.80533 7.248 7.656C7.29067 7.50667 7.34933 7.368 7.424 7.24C7.49867 7.112 7.58133 6.99467 7.672 6.888C7.768 6.78133 7.85867 6.68 7.944 6.584C8.02933 6.48267 8.09867 6.38667 8.152 6.296C8.21067 6.2 8.24 6.10133 8.24 6C8.24 5.83467 8.18933 5.70933 8.088 5.624C7.992 5.53867 7.864 5.496 7.704 5.496C7.55467 5.496 7.42133 5.53067 7.304 5.6C7.192 5.66933 7.08267 5.75733 6.976 5.864L6.328 5.272C6.51467 5.05333 6.736 4.87733 6.992 4.744C7.25333 4.61067 7.54133 4.544 7.856 4.544C8.06933 4.544 8.26667 4.57067 8.448 4.624C8.63467 4.67733 8.79467 4.76 8.928 4.872C9.06667 4.984 9.17333 5.128 9.248 5.304C9.328 5.47467 9.368 5.67733 9.368 5.912C9.368 6.07733 9.33867 6.22667 9.28 6.36C9.22133 6.488 9.14667 6.608 9.056 6.72C8.97067 6.832 8.87733 6.94133 8.776 7.048C8.68 7.14933 8.58933 7.256 8.504 7.368C8.424 7.48 8.36 7.6 8.312 7.728C8.264 7.85067 8.248 7.992 8.264 8.152H7.232ZM7.744 10.096C7.54133 10.096 7.37333 10.0267 7.24 9.888C7.10667 9.744 7.04 9.568 7.04 9.36C7.04 9.14667 7.10667 8.97067 7.24 8.832C7.37333 8.688 7.54133 8.616 7.744 8.616C7.94667 8.616 8.11467 8.688 8.248 8.832C8.38667 8.97067 8.456 9.14667 8.456 9.36C8.456 9.568 8.38667 9.744 8.248 9.888C8.11467 10.0267 7.94667 10.096 7.744 10.096Z"
                                                                                    fill="#4D5464" />
                                                                            </svg>
                                                                        </Form.Label>
                                                                        <Form.Control type="text" />
                                                                    </Form.Group>
                                                                    <Form.Group className="div_from">
                                                                        <Form.Label>Price
                                                                            <svg width="15" height="15"
                                                                                viewBox="0 0 15 15" fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg">
                                                                                <rect width="15" height="15" rx="7.5"
                                                                                    fill="#DEDEDE" />
                                                                                <path
                                                                                    d="M7.232 8.152C7.20533 7.97067 7.21067 7.80533 7.248 7.656C7.29067 7.50667 7.34933 7.368 7.424 7.24C7.49867 7.112 7.58133 6.99467 7.672 6.888C7.768 6.78133 7.85867 6.68 7.944 6.584C8.02933 6.48267 8.09867 6.38667 8.152 6.296C8.21067 6.2 8.24 6.10133 8.24 6C8.24 5.83467 8.18933 5.70933 8.088 5.624C7.992 5.53867 7.864 5.496 7.704 5.496C7.55467 5.496 7.42133 5.53067 7.304 5.6C7.192 5.66933 7.08267 5.75733 6.976 5.864L6.328 5.272C6.51467 5.05333 6.736 4.87733 6.992 4.744C7.25333 4.61067 7.54133 4.544 7.856 4.544C8.06933 4.544 8.26667 4.57067 8.448 4.624C8.63467 4.67733 8.79467 4.76 8.928 4.872C9.06667 4.984 9.17333 5.128 9.248 5.304C9.328 5.47467 9.368 5.67733 9.368 5.912C9.368 6.07733 9.33867 6.22667 9.28 6.36C9.22133 6.488 9.14667 6.608 9.056 6.72C8.97067 6.832 8.87733 6.94133 8.776 7.048C8.68 7.14933 8.58933 7.256 8.504 7.368C8.424 7.48 8.36 7.6 8.312 7.728C8.264 7.85067 8.248 7.992 8.264 8.152H7.232ZM7.744 10.096C7.54133 10.096 7.37333 10.0267 7.24 9.888C7.10667 9.744 7.04 9.568 7.04 9.36C7.04 9.14667 7.10667 8.97067 7.24 8.832C7.37333 8.688 7.54133 8.616 7.744 8.616C7.94667 8.616 8.11467 8.688 8.248 8.832C8.38667 8.97067 8.456 9.14667 8.456 9.36C8.456 9.568 8.38667 9.744 8.248 9.888C8.11467 10.0267 7.94667 10.096 7.744 10.096Z"
                                                                                    fill="#4D5464" />
                                                                            </svg>
                                                                        </Form.Label>
                                                                        <Form.Control type="text" />
                                                                    </Form.Group>

                                                                </Col>
                                                                <Col md={5} className="m-l-50">
                                                                    <Form.Group className="div_from">
                                                                        <Form.Label>Eligible for Discounts
                                                                            <svg width="15" height="15"
                                                                                viewBox="0 0 15 15" fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg">
                                                                                <rect width="15" height="15" rx="7.5"
                                                                                    fill="#DEDEDE" />
                                                                                <path
                                                                                    d="M7.232 8.152C7.20533 7.97067 7.21067 7.80533 7.248 7.656C7.29067 7.50667 7.34933 7.368 7.424 7.24C7.49867 7.112 7.58133 6.99467 7.672 6.888C7.768 6.78133 7.85867 6.68 7.944 6.584C8.02933 6.48267 8.09867 6.38667 8.152 6.296C8.21067 6.2 8.24 6.10133 8.24 6C8.24 5.83467 8.18933 5.70933 8.088 5.624C7.992 5.53867 7.864 5.496 7.704 5.496C7.55467 5.496 7.42133 5.53067 7.304 5.6C7.192 5.66933 7.08267 5.75733 6.976 5.864L6.328 5.272C6.51467 5.05333 6.736 4.87733 6.992 4.744C7.25333 4.61067 7.54133 4.544 7.856 4.544C8.06933 4.544 8.26667 4.57067 8.448 4.624C8.63467 4.67733 8.79467 4.76 8.928 4.872C9.06667 4.984 9.17333 5.128 9.248 5.304C9.328 5.47467 9.368 5.67733 9.368 5.912C9.368 6.07733 9.33867 6.22667 9.28 6.36C9.22133 6.488 9.14667 6.608 9.056 6.72C8.97067 6.832 8.87733 6.94133 8.776 7.048C8.68 7.14933 8.58933 7.256 8.504 7.368C8.424 7.48 8.36 7.6 8.312 7.728C8.264 7.85067 8.248 7.992 8.264 8.152H7.232ZM7.744 10.096C7.54133 10.096 7.37333 10.0267 7.24 9.888C7.10667 9.744 7.04 9.568 7.04 9.36C7.04 9.14667 7.10667 8.97067 7.24 8.832C7.37333 8.688 7.54133 8.616 7.744 8.616C7.94667 8.616 8.11467 8.688 8.248 8.832C8.38667 8.97067 8.456 9.14667 8.456 9.36C8.456 9.568 8.38667 9.744 8.248 9.888C8.11467 10.0267 7.94667 10.096 7.744 10.096Z"
                                                                                    fill="#4D5464" />
                                                                            </svg>
                                                                        </Form.Label>
                                                                        <Form.Check aria-label="option 1" />
                                                                    </Form.Group>
                                                                    <Form.Group className="div_from div_select">
                                                                        <Form.Label>Product Groups
                                                                            <svg width="15" height="15"
                                                                                viewBox="0 0 15 15" fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg">
                                                                                <rect width="15" height="15" rx="7.5"
                                                                                    fill="#DEDEDE" />
                                                                                <path
                                                                                    d="M7.232 8.152C7.20533 7.97067 7.21067 7.80533 7.248 7.656C7.29067 7.50667 7.34933 7.368 7.424 7.24C7.49867 7.112 7.58133 6.99467 7.672 6.888C7.768 6.78133 7.85867 6.68 7.944 6.584C8.02933 6.48267 8.09867 6.38667 8.152 6.296C8.21067 6.2 8.24 6.10133 8.24 6C8.24 5.83467 8.18933 5.70933 8.088 5.624C7.992 5.53867 7.864 5.496 7.704 5.496C7.55467 5.496 7.42133 5.53067 7.304 5.6C7.192 5.66933 7.08267 5.75733 6.976 5.864L6.328 5.272C6.51467 5.05333 6.736 4.87733 6.992 4.744C7.25333 4.61067 7.54133 4.544 7.856 4.544C8.06933 4.544 8.26667 4.57067 8.448 4.624C8.63467 4.67733 8.79467 4.76 8.928 4.872C9.06667 4.984 9.17333 5.128 9.248 5.304C9.328 5.47467 9.368 5.67733 9.368 5.912C9.368 6.07733 9.33867 6.22667 9.28 6.36C9.22133 6.488 9.14667 6.608 9.056 6.72C8.97067 6.832 8.87733 6.94133 8.776 7.048C8.68 7.14933 8.58933 7.256 8.504 7.368C8.424 7.48 8.36 7.6 8.312 7.728C8.264 7.85067 8.248 7.992 8.264 8.152H7.232ZM7.744 10.096C7.54133 10.096 7.37333 10.0267 7.24 9.888C7.10667 9.744 7.04 9.568 7.04 9.36C7.04 9.14667 7.10667 8.97067 7.24 8.832C7.37333 8.688 7.54133 8.616 7.744 8.616C7.94667 8.616 8.11467 8.688 8.248 8.832C8.38667 8.97067 8.456 9.14667 8.456 9.36C8.456 9.568 8.38667 9.744 8.248 9.888C8.11467 10.0267 7.94667 10.096 7.744 10.096Z"
                                                                                    fill="#4D5464" />
                                                                            </svg>
                                                                        </Form.Label>
                                                                        <Form.Select>
                                                                            <option value="Burgers">Burgers</option>
                                                                            <option value="Pizza">Pizza</option>
                                                                            <option value="Beverages">Beverages</option>
                                                                        </Form.Select>
                                                                    </Form.Group>
                                                                    <Form.Group className="div_from">
                                                                        <Form.Label>SKU
                                                                            <svg width="15" height="15"
                                                                                viewBox="0 0 15 15" fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg">
                                                                                <rect width="15" height="15" rx="7.5"
                                                                                    fill="#DEDEDE" />
                                                                                <path
                                                                                    d="M7.232 8.152C7.20533 7.97067 7.21067 7.80533 7.248 7.656C7.29067 7.50667 7.34933 7.368 7.424 7.24C7.49867 7.112 7.58133 6.99467 7.672 6.888C7.768 6.78133 7.85867 6.68 7.944 6.584C8.02933 6.48267 8.09867 6.38667 8.152 6.296C8.21067 6.2 8.24 6.10133 8.24 6C8.24 5.83467 8.18933 5.70933 8.088 5.624C7.992 5.53867 7.864 5.496 7.704 5.496C7.55467 5.496 7.42133 5.53067 7.304 5.6C7.192 5.66933 7.08267 5.75733 6.976 5.864L6.328 5.272C6.51467 5.05333 6.736 4.87733 6.992 4.744C7.25333 4.61067 7.54133 4.544 7.856 4.544C8.06933 4.544 8.26667 4.57067 8.448 4.624C8.63467 4.67733 8.79467 4.76 8.928 4.872C9.06667 4.984 9.17333 5.128 9.248 5.304C9.328 5.47467 9.368 5.67733 9.368 5.912C9.368 6.07733 9.33867 6.22667 9.28 6.36C9.22133 6.488 9.14667 6.608 9.056 6.72C8.97067 6.832 8.87733 6.94133 8.776 7.048C8.68 7.14933 8.58933 7.256 8.504 7.368C8.424 7.48 8.36 7.6 8.312 7.728C8.264 7.85067 8.248 7.992 8.264 8.152H7.232ZM7.744 10.096C7.54133 10.096 7.37333 10.0267 7.24 9.888C7.10667 9.744 7.04 9.568 7.04 9.36C7.04 9.14667 7.10667 8.97067 7.24 8.832C7.37333 8.688 7.54133 8.616 7.744 8.616C7.94667 8.616 8.11467 8.688 8.248 8.832C8.38667 8.97067 8.456 9.14667 8.456 9.36C8.456 9.568 8.38667 9.744 8.248 9.888C8.11467 10.0267 7.94667 10.096 7.744 10.096Z"
                                                                                    fill="#4D5464" />
                                                                            </svg>
                                                                        </Form.Label>
                                                                        <Form.Control type="text" />
                                                                    </Form.Group>
                                                                    <Form.Group className="div_from">
                                                                        <Form.Label>Barcode
                                                                            <svg width="15" height="15"
                                                                                viewBox="0 0 15 15" fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg">
                                                                                <rect width="15" height="15" rx="7.5"
                                                                                    fill="#DEDEDE" />
                                                                                <path
                                                                                    d="M7.232 8.152C7.20533 7.97067 7.21067 7.80533 7.248 7.656C7.29067 7.50667 7.34933 7.368 7.424 7.24C7.49867 7.112 7.58133 6.99467 7.672 6.888C7.768 6.78133 7.85867 6.68 7.944 6.584C8.02933 6.48267 8.09867 6.38667 8.152 6.296C8.21067 6.2 8.24 6.10133 8.24 6C8.24 5.83467 8.18933 5.70933 8.088 5.624C7.992 5.53867 7.864 5.496 7.704 5.496C7.55467 5.496 7.42133 5.53067 7.304 5.6C7.192 5.66933 7.08267 5.75733 6.976 5.864L6.328 5.272C6.51467 5.05333 6.736 4.87733 6.992 4.744C7.25333 4.61067 7.54133 4.544 7.856 4.544C8.06933 4.544 8.26667 4.57067 8.448 4.624C8.63467 4.67733 8.79467 4.76 8.928 4.872C9.06667 4.984 9.17333 5.128 9.248 5.304C9.328 5.47467 9.368 5.67733 9.368 5.912C9.368 6.07733 9.33867 6.22667 9.28 6.36C9.22133 6.488 9.14667 6.608 9.056 6.72C8.97067 6.832 8.87733 6.94133 8.776 7.048C8.68 7.14933 8.58933 7.256 8.504 7.368C8.424 7.48 8.36 7.6 8.312 7.728C8.264 7.85067 8.248 7.992 8.264 8.152H7.232ZM7.744 10.096C7.54133 10.096 7.37333 10.0267 7.24 9.888C7.10667 9.744 7.04 9.568 7.04 9.36C7.04 9.14667 7.10667 8.97067 7.24 8.832C7.37333 8.688 7.54133 8.616 7.744 8.616C7.94667 8.616 8.11467 8.688 8.248 8.832C8.38667 8.97067 8.456 9.14667 8.456 9.36C8.456 9.568 8.38667 9.744 8.248 9.888C8.11467 10.0267 7.94667 10.096 7.744 10.096Z"
                                                                                    fill="#4D5464" />
                                                                            </svg>
                                                                        </Form.Label>
                                                                        <Form.Control type="text" />
                                                                    </Form.Group>
                                                                    <Form.Group className="div_from">
                                                                        <Form.Label>Active
                                                                            <svg width="15" height="15"
                                                                                viewBox="0 0 15 15" fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg">
                                                                                <rect width="15" height="15" rx="7.5"
                                                                                    fill="#DEDEDE" />
                                                                                <path
                                                                                    d="M7.232 8.152C7.20533 7.97067 7.21067 7.80533 7.248 7.656C7.29067 7.50667 7.34933 7.368 7.424 7.24C7.49867 7.112 7.58133 6.99467 7.672 6.888C7.768 6.78133 7.85867 6.68 7.944 6.584C8.02933 6.48267 8.09867 6.38667 8.152 6.296C8.21067 6.2 8.24 6.10133 8.24 6C8.24 5.83467 8.18933 5.70933 8.088 5.624C7.992 5.53867 7.864 5.496 7.704 5.496C7.55467 5.496 7.42133 5.53067 7.304 5.6C7.192 5.66933 7.08267 5.75733 6.976 5.864L6.328 5.272C6.51467 5.05333 6.736 4.87733 6.992 4.744C7.25333 4.61067 7.54133 4.544 7.856 4.544C8.06933 4.544 8.26667 4.57067 8.448 4.624C8.63467 4.67733 8.79467 4.76 8.928 4.872C9.06667 4.984 9.17333 5.128 9.248 5.304C9.328 5.47467 9.368 5.67733 9.368 5.912C9.368 6.07733 9.33867 6.22667 9.28 6.36C9.22133 6.488 9.14667 6.608 9.056 6.72C8.97067 6.832 8.87733 6.94133 8.776 7.048C8.68 7.14933 8.58933 7.256 8.504 7.368C8.424 7.48 8.36 7.6 8.312 7.728C8.264 7.85067 8.248 7.992 8.264 8.152H7.232ZM7.744 10.096C7.54133 10.096 7.37333 10.0267 7.24 9.888C7.10667 9.744 7.04 9.568 7.04 9.36C7.04 9.14667 7.10667 8.97067 7.24 8.832C7.37333 8.688 7.54133 8.616 7.744 8.616C7.94667 8.616 8.11467 8.688 8.248 8.832C8.38667 8.97067 8.456 9.14667 8.456 9.36C8.456 9.568 8.38667 9.744 8.248 9.888C8.11467 10.0267 7.94667 10.096 7.744 10.096Z"
                                                                                    fill="#4D5464" />
                                                                            </svg>
                                                                        </Form.Label>
                                                                        <Form.Check aria-label="option 1" />
                                                                        <div className="img_show active">
                                                                            <img src={file1} alt="" />
                                                                            <img src={file1} alt="" />
                                                                            <img src={file1} alt="" />
                                                                        </div>
                                                                        <div className="custom_file">
                                                                            <Form.Control type="file" />
                                                                            <div className="file_icon">
                                                                                <div className="img_box">
                                                                                    <img src={file} alt="" />
                                                                                </div>
                                                                                <div className="text_box">
                                                                                    Drag &amp; Drop file you want to upload
                                                                                    <span>Your video will be private before yoiu make a public.</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col md={8}>
                                                                    <Button type="submit" className="btn_submit">
                                                                        Update
                                                                    </Button>
                                                                    <Button type="button" className="btn_submit">
                                                                        Cancel
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </Form>
                                                    </div>
                                                </TabPanel>
                                                <TabPanel>
                                                    <div className="tab_froms">
                                                        <Form className="">
                                                            <Row>
                                                                <Col md={5}>
                                                                    <Form.Group className="div_from div_select">
                                                                        <Form.Label>Main Category
                                                                            <svg width="15" height="15"
                                                                                viewBox="0 0 15 15" fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg">
                                                                                <rect width="15" height="15" rx="7.5"
                                                                                    fill="#DEDEDE" />
                                                                                <path
                                                                                    d="M7.232 8.152C7.20533 7.97067 7.21067 7.80533 7.248 7.656C7.29067 7.50667 7.34933 7.368 7.424 7.24C7.49867 7.112 7.58133 6.99467 7.672 6.888C7.768 6.78133 7.85867 6.68 7.944 6.584C8.02933 6.48267 8.09867 6.38667 8.152 6.296C8.21067 6.2 8.24 6.10133 8.24 6C8.24 5.83467 8.18933 5.70933 8.088 5.624C7.992 5.53867 7.864 5.496 7.704 5.496C7.55467 5.496 7.42133 5.53067 7.304 5.6C7.192 5.66933 7.08267 5.75733 6.976 5.864L6.328 5.272C6.51467 5.05333 6.736 4.87733 6.992 4.744C7.25333 4.61067 7.54133 4.544 7.856 4.544C8.06933 4.544 8.26667 4.57067 8.448 4.624C8.63467 4.67733 8.79467 4.76 8.928 4.872C9.06667 4.984 9.17333 5.128 9.248 5.304C9.328 5.47467 9.368 5.67733 9.368 5.912C9.368 6.07733 9.33867 6.22667 9.28 6.36C9.22133 6.488 9.14667 6.608 9.056 6.72C8.97067 6.832 8.87733 6.94133 8.776 7.048C8.68 7.14933 8.58933 7.256 8.504 7.368C8.424 7.48 8.36 7.6 8.312 7.728C8.264 7.85067 8.248 7.992 8.264 8.152H7.232ZM7.744 10.096C7.54133 10.096 7.37333 10.0267 7.24 9.888C7.10667 9.744 7.04 9.568 7.04 9.36C7.04 9.14667 7.10667 8.97067 7.24 8.832C7.37333 8.688 7.54133 8.616 7.744 8.616C7.94667 8.616 8.11467 8.688 8.248 8.832C8.38667 8.97067 8.456 9.14667 8.456 9.36C8.456 9.568 8.38667 9.744 8.248 9.888C8.11467 10.0267 7.94667 10.096 7.744 10.096Z"
                                                                                    fill="#4D5464" />
                                                                            </svg>
                                                                        </Form.Label>
                                                                        <Form.Select name="mainCategory" id="mainCategory" aria-label="Default select example">
                                                                            <option value="Burgers">Burgers</option>
                                                                            <option value="Pizza">Pizza</option>
                                                                            <option value="Bevrages">Bevrages</option>
                                                                        </Form.Select>
                                                                    </Form.Group>
                                                                    <Form.Group className="div_from div_select">
                                                                        <Form.Label>Additional Category
                                                                            <svg width="15" height="15"
                                                                                viewBox="0 0 15 15" fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg">
                                                                                <rect width="15" height="15" rx="7.5"
                                                                                    fill="#DEDEDE" />
                                                                                <path
                                                                                    d="M7.232 8.152C7.20533 7.97067 7.21067 7.80533 7.248 7.656C7.29067 7.50667 7.34933 7.368 7.424 7.24C7.49867 7.112 7.58133 6.99467 7.672 6.888C7.768 6.78133 7.85867 6.68 7.944 6.584C8.02933 6.48267 8.09867 6.38667 8.152 6.296C8.21067 6.2 8.24 6.10133 8.24 6C8.24 5.83467 8.18933 5.70933 8.088 5.624C7.992 5.53867 7.864 5.496 7.704 5.496C7.55467 5.496 7.42133 5.53067 7.304 5.6C7.192 5.66933 7.08267 5.75733 6.976 5.864L6.328 5.272C6.51467 5.05333 6.736 4.87733 6.992 4.744C7.25333 4.61067 7.54133 4.544 7.856 4.544C8.06933 4.544 8.26667 4.57067 8.448 4.624C8.63467 4.67733 8.79467 4.76 8.928 4.872C9.06667 4.984 9.17333 5.128 9.248 5.304C9.328 5.47467 9.368 5.67733 9.368 5.912C9.368 6.07733 9.33867 6.22667 9.28 6.36C9.22133 6.488 9.14667 6.608 9.056 6.72C8.97067 6.832 8.87733 6.94133 8.776 7.048C8.68 7.14933 8.58933 7.256 8.504 7.368C8.424 7.48 8.36 7.6 8.312 7.728C8.264 7.85067 8.248 7.992 8.264 8.152H7.232ZM7.744 10.096C7.54133 10.096 7.37333 10.0267 7.24 9.888C7.10667 9.744 7.04 9.568 7.04 9.36C7.04 9.14667 7.10667 8.97067 7.24 8.832C7.37333 8.688 7.54133 8.616 7.744 8.616C7.94667 8.616 8.11467 8.688 8.248 8.832C8.38667 8.97067 8.456 9.14667 8.456 9.36C8.456 9.568 8.38667 9.744 8.248 9.888C8.11467 10.0267 7.94667 10.096 7.744 10.096Z"
                                                                                    fill="#4D5464" />
                                                                            </svg>
                                                                        </Form.Label>
                                                                        <Form.Select name="additionalCategory" id="additionalCategory" aria-label="Default select example">
                                                                            <option value="Chicken Burger">Chicken Burger</option>
                                                                            <option value="Beef Burger">Beef Burger</option>
                                                                            <option value="Veggie Burger">Veggie Burger</option>
                                                                        </Form.Select>
                                                                    </Form.Group>
                                                                    <Form.Group className="div_from">
                                                                        <Form.Label>Disable Modifier Popup
                                                                            <svg width="15" height="15"
                                                                                viewBox="0 0 15 15" fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg">
                                                                                <rect width="15" height="15" rx="7.5"
                                                                                    fill="#DEDEDE" />
                                                                                <path
                                                                                    d="M7.232 8.152C7.20533 7.97067 7.21067 7.80533 7.248 7.656C7.29067 7.50667 7.34933 7.368 7.424 7.24C7.49867 7.112 7.58133 6.99467 7.672 6.888C7.768 6.78133 7.85867 6.68 7.944 6.584C8.02933 6.48267 8.09867 6.38667 8.152 6.296C8.21067 6.2 8.24 6.10133 8.24 6C8.24 5.83467 8.18933 5.70933 8.088 5.624C7.992 5.53867 7.864 5.496 7.704 5.496C7.55467 5.496 7.42133 5.53067 7.304 5.6C7.192 5.66933 7.08267 5.75733 6.976 5.864L6.328 5.272C6.51467 5.05333 6.736 4.87733 6.992 4.744C7.25333 4.61067 7.54133 4.544 7.856 4.544C8.06933 4.544 8.26667 4.57067 8.448 4.624C8.63467 4.67733 8.79467 4.76 8.928 4.872C9.06667 4.984 9.17333 5.128 9.248 5.304C9.328 5.47467 9.368 5.67733 9.368 5.912C9.368 6.07733 9.33867 6.22667 9.28 6.36C9.22133 6.488 9.14667 6.608 9.056 6.72C8.97067 6.832 8.87733 6.94133 8.776 7.048C8.68 7.14933 8.58933 7.256 8.504 7.368C8.424 7.48 8.36 7.6 8.312 7.728C8.264 7.85067 8.248 7.992 8.264 8.152H7.232ZM7.744 10.096C7.54133 10.096 7.37333 10.0267 7.24 9.888C7.10667 9.744 7.04 9.568 7.04 9.36C7.04 9.14667 7.10667 8.97067 7.24 8.832C7.37333 8.688 7.54133 8.616 7.744 8.616C7.94667 8.616 8.11467 8.688 8.248 8.832C8.38667 8.97067 8.456 9.14667 8.456 9.36C8.456 9.568 8.38667 9.744 8.248 9.888C8.11467 10.0267 7.94667 10.096 7.744 10.096Z"
                                                                                    fill="#4D5464" />
                                                                            </svg>
                                                                        </Form.Label>
                                                                        <Form.Check name="disableModifierPopup" id="disableModifierPopup" aria-label="Disable Modifier Popup" />
                                                                    </Form.Group>

                                                                </Col>
                                                                <Col md={5} className="m-l-50">
                                                                    <Form.Group className="div_from">
                                                                        <Form.Label>Printers
                                                                            <svg width="15" height="15"
                                                                                viewBox="0 0 15 15" fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg">
                                                                                <rect width="15" height="15" rx="7.5"
                                                                                    fill="#DEDEDE" />
                                                                                <path
                                                                                    d="M7.232 8.152C7.20533 7.97067 7.21067 7.80533 7.248 7.656C7.29067 7.50667 7.34933 7.368 7.424 7.24C7.49867 7.112 7.58133 6.99467 7.672 6.888C7.768 6.78133 7.85867 6.68 7.944 6.584C8.02933 6.48267 8.09867 6.38667 8.152 6.296C8.21067 6.2 8.24 6.10133 8.24 6C8.24 5.83467 8.18933 5.70933 8.088 5.624C7.992 5.53867 7.864 5.496 7.704 5.496C7.55467 5.496 7.42133 5.53067 7.304 5.6C7.192 5.66933 7.08267 5.75733 6.976 5.864L6.328 5.272C6.51467 5.05333 6.736 4.87733 6.992 4.744C7.25333 4.61067 7.54133 4.544 7.856 4.544C8.06933 4.544 8.26667 4.57067 8.448 4.624C8.63467 4.67733 8.79467 4.76 8.928 4.872C9.06667 4.984 9.17333 5.128 9.248 5.304C9.328 5.47467 9.368 5.67733 9.368 5.912C9.368 6.07733 9.33867 6.22667 9.28 6.36C9.22133 6.488 9.14667 6.608 9.056 6.72C8.97067 6.832 8.87733 6.94133 8.776 7.048C8.68 7.14933 8.58933 7.256 8.504 7.368C8.424 7.48 8.36 7.6 8.312 7.728C8.264 7.85067 8.248 7.992 8.264 8.152H7.232ZM7.744 10.096C7.54133 10.096 7.37333 10.0267 7.24 9.888C7.10667 9.744 7.04 9.568 7.04 9.36C7.04 9.14667 7.10667 8.97067 7.24 8.832C7.37333 8.688 7.54133 8.616 7.744 8.616C7.94667 8.616 8.11467 8.688 8.248 8.832C8.38667 8.97067 8.456 9.14667 8.456 9.36C8.456 9.568 8.38667 9.744 8.248 9.888C8.11467 10.0267 7.94667 10.096 7.744 10.096Z"
                                                                                    fill="#4D5464" />
                                                                            </svg>
                                                                        </Form.Label>
                                                                        <Form.Control type="text" name="printers" id="printers" placeholder="Kitchen 01"/>
                                                                    </Form.Group>
                                                                    <Form.Group className="div_from">
                                                                        <Form.Label>Kitchen Description
                                                                            <svg width="15" height="15"
                                                                                viewBox="0 0 15 15" fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg">
                                                                                <rect width="15" height="15" rx="7.5"
                                                                                    fill="#DEDEDE" />
                                                                                <path
                                                                                    d="M7.232 8.152C7.20533 7.97067 7.21067 7.80533 7.248 7.656C7.29067 7.50667 7.34933 7.368 7.424 7.24C7.49867 7.112 7.58133 6.99467 7.672 6.888C7.768 6.78133 7.85867 6.68 7.944 6.584C8.02933 6.48267 8.09867 6.38667 8.152 6.296C8.21067 6.2 8.24 6.10133 8.24 6C8.24 5.83467 8.18933 5.70933 8.088 5.624C7.992 5.53867 7.864 5.496 7.704 5.496C7.55467 5.496 7.42133 5.53067 7.304 5.6C7.192 5.66933 7.08267 5.75733 6.976 5.864L6.328 5.272C6.51467 5.05333 6.736 4.87733 6.992 4.744C7.25333 4.61067 7.54133 4.544 7.856 4.544C8.06933 4.544 8.26667 4.57067 8.448 4.624C8.63467 4.67733 8.79467 4.76 8.928 4.872C9.06667 4.984 9.17333 5.128 9.248 5.304C9.328 5.47467 9.368 5.67733 9.368 5.912C9.368 6.07733 9.33867 6.22667 9.28 6.36C9.22133 6.488 9.14667 6.608 9.056 6.72C8.97067 6.832 8.87733 6.94133 8.776 7.048C8.68 7.14933 8.58933 7.256 8.504 7.368C8.424 7.48 8.36 7.6 8.312 7.728C8.264 7.85067 8.248 7.992 8.264 8.152H7.232ZM7.744 10.096C7.54133 10.096 7.37333 10.0267 7.24 9.888C7.10667 9.744 7.04 9.568 7.04 9.36C7.04 9.14667 7.10667 8.97067 7.24 8.832C7.37333 8.688 7.54133 8.616 7.744 8.616C7.94667 8.616 8.11467 8.688 8.248 8.832C8.38667 8.97067 8.456 9.14667 8.456 9.36C8.456 9.568 8.38667 9.744 8.248 9.888C8.11467 10.0267 7.94667 10.096 7.744 10.096Z"
                                                                                    fill="#4D5464" />
                                                                            </svg>
                                                                        </Form.Label>
                                                                        <Form.Control type="text" name="kitchenDescription" id="kitchenDescription" placeholder="Kitchen 01"/>
                                                                    </Form.Group>
                                                                    <Form.Group className="div_from">
                                                                        <Form.Label>Price Tag
                                                                            <svg width="15" height="15"
                                                                                viewBox="0 0 15 15" fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg">
                                                                                <rect width="15" height="15" rx="7.5"
                                                                                    fill="#DEDEDE" />
                                                                                <path
                                                                                    d="M7.232 8.152C7.20533 7.97067 7.21067 7.80533 7.248 7.656C7.29067 7.50667 7.34933 7.368 7.424 7.24C7.49867 7.112 7.58133 6.99467 7.672 6.888C7.768 6.78133 7.85867 6.68 7.944 6.584C8.02933 6.48267 8.09867 6.38667 8.152 6.296C8.21067 6.2 8.24 6.10133 8.24 6C8.24 5.83467 8.18933 5.70933 8.088 5.624C7.992 5.53867 7.864 5.496 7.704 5.496C7.55467 5.496 7.42133 5.53067 7.304 5.6C7.192 5.66933 7.08267 5.75733 6.976 5.864L6.328 5.272C6.51467 5.05333 6.736 4.87733 6.992 4.744C7.25333 4.61067 7.54133 4.544 7.856 4.544C8.06933 4.544 8.26667 4.57067 8.448 4.624C8.63467 4.67733 8.79467 4.76 8.928 4.872C9.06667 4.984 9.17333 5.128 9.248 5.304C9.328 5.47467 9.368 5.67733 9.368 5.912C9.368 6.07733 9.33867 6.22667 9.28 6.36C9.22133 6.488 9.14667 6.608 9.056 6.72C8.97067 6.832 8.87733 6.94133 8.776 7.048C8.68 7.14933 8.58933 7.256 8.504 7.368C8.424 7.48 8.36 7.6 8.312 7.728C8.264 7.85067 8.248 7.992 8.264 8.152H7.232ZM7.744 10.096C7.54133 10.096 7.37333 10.0267 7.24 9.888C7.10667 9.744 7.04 9.568 7.04 9.36C7.04 9.14667 7.10667 8.97067 7.24 8.832C7.37333 8.688 7.54133 8.616 7.744 8.616C7.94667 8.616 8.11467 8.688 8.248 8.832C8.38667 8.97067 8.456 9.14667 8.456 9.36C8.456 9.568 8.38667 9.744 8.248 9.888C8.11467 10.0267 7.94667 10.096 7.744 10.096Z"
                                                                                    fill="#4D5464" />
                                                                            </svg>
                                                                        </Form.Label>
                                                                        <Form.Check name="priceTag" id="priceTag" aria-label="Price Tag Activation" />
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col md={8}>
                                                                    <Button type="submit" className="btn_submit">
                                                                        Update
                                                                    </Button>
                                                                    <Button type="button" className="btn_submit">
                                                                        Save
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </Form>
                                                    </div>
                                                </TabPanel>
                                                <TabPanel>
                                                    <div className="tab_froms">
                                                        <Form className="">
                                                            <Row>
                                                                <Col md={5}>
                                                                    <Form.Group className="div_from div_select">
                                                                        <Form.Label>Category
                                                                            <svg width="15" height="15"
                                                                                viewBox="0 0 15 15" fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg">
                                                                                <rect width="15" height="15" rx="7.5"
                                                                                    fill="#DEDEDE" />
                                                                                <path
                                                                                    d="M7.232 8.152C7.20533 7.97067 7.21067 7.80533 7.248 7.656C7.29067 7.50667 7.34933 7.368 7.424 7.24C7.49867 7.112 7.58133 6.99467 7.672 6.888C7.768 6.78133 7.85867 6.68 7.944 6.584C8.02933 6.48267 8.09867 6.38667 8.152 6.296C8.21067 6.2 8.24 6.10133 8.24 6C8.24 5.83467 8.18933 5.70933 8.088 5.624C7.992 5.53867 7.864 5.496 7.704 5.496C7.55467 5.496 7.42133 5.53067 7.304 5.6C7.192 5.66933 7.08267 5.75733 6.976 5.864L6.328 5.272C6.51467 5.05333 6.736 4.87733 6.992 4.744C7.25333 4.61067 7.54133 4.544 7.856 4.544C8.06933 4.544 8.26667 4.57067 8.448 4.624C8.63467 4.67733 8.79467 4.76 8.928 4.872C9.06667 4.984 9.17333 5.128 9.248 5.304C9.328 5.47467 9.368 5.67733 9.368 5.912C9.368 6.07733 9.33867 6.22667 9.28 6.36C9.22133 6.488 9.14667 6.608 9.056 6.72C8.97067 6.832 8.87733 6.94133 8.776 7.048C8.68 7.14933 8.58933 7.256 8.504 7.368C8.424 7.48 8.36 7.6 8.312 7.728C8.264 7.85067 8.248 7.992 8.264 8.152H7.232ZM7.744 10.096C7.54133 10.096 7.37333 10.0267 7.24 9.888C7.10667 9.744 7.04 9.568 7.04 9.36C7.04 9.14667 7.10667 8.97067 7.24 8.832C7.37333 8.688 7.54133 8.616 7.744 8.616C7.94667 8.616 8.11467 8.688 8.248 8.832C8.38667 8.97067 8.456 9.14667 8.456 9.36C8.456 9.568 8.38667 9.744 8.248 9.888C8.11467 10.0267 7.94667 10.096 7.744 10.096Z"
                                                                                    fill="#4D5464" />
                                                                            </svg>
                                                                        </Form.Label>
                                                                        <Form.Control type="text" name="category" id="category" placeholder="Burgers" />
                                                                    </Form.Group>
                                                                    <Form.Group className="div_from div_select">
                                                                        <Form.Label>Sub Category
                                                                            <svg width="15" height="15"
                                                                                viewBox="0 0 15 15" fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg">
                                                                                <rect width="15" height="15" rx="7.5"
                                                                                    fill="#DEDEDE" />
                                                                                <path
                                                                                    d="M7.232 8.152C7.20533 7.97067 7.21067 7.80533 7.248 7.656C7.29067 7.50667 7.34933 7.368 7.424 7.24C7.49867 7.112 7.58133 6.99467 7.672 6.888C7.768 6.78133 7.85867 6.68 7.944 6.584C8.02933 6.48267 8.09867 6.38667 8.152 6.296C8.21067 6.2 8.24 6.10133 8.24 6C8.24 5.83467 8.18933 5.70933 8.088 5.624C7.992 5.53867 7.864 5.496 7.704 5.496C7.55467 5.496 7.42133 5.53067 7.304 5.6C7.192 5.66933 7.08267 5.75733 6.976 5.864L6.328 5.272C6.51467 5.05333 6.736 4.87733 6.992 4.744C7.25333 4.61067 7.54133 4.544 7.856 4.544C8.06933 4.544 8.26667 4.57067 8.448 4.624C8.63467 4.67733 8.79467 4.76 8.928 4.872C9.06667 4.984 9.17333 5.128 9.248 5.304C9.328 5.47467 9.368 5.67733 9.368 5.912C9.368 6.07733 9.33867 6.22667 9.28 6.36C9.22133 6.488 9.14667 6.608 9.056 6.72C8.97067 6.832 8.87733 6.94133 8.776 7.048C8.68 7.14933 8.58933 7.256 8.504 7.368C8.424 7.48 8.36 7.6 8.312 7.728C8.264 7.85067 8.248 7.992 8.264 8.152H7.232ZM7.744 10.096C7.54133 10.096 7.37333 10.0267 7.24 9.888C7.10667 9.744 7.04 9.568 7.04 9.36C7.04 9.14667 7.10667 8.97067 7.24 8.832C7.37333 8.688 7.54133 8.616 7.744 8.616C7.94667 8.616 8.11467 8.688 8.248 8.832C8.38667 8.97067 8.456 9.14667 8.456 9.36C8.456 9.568 8.38667 9.744 8.248 9.888C8.11467 10.0267 7.94667 10.096 7.744 10.096Z"
                                                                                    fill="#4D5464" />
                                                                            </svg>
                                                                        </Form.Label>
                                                                        <Form.Control type="text" name="subCategory" id="subCategory" placeholder="Beef Burgers" />
                                                                    </Form.Group>
                                                                    <Form.Group className="div_from">
                                                                        <Form.Label>Pricing Options
                                                                            <svg width="15" height="15"
                                                                                viewBox="0 0 15 15" fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg">
                                                                                <rect width="15" height="15" rx="7.5"
                                                                                    fill="#DEDEDE" />
                                                                                <path
                                                                                    d="M7.232 8.152C7.20533 7.97067 7.21067 7.80533 7.248 7.656C7.29067 7.50667 7.34933 7.368 7.424 7.24C7.49867 7.112 7.58133 6.99467 7.672 6.888C7.768 6.78133 7.85867 6.68 7.944 6.584C8.02933 6.48267 8.09867 6.38667 8.152 6.296C8.21067 6.2 8.24 6.10133 8.24 6C8.24 5.83467 8.18933 5.70933 8.088 5.624C7.992 5.53867 7.864 5.496 7.704 5.496C7.55467 5.496 7.42133 5.53067 7.304 5.6C7.192 5.66933 7.08267 5.75733 6.976 5.864L6.328 5.272C6.51467 5.05333 6.736 4.87733 6.992 4.744C7.25333 4.61067 7.54133 4.544 7.856 4.544C8.06933 4.544 8.26667 4.57067 8.448 4.624C8.63467 4.67733 8.79467 4.76 8.928 4.872C9.06667 4.984 9.17333 5.128 9.248 5.304C9.328 5.47467 9.368 5.67733 9.368 5.912C9.368 6.07733 9.33867 6.22667 9.28 6.36C9.22133 6.488 9.14667 6.608 9.056 6.72C8.97067 6.832 8.87733 6.94133 8.776 7.048C8.68 7.14933 8.58933 7.256 8.504 7.368C8.424 7.48 8.36 7.6 8.312 7.728C8.264 7.85067 8.248 7.992 8.264 8.152H7.232ZM7.744 10.096C7.54133 10.096 7.37333 10.0267 7.24 9.888C7.10667 9.744 7.04 9.568 7.04 9.36C7.04 9.14667 7.10667 8.97067 7.24 8.832C7.37333 8.688 7.54133 8.616 7.744 8.616C7.94667 8.616 8.11467 8.688 8.248 8.832C8.38667 8.97067 8.456 9.14667 8.456 9.36C8.456 9.568 8.38667 9.744 8.248 9.888C8.11467 10.0267 7.94667 10.096 7.744 10.096Z"
                                                                                    fill="#4D5464" />
                                                                            </svg>
                                                                        </Form.Label>
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col md={5} className="m-l-50">
                                                                    <Form.Group className="div_from">
                                                                        <Form.Label>Printers
                                                                            <svg width="15" height="15"
                                                                                viewBox="0 0 15 15" fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg">
                                                                                <rect width="15" height="15" rx="7.5"
                                                                                    fill="#DEDEDE" />
                                                                                <path
                                                                                    d="M7.232 8.152C7.20533 7.97067 7.21067 7.80533 7.248 7.656C7.29067 7.50667 7.34933 7.368 7.424 7.24C7.49867 7.112 7.58133 6.99467 7.672 6.888C7.768 6.78133 7.85867 6.68 7.944 6.584C8.02933 6.48267 8.09867 6.38667 8.152 6.296C8.21067 6.2 8.24 6.10133 8.24 6C8.24 5.83467 8.18933 5.70933 8.088 5.624C7.992 5.53867 7.864 5.496 7.704 5.496C7.55467 5.496 7.42133 5.53067 7.304 5.6C7.192 5.66933 7.08267 5.75733 6.976 5.864L6.328 5.272C6.51467 5.05333 6.736 4.87733 6.992 4.744C7.25333 4.61067 7.54133 4.544 7.856 4.544C8.06933 4.544 8.26667 4.57067 8.448 4.624C8.63467 4.67733 8.79467 4.76 8.928 4.872C9.06667 4.984 9.17333 5.128 9.248 5.304C9.328 5.47467 9.368 5.67733 9.368 5.912C9.368 6.07733 9.33867 6.22667 9.28 6.36C9.22133 6.488 9.14667 6.608 9.056 6.72C8.97067 6.832 8.87733 6.94133 8.776 7.048C8.68 7.14933 8.58933 7.256 8.504 7.368C8.424 7.48 8.36 7.6 8.312 7.728C8.264 7.85067 8.248 7.992 8.264 8.152H7.232ZM7.744 10.096C7.54133 10.096 7.37333 10.0267 7.24 9.888C7.10667 9.744 7.04 9.568 7.04 9.36C7.04 9.14667 7.10667 8.97067 7.24 8.832C7.37333 8.688 7.54133 8.616 7.744 8.616C7.94667 8.616 8.11467 8.688 8.248 8.832C8.38667 8.97067 8.456 9.14667 8.456 9.36C8.456 9.568 8.38667 9.744 8.248 9.888C8.11467 10.0267 7.94667 10.096 7.744 10.096Z"
                                                                                    fill="#4D5464" />
                                                                            </svg>
                                                                        </Form.Label>
                                                                        <Form.Control type="text" name="printers" id="printers" placeholder="Kitchen 01"/>
                                                                    </Form.Group>
                                                                    <Form.Group className="div_from">
                                                                        <Form.Label>Kitchen Description
                                                                            <svg width="15" height="15"
                                                                                viewBox="0 0 15 15" fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg">
                                                                                <rect width="15" height="15" rx="7.5"
                                                                                    fill="#DEDEDE" />
                                                                                <path
                                                                                    d="M7.232 8.152C7.20533 7.97067 7.21067 7.80533 7.248 7.656C7.29067 7.50667 7.34933 7.368 7.424 7.24C7.49867 7.112 7.58133 6.99467 7.672 6.888C7.768 6.78133 7.85867 6.68 7.944 6.584C8.02933 6.48267 8.09867 6.38667 8.152 6.296C8.21067 6.2 8.24 6.10133 8.24 6C8.24 5.83467 8.18933 5.70933 8.088 5.624C7.992 5.53867 7.864 5.496 7.704 5.496C7.55467 5.496 7.42133 5.53067 7.304 5.6C7.192 5.66933 7.08267 5.75733 6.976 5.864L6.328 5.272C6.51467 5.05333 6.736 4.87733 6.992 4.744C7.25333 4.61067 7.54133 4.544 7.856 4.544C8.06933 4.544 8.26667 4.57067 8.448 4.624C8.63467 4.67733 8.79467 4.76 8.928 4.872C9.06667 4.984 9.17333 5.128 9.248 5.304C9.328 5.47467 9.368 5.67733 9.368 5.912C9.368 6.07733 9.33867 6.22667 9.28 6.36C9.22133 6.488 9.14667 6.608 9.056 6.72C8.97067 6.832 8.87733 6.94133 8.776 7.048C8.68 7.14933 8.58933 7.256 8.504 7.368C8.424 7.48 8.36 7.6 8.312 7.728C8.264 7.85067 8.248 7.992 8.264 8.152H7.232ZM7.744 10.096C7.54133 10.096 7.37333 10.0267 7.24 9.888C7.10667 9.744 7.04 9.568 7.04 9.36C7.04 9.14667 7.10667 8.97067 7.24 8.832C7.37333 8.688 7.54133 8.616 7.744 8.616C7.94667 8.616 8.11467 8.688 8.248 8.832C8.38667 8.97067 8.456 9.14667 8.456 9.36C8.456 9.568 8.38667 9.744 8.248 9.888C8.11467 10.0267 7.94667 10.096 7.744 10.096Z"
                                                                                    fill="#4D5464" />
                                                                            </svg>
                                                                        </Form.Label>
                                                                        <Form.Control type="text" name="kitchenDescription" id="kitchenDescription" placeholder="Kitchen 01"/>
                                                                    </Form.Group>
                                                                    <Form.Group className="div_from div_select">
                                                                        <Form.Label>Meal Type
                                                                            <svg width="15" height="15"
                                                                                viewBox="0 0 15 15" fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg">
                                                                                <rect width="15" height="15" rx="7.5"
                                                                                    fill="#DEDEDE" />
                                                                                <path
                                                                                    d="M7.232 8.152C7.20533 7.97067 7.21067 7.80533 7.248 7.656C7.29067 7.50667 7.34933 7.368 7.424 7.24C7.49867 7.112 7.58133 6.99467 7.672 6.888C7.768 6.78133 7.85867 6.68 7.944 6.584C8.02933 6.48267 8.09867 6.38667 8.152 6.296C8.21067 6.2 8.24 6.10133 8.24 6C8.24 5.83467 8.18933 5.70933 8.088 5.624C7.992 5.53867 7.864 5.496 7.704 5.496C7.55467 5.496 7.42133 5.53067 7.304 5.6C7.192 5.66933 7.08267 5.75733 6.976 5.864L6.328 5.272C6.51467 5.05333 6.736 4.87733 6.992 4.744C7.25333 4.61067 7.54133 4.544 7.856 4.544C8.06933 4.544 8.26667 4.57067 8.448 4.624C8.63467 4.67733 8.79467 4.76 8.928 4.872C9.06667 4.984 9.17333 5.128 9.248 5.304C9.328 5.47467 9.368 5.67733 9.368 5.912C9.368 6.07733 9.33867 6.22667 9.28 6.36C9.22133 6.488 9.14667 6.608 9.056 6.72C8.97067 6.832 8.87733 6.94133 8.776 7.048C8.68 7.14933 8.58933 7.256 8.504 7.368C8.424 7.48 8.36 7.6 8.312 7.728C8.264 7.85067 8.248 7.992 8.264 8.152H7.232ZM7.744 10.096C7.54133 10.096 7.37333 10.0267 7.24 9.888C7.10667 9.744 7.04 9.568 7.04 9.36C7.04 9.14667 7.10667 8.97067 7.24 8.832C7.37333 8.688 7.54133 8.616 7.744 8.616C7.94667 8.616 8.11467 8.688 8.248 8.832C8.38667 8.97067 8.456 9.14667 8.456 9.36C8.456 9.568 8.38667 9.744 8.248 9.888C8.11467 10.0267 7.94667 10.096 7.744 10.096Z"
                                                                                    fill="#4D5464" />
                                                                            </svg>
                                                                        </Form.Label>
                                                                        <Form.Select name="mealType" id="mealType" aria-label="Default select example">
                                                                            <option value="Combo">Combo</option>
                                                                            <option value="Single">Single Meal</option>
                                                                            <option value="Family">Family Meal</option>
                                                                        </Form.Select>
                                                                    </Form.Group>
                                                                    <Form.Group className="div_from div_select">
                                                                        <Form.Label>Combo Items
                                                                            <svg width="15" height="15"
                                                                                viewBox="0 0 15 15" fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg">
                                                                                <rect width="15" height="15" rx="7.5"
                                                                                    fill="#DEDEDE" />
                                                                                <path
                                                                                    d="M7.232 8.152C7.20533 7.97067 7.21067 7.80533 7.248 7.656C7.29067 7.50667 7.34933 7.368 7.424 7.24C7.49867 7.112 7.58133 6.99467 7.672 6.888C7.768 6.78133 7.85867 6.68 7.944 6.584C8.02933 6.48267 8.09867 6.38667 8.152 6.296C8.21067 6.2 8.24 6.10133 8.24 6C8.24 5.83467 8.18933 5.70933 8.088 5.624C7.992 5.53867 7.864 5.496 7.704 5.496C7.55467 5.496 7.42133 5.53067 7.304 5.6C7.192 5.66933 7.08267 5.75733 6.976 5.864L6.328 5.272C6.51467 5.05333 6.736 4.87733 6.992 4.744C7.25333 4.61067 7.54133 4.544 7.856 4.544C8.06933 4.544 8.26667 4.57067 8.448 4.624C8.63467 4.67733 8.79467 4.76 8.928 4.872C9.06667 4.984 9.17333 5.128 9.248 5.304C9.328 5.47467 9.368 5.67733 9.368 5.912C9.368 6.07733 9.33867 6.22667 9.28 6.36C9.22133 6.488 9.14667 6.608 9.056 6.72C8.97067 6.832 8.87733 6.94133 8.776 7.048C8.68 7.14933 8.58933 7.256 8.504 7.368C8.424 7.48 8.36 7.6 8.312 7.728C8.264 7.85067 8.248 7.992 8.264 8.152H7.232ZM7.744 10.096C7.54133 10.096 7.37333 10.0267 7.24 9.888C7.10667 9.744 7.04 9.568 7.04 9.36C7.04 9.14667 7.10667 8.97067 7.24 8.832C7.37333 8.688 7.54133 8.616 7.744 8.616C7.94667 8.616 8.11467 8.688 8.248 8.832C8.38667 8.97067 8.456 9.14667 8.456 9.36C8.456 9.568 8.38667 9.744 8.248 9.888C8.11467 10.0267 7.94667 10.096 7.744 10.096Z"
                                                                                    fill="#4D5464" />
                                                                            </svg>
                                                                        </Form.Label>
                                                                        <Form.Select name="comboItems" id="comboItems" aria-label="Default select example">
                                                                            <option value="Beef Dhamaka Burger + Pepsi + Regular Fries">Beef Dhamaka Burger + Pepsi + Regular Fries</option>
                                                                            <option value="Beef Dhamaka Burger + Pepsi">Beef Dhamaka Burger + Pepsi</option>
                                                                            <option value="Pepsi + Regular Fries">Pepsi + Regular Fries</option>
                                                                        </Form.Select>
                                                                    </Form.Group>
                                                                    <Form.Group className="div_from">
                                                                        <Form.Label>Price Tag
                                                                            <svg width="15" height="15"
                                                                                viewBox="0 0 15 15" fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg">
                                                                                <rect width="15" height="15" rx="7.5"
                                                                                    fill="#DEDEDE" />
                                                                                <path
                                                                                    d="M7.232 8.152C7.20533 7.97067 7.21067 7.80533 7.248 7.656C7.29067 7.50667 7.34933 7.368 7.424 7.24C7.49867 7.112 7.58133 6.99467 7.672 6.888C7.768 6.78133 7.85867 6.68 7.944 6.584C8.02933 6.48267 8.09867 6.38667 8.152 6.296C8.21067 6.2 8.24 6.10133 8.24 6C8.24 5.83467 8.18933 5.70933 8.088 5.624C7.992 5.53867 7.864 5.496 7.704 5.496C7.55467 5.496 7.42133 5.53067 7.304 5.6C7.192 5.66933 7.08267 5.75733 6.976 5.864L6.328 5.272C6.51467 5.05333 6.736 4.87733 6.992 4.744C7.25333 4.61067 7.54133 4.544 7.856 4.544C8.06933 4.544 8.26667 4.57067 8.448 4.624C8.63467 4.67733 8.79467 4.76 8.928 4.872C9.06667 4.984 9.17333 5.128 9.248 5.304C9.328 5.47467 9.368 5.67733 9.368 5.912C9.368 6.07733 9.33867 6.22667 9.28 6.36C9.22133 6.488 9.14667 6.608 9.056 6.72C8.97067 6.832 8.87733 6.94133 8.776 7.048C8.68 7.14933 8.58933 7.256 8.504 7.368C8.424 7.48 8.36 7.6 8.312 7.728C8.264 7.85067 8.248 7.992 8.264 8.152H7.232ZM7.744 10.096C7.54133 10.096 7.37333 10.0267 7.24 9.888C7.10667 9.744 7.04 9.568 7.04 9.36C7.04 9.14667 7.10667 8.97067 7.24 8.832C7.37333 8.688 7.54133 8.616 7.744 8.616C7.94667 8.616 8.11467 8.688 8.248 8.832C8.38667 8.97067 8.456 9.14667 8.456 9.36C8.456 9.568 8.38667 9.744 8.248 9.888C8.11467 10.0267 7.94667 10.096 7.744 10.096Z"
                                                                                    fill="#4D5464" />
                                                                            </svg>
                                                                        </Form.Label>
                                                                        <Form.Check name="priceTag" id="priceTag" aria-label="Price Tag Activation" />
                                                                    </Form.Group>
                                                                    <Form.Group className="div_from">
                                                                        <Form.Label>Disable Modifier Popup
                                                                            <svg width="15" height="15"
                                                                                viewBox="0 0 15 15" fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg">
                                                                                <rect width="15" height="15" rx="7.5"
                                                                                    fill="#DEDEDE" />
                                                                                <path
                                                                                    d="M7.232 8.152C7.20533 7.97067 7.21067 7.80533 7.248 7.656C7.29067 7.50667 7.34933 7.368 7.424 7.24C7.49867 7.112 7.58133 6.99467 7.672 6.888C7.768 6.78133 7.85867 6.68 7.944 6.584C8.02933 6.48267 8.09867 6.38667 8.152 6.296C8.21067 6.2 8.24 6.10133 8.24 6C8.24 5.83467 8.18933 5.70933 8.088 5.624C7.992 5.53867 7.864 5.496 7.704 5.496C7.55467 5.496 7.42133 5.53067 7.304 5.6C7.192 5.66933 7.08267 5.75733 6.976 5.864L6.328 5.272C6.51467 5.05333 6.736 4.87733 6.992 4.744C7.25333 4.61067 7.54133 4.544 7.856 4.544C8.06933 4.544 8.26667 4.57067 8.448 4.624C8.63467 4.67733 8.79467 4.76 8.928 4.872C9.06667 4.984 9.17333 5.128 9.248 5.304C9.328 5.47467 9.368 5.67733 9.368 5.912C9.368 6.07733 9.33867 6.22667 9.28 6.36C9.22133 6.488 9.14667 6.608 9.056 6.72C8.97067 6.832 8.87733 6.94133 8.776 7.048C8.68 7.14933 8.58933 7.256 8.504 7.368C8.424 7.48 8.36 7.6 8.312 7.728C8.264 7.85067 8.248 7.992 8.264 8.152H7.232ZM7.744 10.096C7.54133 10.096 7.37333 10.0267 7.24 9.888C7.10667 9.744 7.04 9.568 7.04 9.36C7.04 9.14667 7.10667 8.97067 7.24 8.832C7.37333 8.688 7.54133 8.616 7.744 8.616C7.94667 8.616 8.11467 8.688 8.248 8.832C8.38667 8.97067 8.456 9.14667 8.456 9.36C8.456 9.568 8.38667 9.744 8.248 9.888C8.11467 10.0267 7.94667 10.096 7.744 10.096Z"
                                                                                    fill="#4D5464" />
                                                                            </svg>
                                                                        </Form.Label>
                                                                        <Form.Check name="disableModifierPopup" id="disableModifierPopup" aria-label="Disable Modifier Popup" />
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col md={8}>
                                                                    <Button type="submit" className="btn_submit">
                                                                        Update
                                                                    </Button>
                                                                    <Button type="button" className="btn_submit">
                                                                        Cancel
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </Form>
                                                    </div>
                                                </TabPanel>
                                                <TabPanel>
                                                    <div className="tab_froms p-0 w-100">
                                                        <Form className="">
                                                            <Row>
                                                                <Col className="pl-5 pt-3 pb-3" md={5}>
                                                                    <Form.Group className="div_from">
                                                                        <Form.Label>Item Name
                                                                            <svg width="15" height="15"
                                                                                viewBox="0 0 15 15" fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg">
                                                                                <rect width="15" height="15" rx="7.5"
                                                                                    fill="#DEDEDE" />
                                                                                <path
                                                                                    d="M7.232 8.152C7.20533 7.97067 7.21067 7.80533 7.248 7.656C7.29067 7.50667 7.34933 7.368 7.424 7.24C7.49867 7.112 7.58133 6.99467 7.672 6.888C7.768 6.78133 7.85867 6.68 7.944 6.584C8.02933 6.48267 8.09867 6.38667 8.152 6.296C8.21067 6.2 8.24 6.10133 8.24 6C8.24 5.83467 8.18933 5.70933 8.088 5.624C7.992 5.53867 7.864 5.496 7.704 5.496C7.55467 5.496 7.42133 5.53067 7.304 5.6C7.192 5.66933 7.08267 5.75733 6.976 5.864L6.328 5.272C6.51467 5.05333 6.736 4.87733 6.992 4.744C7.25333 4.61067 7.54133 4.544 7.856 4.544C8.06933 4.544 8.26667 4.57067 8.448 4.624C8.63467 4.67733 8.79467 4.76 8.928 4.872C9.06667 4.984 9.17333 5.128 9.248 5.304C9.328 5.47467 9.368 5.67733 9.368 5.912C9.368 6.07733 9.33867 6.22667 9.28 6.36C9.22133 6.488 9.14667 6.608 9.056 6.72C8.97067 6.832 8.87733 6.94133 8.776 7.048C8.68 7.14933 8.58933 7.256 8.504 7.368C8.424 7.48 8.36 7.6 8.312 7.728C8.264 7.85067 8.248 7.992 8.264 8.152H7.232ZM7.744 10.096C7.54133 10.096 7.37333 10.0267 7.24 9.888C7.10667 9.744 7.04 9.568 7.04 9.36C7.04 9.14667 7.10667 8.97067 7.24 8.832C7.37333 8.688 7.54133 8.616 7.744 8.616C7.94667 8.616 8.11467 8.688 8.248 8.832C8.38667 8.97067 8.456 9.14667 8.456 9.36C8.456 9.568 8.38667 9.744 8.248 9.888C8.11467 10.0267 7.94667 10.096 7.744 10.096Z"
                                                                                    fill="#4D5464" />
                                                                            </svg>
                                                                        </Form.Label>
                                                                        <Form.Control type="text" id="itemName" name="itemName" placeholder="Beef Burger"/>
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col md={12}>
                                                                    <Table className="tableModifiers" hover responsive>
                                                                        <thead>
                                                                            <tr>
                                                                                <th className="col-md-2">Modifier#</th>
                                                                                <th className="col-md-6">Available Modifier</th>
                                                                                <th className="col-md-2">Price</th>
                                                                                <th className="col-md-1">Active</th>
                                                                                <th className="col-md-1">DFLT</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>01</td>
                                                                                <td>Modifier Name</td>
                                                                                <td>$2.00</td>
                                                                                <td>
                                                                                    <Form.Group className="inputMargin">
                                                                                        <label srOnly="Modifier Name" labelFor="activeModifier01" className="d-none">Modifier Name</label>
                                                                                        <Form.Check type="checkbox" id="activeModifier01" name="activeModifier01" />
                                                                                    </Form.Group>
                                                                                </td>
                                                                                <td>
                                                                                    <Form.Group className="inputMargin">
                                                                                        <label srOnly="Modifier Name" labelFor="dfltModifier01" className="d-none">DFLT</label>
                                                                                        <Form.Check type="checkbox" id="dfltModifier01" name="dfltModifier01" />
                                                                                    </Form.Group>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>02</td>
                                                                                <td>Modifier Name</td>
                                                                                <td>$2.00</td>
                                                                                <td>
                                                                                    <Form.Group className="inputMargin">
                                                                                        <label srOnly="Modifier Name" labelFor="activeModifier02" className="d-none">Modifier Name</label>
                                                                                        <Form.Check type="checkbox" id="activeModifier02" name="activeModifier02" checked/>
                                                                                    </Form.Group>
                                                                                </td>
                                                                                <td>
                                                                                    <Form.Group className="inputMargin">
                                                                                        <label srOnly="Modifier Name" labelFor="dfltModifier02" className="d-none">DFLT</label>
                                                                                        <Form.Check type="checkbox" id="dfltModifier02" name="dfltModifier02" checked/>
                                                                                    </Form.Group>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>03</td>
                                                                                <td>Modifier Name</td>
                                                                                <td>$2.00</td>
                                                                                <td>
                                                                                    <Form.Group className="inputMargin">
                                                                                        <label srOnly="Modifier Name" labelFor="activeModifier03" className="d-none">Modifier Name</label>
                                                                                        <Form.Check type="checkbox" id="activeModifier03" name="activeModifier03"/>
                                                                                    </Form.Group>
                                                                                </td>
                                                                                <td>
                                                                                    <Form.Group className="inputMargin">
                                                                                        <label srOnly="Modifier Name" labelFor="dfltModifier03" className="d-none">DFLT</label>
                                                                                        <Form.Check type="checkbox" id="dfltModifier03" name="dfltModifier03"/>
                                                                                    </Form.Group>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </Table>
                                                                </Col>
                                                                <Col md={8} className="pl-5 pt-3 pb-3">
                                                                    <Button type="button" className="btn btnAddModifiers">
                                                                        + Add More Modifiers
                                                                    </Button>
                                                                    <div>
                                                                        <Button type="submit" className="btn_submit">
                                                                            Update
                                                                        </Button>
                                                                        <Button type="button" className="btn_submit">
                                                                            Save
                                                                        </Button>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Form>
                                                    </div>
                                                </TabPanel>
                                                <TabPanel>
                                                    <Col md={12}>
                                                        <Table className="tableModifiers" hover responsive>
                                                            <thead>
                                                                <tr>
                                                                    <th className="col-md-6">Tax</th>
                                                                    <th className="col-md-2">Tax Group</th>
                                                                    <th className="col-md-2">Rate</th>
                                                                    <th className="col-md-2">Active</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <Form.Select className="selectTable" id="gstTaxPercent" name="gstTaxPercent">
                                                                            <option value="GST/Tax at 13%">GST/Tax at 13%</option>
                                                                            <option value="GST/Tax at 15%">GST/Tax at 15%</option>
                                                                            <option value="GST/Tax at 18%">GST/Tax at 18%</option>
                                                                        </Form.Select>
                                                                        <Button type="button" className="btn btn-AddTax">+ Add</Button>
                                                                    </td>
                                                                    <td>General Sales Tax</td>
                                                                    <td>$2.00</td>
                                                                    <td>
                                                                        <Form.Group className="inputMargin">
                                                                            <label srOnly="Modifier Name" labelFor="taxStatus01" className="d-none">Tax Status</label>
                                                                            <Form.Check type="checkbox" id="taxStatus01" name="taxStatus01" />
                                                                        </Form.Group>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <Form.Select className="selectTable" id="gstTaxPercent" name="gstTaxPercent">
                                                                            <option value="GST/Tax at 13%">GST/Tax at 13%</option>
                                                                            <option value="GST/Tax at 15%">GST/Tax at 15%</option>
                                                                            <option value="GST/Tax at 18%">GST/Tax at 18%</option>
                                                                        </Form.Select>
                                                                        <Button type="button" className="btn btn-AddTax">+ Add</Button>
                                                                    </td>
                                                                    <td>General Sales Tax</td>
                                                                    <td>$5.00</td>
                                                                    <td>
                                                                        <Form.Group className="inputMargin">
                                                                            <label srOnly="Modifier Name" labelFor="taxStatus02" className="d-none">Tax Status</label>
                                                                            <Form.Check type="checkbox" id="taxStatus02" name="taxStatus02" />
                                                                        </Form.Group>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <Form.Select className="selectTable" id="gstTaxPercent" name="gstTaxPercent">
                                                                            <option value="GST/Tax at 13%">GST/Tax at 13%</option>
                                                                            <option value="GST/Tax at 15%">GST/Tax at 15%</option>
                                                                            <option value="GST/Tax at 18%">GST/Tax at 18%</option>
                                                                        </Form.Select>
                                                                        <Button type="button" className="btn btn-AddTax">+ Add</Button>
                                                                    </td>
                                                                    <td>General Sales Tax</td>
                                                                    <td>$10.00</td>
                                                                    <td>
                                                                        <Form.Group className="inputMargin">
                                                                            <label srOnly="Modifier Name" labelFor="taxStatus03" className="d-none">Tax Status</label>
                                                                            <Form.Check type="checkbox" id="taxStatus03" name="taxStatus03" />
                                                                        </Form.Group>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                    </Col>
                                                    <Col md={8} className="pl-3 pt-3 pb-3">
                                                        <div>
                                                            <Button type="submit" className="btn_submit">
                                                                Update
                                                            </Button>
                                                            <Button type="button" className="btn_submit">
                                                                Save
                                                            </Button>
                                                        </div>
                                                    </Col>
                                                </TabPanel>
                                            </Tabs>

                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
        );
    }
}

export default ProductDetails;