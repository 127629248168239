import React, {Component} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import notification from "../../../assets/icons/Notification.svg";
import profile from "../../../assets/icons/photo.png";

class TopHeader extends Component {
    render() {
        return (
            <div className="top_header">
                <Container>
                    <Row>
                        <Col md={6}>
                            <div className="left_side">
                                <div className="drop_down">
                                    <select id="dropdown">
                                        <option value="Restaurent Branch ">Restaurent Branch</option>
                                        <option value="Restaurent Branch ">Restaurent Branch</option>
                                        <option value="Restaurent Branch ">Restaurent Branch</option>
                                        <option value="Restaurent Branch ">Restaurent Branch</option>
                                        <option value="Restaurent Branch ">Restaurent Branch</option>
                                    </select>
                                </div>
                                <div className="data_title">17-08-2021 | Tuesday</div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="right_side">
                                <div className="notification-icon">
                                    <Link to="">
                                        <img src={notification} alt="Notification_icon"/>
                                    </Link>
                                </div>
                                <div className="profile-icon">
                                    <div className="profile_img">
                                        <img src={profile} alt="profile_icon"/>
                                    </div>
                                    <div className="name_profile">
                                        John Doe
                                        <span>Sales Manager</span>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default TopHeader;