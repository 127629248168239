import React, {Component} from 'react';
import {Link} from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {Col, Container, Row,} from "react-bootstrap";

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: {max: 4000, min: 3000},
        items: 1
    },
    desktop: {
        breakpoint: {max: 3000, min: 1024},
        items: 1
    },
    tablet: {
        breakpoint: {max: 1024, min: 464},
        items: 1
    },
    mobile: {
        breakpoint: {max: 464, min: 0},
        items: 1
    }
};

class CarouselBanner extends Component {
    render() {
        return (
            <Carousel
                swipeable={false}
                draggable={false}
                showDots={true}
                responsive={responsive}
                ssr={false} // means to render carousel on server-side.
                infinite={true}
                autoPlay={this.props.deviceType !== "mobile" ? true : false}
                autoPlaySpeed={3000}
                keyBoardControl={true}
                customTransition="sldie 3"
                transitionDuration={5000}
                containerClass="carousel-container"
                removeArrowOnDeviceType={["tablet", "mobile"]}
                deviceType={this.props.deviceType}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
            >
                <div className="bg_img" style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/images/banner1.png'})`
                }}>
                    <Container fluid className="h-100 p_100">
                        <Row className=" h-100">
                            <Col md={7} lg={6} className=" mt-auto">
                                <div className="content_txt">
                                    <div className="date">08-09-2021</div>
                                    <h2>Introducing: The New Steps of Service</h2>
                                    <p>There’s a new service model emerging that’s helping restaurant operators run
                                        healthier, more durable businesses. Learn more about The New Steps of
                                        Service, the benefits it offers to the restaurant industry, and how to set
                                        yourself up for success by utilizing it.</p>
                                    <Link to="">Learn More...</Link>
                                </div>

                            </Col>
                        </Row>
                    </Container>

                </div>
                <div className="bg_img" style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/images/banner2.png'})`
                }}>
                    <Container fluid className="h-100 p_100">
                        <Row className=" h-100">
                            <Col md={7} lg={6} className=" mt-auto">
                                <div className="content_txt">
                                    <div className="date">12-09-2021</div>
                                    <h2>Get Access to Fast Funding Through ServeEasy Capital</h2>
                                    <p>Whether you are dreaming of expanding your restaurant, investing in new
                                        technology, upgrading equipment, or even looking to open that next location,
                                        Toast Capital Loans have you covered.</p>
                                    <Link to="">Learn More...</Link>
                                </div>

                            </Col>
                        </Row>
                    </Container>

                </div>

            </Carousel>
        );
    }
}

export default CarouselBanner;