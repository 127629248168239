import React, {Component} from 'react';
import SidebarMenu from "../layouts/SidebarMenu";
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import span from "../../../src/assets/icons/photo.png"
import search from "../../../src/assets/icons/search.svg"
import DineIn from "../../../src/assets/icons/Dine-In.svg"
import Reservation from "../../../src/assets/icons/Reservation.svg"
import Takeaway from "../../../src/assets/icons/Takeaway.svg"
import Delivery from "../../../src/assets/icons/Delivery.svg"
import TodaySales from "../../../src/assets/icons/Today-Sales.svg"
import LastWeek from "../../../src/assets/icons/Last-Week-Sales.svg"
import AvgGuestCheck from "../../../src/assets/icons/AvgGuestCheck.svg"
import TotalMadeVoids from "../../../src/assets/icons/TotalMadeVoids.svg"
import excel from "../../../src/assets/icons/excel.svg"
import meal from "../../../src/assets/icons/meal.svg"
import 'react-tabs/style/react-tabs.css';
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import LineChart from "../chart/LineChart";
import BarChart from "../chart/BarChart";
import DoughnutChart from "../chart/DoughnutChart";
import TopHeader from "./components/TopHeader";

class Home extends Component {
    render() {
        return (
            <div>
                <SidebarMenu/>
                <div className="main_page_sectoin">
                    <TopHeader/>
                    <div className="main_panel">
                        <Container>
                            <Row>
                                <Col md={12}>
                                    <div className="title_content">
                                        <h1>Welcome To <span>Serve</span>Easy</h1>
                                        <div className="div_search">
                                            <Form>
                                                <Form.Group className="from_search">
                                                    <Form.Control type="text"
                                                                  placeholder="What are you looking for..."/>
                                                    <Button type="submit" className="btn_search">
                                                        <img src={search} alt="search_icon"/>
                                                    </Button>
                                                </Form.Group>

                                            </Form>
                                        </div>
                                    </div>
                                    <div className="sales_overview">
                                        <h2>Sales Overview</h2>
                                        <span className="sort">
                                            Sort By:
                                            <select>
                                                    <option>Date & Day</option>
                                                    <option>12-08-21</option>
                                                     <option>12-08-21</option>
                                                     <option>12-08-21</option>
                                                     <option>12-08-21</option>
                                            </select>
                                        </span>
                                        <Row>
                                            <Col md={6} lg={4}>
                                                <div className="bg_white border__2">
                                                    <Row className="row-cols-lg-2">
                                                        <Col>
                                                            <div className="sales_box">
                                                                <div className="icon_box">
                                                                    <img src={DineIn} alt="DineIn_icon"/>
                                                                </div>
                                                                <div className="title_box blue">
                                                                    Dine-In
                                                                    <span>2,54,741</span>
                                                                </div>

                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <div className="sales_box">
                                                                <div className="icon_box ">
                                                                    <img src={Takeaway} alt="Takeaway_icon"/>
                                                                </div>
                                                                <div className="title_box yellow">
                                                                    Takeaway
                                                                    <span>1,57,154</span>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <div className="sales_box">
                                                                <div className="icon_box ">
                                                                    <img src={Reservation} alt="Reservation_icon"/>
                                                                </div>
                                                                <div className="title_box red">
                                                                    Reservation
                                                                    <span>510 PAX</span>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <div className="sales_box">
                                                                <div className="icon_box ">
                                                                    <img src={Delivery} alt="Delivery_icon"/>
                                                                </div>
                                                                <div className="title_box green">
                                                                    Delivery
                                                                    <span>4,15,864</span>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                            <Col md={6} lg={3}>
                                                <div className="bg_white border__1">
                                                    <Row className="row-cols-lg-1">
                                                        <Col>
                                                            <div className="sales_box">
                                                                <div className="icon_box">
                                                                    <img src={TodaySales} alt="Today_Sales_icon"/>
                                                                </div>
                                                                <div className="title_box green">
                                                                    Today’s Sales
                                                                    <span>8,15,856</span>
                                                                    <div className="sale_count plus">
                                                                        40%
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <div className="sales_box">
                                                                <div className="icon_box ">
                                                                    <img src={LastWeek} alt="Last_Week_Sales_icon"/>
                                                                </div>
                                                                <div className="title_box red">
                                                                    Last Week Sales
                                                                    <span>6,18,484</span>
                                                                    <div className="sale_count minus">
                                                                        27%
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                            <Col md={6} lg={3}>
                                                <div className="bg_white border__1">
                                                    <Row className="row-cols-lg-1">
                                                        <Col>
                                                            <div className="sales_box">
                                                                <div className="icon_box">
                                                                    <img src={AvgGuestCheck} alt="AvgGuestCheck_icon"/>
                                                                </div>
                                                                <div className="title_box blue">
                                                                    Avg Guest Check
                                                                    <span>
                                                                       <svg width="14" height="14" viewBox="0 0 14 14"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg">
<circle cx="7" cy="3.25" r="2.25" stroke="#0178E5" stroke-width="2" stroke-linecap="round"/>
<path
    d="M7 8.5C1.54545 8.5 1 10.75 1 11.875C1 12.4963 1.50368 13 2.125 13H11.875C12.4963 13 13 12.4963 13 11.875C13 10.75 12.4545 8.5 7 8.5Z"
    stroke="#0178E5" stroke-width="2" stroke-linecap="round"/>
</svg>325 - 1,900
                                                                    </span>
                                                                    <div className="sale_count plus">
                                                                        40%
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <div className="sales_box">
                                                                <div className="icon_box ">
                                                                    <img src={TotalMadeVoids}
                                                                         alt="TotalMadeVoids_icon"/>
                                                                </div>
                                                                <div className="title_box red">
                                                                    Total Made Voids
                                                                    <span>
                                                                        <svg width="14" height="14"
                                                                             viewBox="0 0 14 14" fill="none"
                                                                             xmlns="http://www.w3.org/2000/svg">
<circle cx="7" cy="3.25" r="2.25" stroke-width="2" stroke-linecap="round"/>
<path
    d="M7 8.5C1.54545 8.5 1 10.75 1 11.875C1 12.4963 1.50368 13 2.125 13H11.875C12.4963 13 13 12.4963 13 11.875C13 10.75 12.4545 8.5 7 8.5Z"
    stroke-width="2" stroke-linecap="round"/>
</svg>
                                                                        151</span>
                                                                    <div className="sale_count minus">
                                                                        15%
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                            <Col md={6} lg={2}>
                                                <div className="bg_white">
                                                    ddddas
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="sales_overview">
                                        <h2>Guest Map</h2>

                                        <Row>
                                            <Col md={12} lg={12}>
                                                <div className="bg_white">
                                                    <div className="export_csv">
                                                        <img src={excel} alt="excel_icon"/>
                                                        <Form.Group className="date_type">
                                                            <Form.Control type="date" placeholder="30 August 2021"/>
                                                        </Form.Group>
                                                    </div>
                                                    <Tabs>
                                                        <TabList>
                                                            <Tab>All</Tab>
                                                            <Tab>Dine-In</Tab>
                                                            <Tab>Takeaway</Tab>
                                                            <Tab>Delivery</Tab>
                                                        </TabList>
                                                        <TabPanel>
                                                            <h3 className="title_total">Total
                                                                revenue <span className="count">8,15,856</span>
                                                                <span className="sale_count plus">115%</span>
                                                            </h3>
                                                            <LineChart/>
                                                        </TabPanel>
                                                        <TabPanel>
                                                            <h3 className="title_total">Total
                                                                Dine-In <span className="count">8,15,856</span>
                                                                <span className="sale_count plus">115%</span>
                                                            </h3>
                                                            <LineChart/>
                                                        </TabPanel>
                                                        <TabPanel>
                                                            <h3 className="title_total">Total
                                                                Takeaway <span className="count">8,15,856</span>
                                                                <span className="sale_count plus">115%</span>
                                                            </h3>
                                                            <LineChart/>
                                                        </TabPanel>
                                                        <TabPanel>
                                                            <h3 className="title_total">Total
                                                                Delivery <span className="count">8,15,856</span>
                                                                <span className="sale_count plus">115%</span>
                                                            </h3>
                                                            <LineChart/>
                                                        </TabPanel>
                                                    </Tabs>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="sales_overview">

                                        <Row>
                                            <Col md={12} lg={8}>
                                                <h2>Overall Sales Comperative Chart</h2>
                                                <div className="bg_white">
                                                    <BarChart/>
                                                </div>
                                            </Col>
                                            <Col md={12} lg={4}>
                                                <h2>Sales Breakdown </h2>
                                                <div className="bg_white border__2">
                                                    <DoughnutChart/>
                                                    <Row className="row-cols-lg-2">
                                                        <Col>
                                                            <div className="sales_box">
                                                                <div className="icon_box">
                                                                    <img src={meal} alt="meal_icon"/>
                                                                </div>
                                                                <div className="title_box blue">
                                                                    Breakfast
                                                                    <span>2,54,741</span>
                                                                </div>

                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <div className="sales_box">
                                                                <div className="icon_box ">
                                                                    <img src={meal} alt="meal_icon"/>
                                                                </div>
                                                                <div className="title_box yellow">
                                                                    Lunch
                                                                    <span>1,57,154</span>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <div className="sales_box">
                                                                <div className="icon_box ">
                                                                    <img src={meal} alt="meal_icon"/>
                                                                </div>
                                                                <div className="title_box red">
                                                                    Happy Hour
                                                                    <span>510 PAX</span>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <div className="sales_box">
                                                                <div className="icon_box ">
                                                                    <img src={meal} alt="meal_icon"/>
                                                                </div>
                                                                <div className="title_box green">
                                                                    Dinner
                                                                    <span>4,15,864</span>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>

                                        </Row>
                                    </div>

                                </Col>

                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;